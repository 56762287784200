import { CustomDataGrid, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { getProvidersWithoutExpenseThisMonth, ProviderWithoutExpenseThisMonth } from "../../../../queries"
import { GridColDef } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { Button } from "@mui/material"
import { useDispatch } from "../../../../redux/store"
import { setModalOpen } from "../../../../redux/slices/App"
import { useNavigate } from "react-router-dom"

const DesktopProvidersWithoutExpenseThisMonthList = () => {
    const { data, isLoading } = getProvidersWithoutExpenseThisMonth()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { data: userProperties } = useUserPropertiesQuery()
    const navigate = useNavigate()

    const columns = useMemo(() => {
        const columns: GridColDef<ProviderWithoutExpenseThisMonth>[] = [
            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueGetter: (value) => userProperties?.find((p) => p.id === value)?.label,
                renderCell: ({ value, row }) => {
                    return (
                        <Button
                            variant={"text"}
                            color='primary'
                            onClick={() => {
                                dispatch(setModalOpen(undefined))
                                navigate(`/properties/${row.propertyId}`)
                            }}
                        >
                            {value}
                        </Button>
                    )
                },
            },
            {
                field: "providerName",
                minWidth: 150,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("providerName"),
            },
            {
                field: "lastExpenseDate",
                type: "string",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastExpenseDate"),
                valueFormatter: (value) => (value != null ? (value as DateTime).toLocaleString(DateTime.DATE_SHORT) : "-"),
            },
        ]

        return columns
    }, [translate, userProperties])

    return (
        <CustomDataGrid
            isLoading={isLoading}
            columns={columns}
            rows={data ?? []}
            idKey={"propertyId"}
            getRowId={(r) => r.providerId}
            gridId='InvoicingDetailsPanel'
            actionsColumn={[]}
            gridHeight={400}
            paginationMode={"client"}
        />
    )
}

export default DesktopProvidersWithoutExpenseThisMonthList
