import { useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useSelector } from "../redux/store"
import { AppModals } from "../redux/slices/App"
import { DateTime } from "luxon"

export interface ProviderWithoutExpenseThisMonthBase {
    propertyId: number
    providerName: string
    providerId: string
}

export interface ProviderWithoutExpenseThisMonthDto extends ProviderWithoutExpenseThisMonthBase {
    lastExpenseDate?: string
}

export interface ProviderWithoutExpenseThisMonth extends ProviderWithoutExpenseThisMonthBase {
    lastExpenseDate?: DateTime
}

export const useSummaryCardQuery = (cardId?: number, propertyId?: number | null) => {
    const { context, currentGroupId } = useSelector((state) => state.appState)
    return useQuery({
        queryKey: [ComplexQueryIDs.SummaryCard, { cardId, propertyId, groupId: currentGroupId }],
        queryFn: Api.fetchSummaryCards,
        enabled: !!cardId && context != null,
        staleTime: Infinity,
    })
}

export const useDashboardChartQuery = (chartId?: number, propertyId?: number | null) => {
    const { currentGroupId } = useSelector((state) => state.appState)
    return useQuery({
        enabled: !!chartId,
        staleTime: Infinity,
        queryFn: Api.fetchCharts,
        queryKey: [ComplexQueryIDs.DashboardChart, { chartId, propertyId, groupId: currentGroupId }],
    })
}

export const getProvidersWithoutExpenseThisMonth = () => {
    const { modalOpen } = useSelector((state) => state.appState)

    return useQuery({
        enabled: modalOpen === AppModals.ProvidersWithoutExpenseThisMonth,
        staleTime: Infinity,
        queryFn: Api.fetchProvidersWithoutExpenseThisMonth,
        queryKey: [SimpleQueryIDs.ProvidersWithoutExpenseThisMonth],
    })
}

export const useDashboardTableQuery = (tableId?: number, propertyId?: number) => {
    const { editablePropertiesIds } = usePermissions(PermissionType.Meters)

    return useQuery({
        queryKey: [ComplexQueryIDs.DashboardTable, { tableId, propertyId }],
        queryFn: Api.fetchTables,
        enabled: !!tableId,
        staleTime: Infinity,
        select: (data) => {
            return {
                code: data.code,
                title: data.title,
                values: data.values.map((value) => ({
                    ...value,
                    canWrite: editablePropertiesIds?.includes(value.propertyId) ?? false,
                })),
            }
        },
    })
}
