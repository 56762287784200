import { Tab } from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useParams } from "react-router"
import { useAtom } from "jotai"
import { rentingPeriodDetailsPage, TenantsPage } from "../../../../utils/atom"
import { CustomTabs, useLocales } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../../../queries/userData"
import { useSelector } from "../../../../redux/store"
import { a11yProps } from "../../../../utils/A11yProps"

export default function RentingPeriodDetailsPageTabs() {
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { page } = useParams()
    const [rentingPeriodsPage, setRentingPeriodPage] = useAtom(rentingPeriodDetailsPage)
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())

    useEffect(() => {
        switch (page) {
            case "general":
                setRentingPeriodPage(TenantsPage.GENERAL)
                break
            case "expenses":
                setRentingPeriodPage(TenantsPage.EXPENSES)
                break
            case "incomes":
                setRentingPeriodPage(TenantsPage.INCOMES)
                break
            case "documents":
                setRentingPeriodPage(TenantsPage.DOCUMENTS)
                break
            case "journal":
                setRentingPeriodPage(TenantsPage.JOURNAL)
                break
            case "c168":
                setRentingPeriodPage(TenantsPage.C168)
                break
            case "configurations":
                setRentingPeriodPage(TenantsPage.CONFIGURATIONS)
                break
            case "payment-notifications":
                setRentingPeriodPage(TenantsPage.PAYMENT_NOTIFICATIONS)
                break
            default:
                setRentingPeriodPage(TenantsPage.GENERAL)
        }
    }, [page])

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: TenantsPage | undefined) => {
            switch (newValue) {
                case TenantsPage.GENERAL:
                    navigate(`/tenants/${currentRentingPeriodId}/general`)
                    break
                case TenantsPage.EXPENSES:
                    navigate(`/tenants/${currentRentingPeriodId}/expenses`)
                    break
                case TenantsPage.INCOMES:
                    navigate(`/tenants/${currentRentingPeriodId}/incomes`)
                    break
                case TenantsPage.DOCUMENTS:
                    navigate(`/tenants/${currentRentingPeriodId}/documents`)
                    break
                case TenantsPage.JOURNAL:
                    navigate(`/tenants/${currentRentingPeriodId}/journal`)
                    break
                case TenantsPage.C168:
                    navigate(`/tenants/${currentRentingPeriodId}/c168`)
                    break
                case TenantsPage.CONFIGURATIONS:
                    navigate(`/tenants/${currentRentingPeriodId}/configurations`)
                    break
                case TenantsPage.PAYMENT_NOTIFICATIONS:
                    navigate(`/tenants/${currentRentingPeriodId}/payment-notifications`)
                    break
            }
        },
        [navigate],
    )

    return (
        <CustomTabs value={rentingPeriodsPage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
            <Tab iconPosition='end' label={translate("general")} {...a11yProps(TenantsPage.GENERAL)} />
            <Tab iconPosition='end' label={translate("expenses_title")} {...a11yProps(TenantsPage.EXPENSES)} />
            <Tab iconPosition='end' label={translate("income")} {...a11yProps(TenantsPage.INCOMES)} />
            <Tab iconPosition='end' label={translate("documents")} {...a11yProps(TenantsPage.DOCUMENTS)} />
            <Tab iconPosition='end' label={translate("journal")} {...a11yProps(TenantsPage.JOURNAL)} />
            {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <Tab iconPosition='end' label={translate("configurations")} {...a11yProps(TenantsPage.CONFIGURATIONS)} />
            )}

            {(!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <Tab iconPosition='end' label={translate("c168")} {...a11yProps(TenantsPage.C168)} />
            )}

            {showPaymentNotifications && (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <Tab iconPosition='end' label={translate("payment-notifications-title")} {...a11yProps(TenantsPage.PAYMENT_NOTIFICATIONS)} />
            )}
        </CustomTabs>
    )
}
