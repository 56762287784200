import { array, boolean, lazy, number, object, string } from "yup"
import { RecurringType } from "../queries/expenses"
import { DateTime } from "luxon"

export const AddRecurringExpenseDetailsStepSchema = object().shape({
    assignee: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required")
        .min(0)
        .max(1),
    recurringType: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required")
        .min(RecurringType.Daily)
        .max(RecurringType.Monthly),
    value: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required")
        .min(1, "amount_required")
        .positive("invalid_value"),
    files: array().required(),
    associatedProviderId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    labelId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    shouldNotifyOtherParty: boolean().required("required"),
    shouldGenerateInvoice: boolean().required("required"),
    expirationDate: string().test((date: string | undefined) => {
        if (date == null) return true
        const newDate = DateTime.fromISO(date)

        return newDate.isValid
    }),
    paymentType: lazy((v) => {
        if (typeof v === "string") return string().required("required")

        return number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required")
            .min(0)
            .max(4)
    }),
})

export const AddRecurringExpenseInvoiceDataSchema = object().shape({
    invoiceLanguage: string().required("required"),
    invoiceCompany: string().required("required"),
    invoiceClient: number().required("required"),
    invoicingSeries: string().required("required"),
    invoiceCurrencyId: number().required("required"),
    invoiceTVA: number().required("required"),
    invoiceProductName: string().required("required"),
    invoiceProductDescription: string().required("required"),
    invoicingPeriodStart: number()
        .required("required")
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
    invoicingPeriodEnd: number()
        .required("required")
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
})
