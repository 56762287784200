import { Box, Button, CircularProgress, InputAdornment, Tab, useColorScheme } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { CustomTabs, RHFTextField, useIsMobile, useLocales, useResponsive } from "rentzz"
import CheckIcon from "@mui/icons-material/Check"
import { alpha, useTheme } from "@mui/material/styles"
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar } from "@syncfusion/ej2-react-richtexteditor"
import { Viewer } from "@react-pdf-viewer/core"
import * as React from "react"
import { RefObject, useCallback, useState } from "react"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { useFormContext } from "react-hook-form"
import { NotificationTemplateDetails, NotificationTemplateVariable } from "../queries/userData"
import { GeneralDocumentDetails } from "../queries/generalDocuments"
import { toolbarSettings } from "./ToolbarSettings"
import { ToolbarSettingsModel } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/models"
import { a11yProps } from "../utils/A11yProps"
import DefaultVariablesEdit from "../sections/documents-templates/notifications/DefaultVariablesEdit"

interface Props {
    isAddPending: boolean
    isUpdateContentPending: boolean
    url: string
    showPreview: boolean
    onTextEditorFocus: () => void
    onMobileSave: () => void
    onTextFieldBlur: () => void
    pRef: RefObject<RichTextEditorComponent>
    isPdfLoading: boolean
    onReloadPdf: () => void
    isNameChangeSuccess: boolean
    isNameChangeUpdating: boolean
    name: string
    defaultValues?: NotificationTemplateDetails | GeneralDocumentDetails
    variables?: NotificationTemplateVariable[]
    itemId?: number
    onAIGenerate?: (ev: React.MouseEvent<HTMLElement>) => void
}

enum DocumentPage {
    EDIT,
    DEFAULT_VARIABLE_VALUES,
}

export default function CustomAddDocumentFile({
    isAddPending,
    isUpdateContentPending,
    url,
    showPreview,
    onTextFieldBlur,
    onMobileSave,
    onTextEditorFocus,
    pRef,
    defaultValues,
    isPdfLoading,
    onReloadPdf,
    isNameChangeSuccess,
    isNameChangeUpdating,
    name,
    variables,
    itemId,
    onAIGenerate,
}: Props) {
    const theme = useTheme()
    const mdUp = useResponsive("up", "md")
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    const { mode } = useColorScheme()
    const { setValue } = useFormContext()
    const [activePage, setActivePage] = useState<DocumentPage>(DocumentPage.EDIT)

    const handleChange = useCallback(
        (_: React.SyntheticEvent, newValue: DocumentPage) => {
            setActivePage(newValue)
        },
        [setActivePage],
    )

    const getWidth = useCallback(() => {
        if (mdUp) return "50%"
        return "100%"
    }, [mdUp])

    return (
        <>
            <Box sx={{ marginLeft: 2 }} paddingRight={isMobile ? 2 : undefined} display={"flex"} justifyContent={"space-between"}>
                <Box display='flex' flexDirection='row' gap={2}>
                    <LoadingButton
                        type={"submit"}
                        variant={"contained"}
                        color={"primary"}
                        loading={isAddPending || isUpdateContentPending}
                        disabled={isAddPending || isUpdateContentPending}
                    >
                        {translate("save")}
                    </LoadingButton>
                    {onAIGenerate && (
                        <Button
                            type={"submit"}
                            variant={"contained"}
                            color={"primary"}
                            disabled={isAddPending || isUpdateContentPending}
                            onClick={onAIGenerate}
                        >
                            {translate("generate_document_with_ai")}
                        </Button>
                    )}
                </Box>

                {isMobile && (
                    <LoadingButton onClick={onMobileSave} variant='contained' color='primary' loading={isPdfLoading} disabled={isPdfLoading}>
                        {translate("load_preview")}
                    </LoadingButton>
                )}
            </Box>

            <Box
                display='flex'
                width={"100%"}
                height={"100%"}
                flexDirection={mdUp ? "row" : "column"}
                paddingTop={2}
                paddingLeft={2}
                paddingRight={isMobile ? 2 : undefined}
                paddingBottom={isMobile ? 8 : undefined}
                gap={4}
                sx={{ color: theme.palette.grey.A700 }}
            >
                <Box
                    width={getWidth()}
                    height={"100%"}
                    sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <RHFTextField
                        name={"name"}
                        label={translate(name)}
                        fullWidth
                        onBlur={onTextFieldBlur}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <Box>
                                        {isNameChangeUpdating && <CircularProgress size={"1rem"} />}
                                        {isNameChangeSuccess && <CheckIcon color={"success"} fontSize={"small"} />}
                                    </Box>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {defaultValues != null && itemId != null && (
                        <CustomTabs value={activePage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                            <Tab iconPosition='start' label={translate("edit")} style={{ textTransform: "none" }} {...a11yProps(0)}></Tab>
                            <Tab
                                iconPosition='start'
                                label={translate("variable_config_default")}
                                style={{ textTransform: "none" }}
                                {...a11yProps(1)}
                            />
                        </CustomTabs>
                    )}
                    {activePage === DocumentPage.DEFAULT_VARIABLE_VALUES && (
                        <DefaultVariablesEdit variables={variables ?? []} notificationId={itemId} />
                    )}
                    {activePage === DocumentPage.EDIT && (
                        <Box height={isMobile ? undefined : window.innerHeight - 215} position='relative'>
                            {isPdfLoading && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 1000,
                                        top: 0,
                                        left: 0,
                                        backgroundColor: alpha(theme.palette.grey.A700, 0.4),
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0.5,
                                    }}
                                />
                            )}
                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                                <RichTextEditorComponent
                                    name={"content"}
                                    id={"content"}
                                    ref={pRef}
                                    value={defaultValues?.content ?? ""}
                                    height={isMobile ? undefined : window.innerHeight - 215}
                                    onFocus={onTextEditorFocus}
                                    toolbarSettings={toolbarSettings as ToolbarSettingsModel}
                                    onBlur={() => setValue("content", pRef.current?.getHtml() ?? defaultValues?.content ?? "")}
                                >
                                    <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                                </RichTextEditorComponent>

                                {isPdfLoading && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            zIndex: 1000,
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <CircularProgress color={"inherit"} size={"2rem"} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>

                {!isMobile && (
                    <Box width={getWidth()} height={window.innerHeight - 160} position='relative'>
                        {showPreview && url && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    zIndex: 1000,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: alpha(theme.palette.grey.A700, 0.4),
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0.5,
                                }}
                            />
                        )}

                        <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                            {url && <Viewer theme={mode} fileUrl={url} plugins={[defaultLayoutPluginInstance]} />}

                            {(showPreview || isPdfLoading) && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 1000,
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <LoadingButton onClick={onReloadPdf} variant='contained' color='primary' loading={isPdfLoading}>
                                        {translate(url ? "reload" : "load_preview")}
                                    </LoadingButton>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </>
    )
}
