import { useDispatch } from "../../../redux/store"
import { useNavigate } from "react-router-dom"
import { alpha, useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddGeneralDocumentSchema } from "../../../validations/notificationsTemplates"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { CustomFile, FormProvider, usePopover } from "rentzz"
import CustomAddDocumentFile from "../../../components/CustomAddDocumentFile"
import { GeneralDocumentDetails } from "../../../queries/generalDocuments"
import {
    getGeneralDocumentPdfMutation,
    updateGeneralDocumentContentMutation,
    updateGeneralDocumentMutation,
    useAddGeneralDocumentMutation,
} from "../../../mutations/generalDocuments"
import GenerateDocumentWithAI from "./GenerateDocumentWithAI"

interface Props {
    defaultValues?: GeneralDocumentDetails
}

export interface AddNewDocumentRequest {
    name: string
    content?: string
    files?: CustomFile[]
}

export default function AddOrEditGeneralDocument({ defaultValues }: Props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const pRef = useRef<RichTextEditorComponent>(null)
    const [url, setUrl] = useState<string>("")
    const [showPreview, setShowPreview] = useState<boolean>(true)
    const { mutateAsync: addGeneralDocument, isPending } = useAddGeneralDocumentMutation()
    const { mutateAsync: updateGeneralDocumentContent, isPending: isUpdateGeneralContentLoading } = updateGeneralDocumentContentMutation()
    const { mutateAsync: updateGeneralDocument, isPending: isUpdateGeneralDocument, isSuccess } = updateGeneralDocumentMutation()
    const { mutateAsync: getPdf, isPending: isGetPdfPending } = getGeneralDocumentPdfMutation()
    const popover = usePopover()
    const [aiContent, setAiContent] = useState<string | null>()

    const methods = useForm<AddNewDocumentRequest>({
        resolver: yupResolver(AddGeneralDocumentSchema),
        mode: "onBlur",
        defaultValues: {
            name: "",
            content: "",
            files: [],
        },
    })

    const { handleSubmit, reset, setValue, getValues } = methods

    const getHTMLContent = useCallback(
        () =>
            (pRef.current?.getHtml() ?? defaultValues?.content ?? "").replace(
                /\{{([^}]+)\}}/g,
                (match, p1) =>
                    `<span style="font-weight:bold; background-color:${alpha(theme.palette.primary.main, 0.2)}; color:${
                        theme.palette.primary.main
                    }; border-radius: 4px; padding: 2px">${p1}</span>`,
            ),
        [pRef, defaultValues, theme],
    )

    const onLoad = useCallback(async () => {
        setShowPreview(true)
        const result = await getPdf({ body: getHTMLContent() })
        const url = window.URL.createObjectURL(new Blob([result]))
        setUrl(url)
        setShowPreview(false)
        setValue("content", pRef.current?.getHtml() ?? defaultValues?.content ?? "")
    }, [getPdf, setUrl, setShowPreview, defaultValues, getHTMLContent])

    useEffect(() => {
        if (defaultValues) {
            onLoad()
            reset({
                name: defaultValues.name,
                content: defaultValues.content,
            })
        } else {
            reset()
        }
    }, [defaultValues])

    const onSave = useCallback(
        async (data: AddNewDocumentRequest) => {
            if (defaultValues) {
                await updateGeneralDocumentContent({
                    documentId: defaultValues.id,
                    content: data.content,
                })
            } else {
                await addGeneralDocument({ data })
                navigate("/document-templates/general-documents")
            }
        },
        [addGeneralDocument, navigate, updateGeneralDocumentContent, defaultValues, setValue],
    )

    const onMobileSave = useCallback(async () => {
        const result = await getPdf({ body: getHTMLContent() })
        const url = window.URL.createObjectURL(new Blob([result]))
        dispatch(setModalOpen(AppModals.GeneralDocumentPreview))
        dispatch(setEditingItem({ pdfUrl: url }))
    }, [getPdf, getHTMLContent, dispatch])

    const onTextFieldBlur = useCallback(async () => {
        if (defaultValues) {
            if (getValues("name") === defaultValues.name) return
            await updateGeneralDocument({
                documentId: defaultValues.id,
                operations: [{ op: "replace", path: "/name", value: getValues("name") }],
            })
        }
    }, [defaultValues, getValues, updateGeneralDocument])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <CustomAddDocumentFile
                url={url}
                pRef={pRef}
                isAddPending={isPending}
                isNameChangeSuccess={isSuccess}
                isNameChangeUpdating={isUpdateGeneralDocument}
                defaultValues={
                    aiContent != null && aiContent.length > 0 && defaultValues?.content != null
                        ? { ...defaultValues, content: aiContent }
                        : defaultValues
                }
                isPdfLoading={isGetPdfPending}
                isUpdateContentPending={isUpdateGeneralContentLoading}
                onMobileSave={onMobileSave}
                onReloadPdf={onLoad}
                onTextEditorFocus={() => setShowPreview(true)}
                onTextFieldBlur={onTextFieldBlur}
                showPreview={showPreview}
                name={"document_name"}
                onAIGenerate={popover.onOpen}
            />
            <GenerateDocumentWithAI
                pRef={pRef.current}
                popover={popover}
                setContent={setAiContent}
                onEnd={() => {
                    onTextFieldBlur().then(() => {
                        setTimeout(() => onLoad(), 200)
                    })
                }}
            />
        </FormProvider>
    )
}
