import { Banks, RoleName, UserBankAccount, useUserDataQueryFn } from "../../../queries/userData"
import { FormProvider, RHFAutocomplete, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import {
    addBankAccountMutation,
    addPropertyToBankAccountMutation,
    deletePropertyFromBankAccountMutation,
    updateBankAccountMutation,
} from "../../../mutations/user"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect } from "react"
import { Box, Button, CircularProgress, MenuItem, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddBankAccountSchema } from "../../../validations/bankAccounts"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useDispatch, useSelector } from "../../../redux/store"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import * as jsonpatch from "fast-json-patch"
import { setModalOpen, setShowSubscriptionPage } from "../../../redux/slices/App"

interface Props {
    onClose: () => void
    defaultValues?: UserBankAccount
    isAssociatingProperties: boolean | undefined
}

export interface AddBankAccountRequest {
    holder: string
    iban: string
    bank: number
    currencyId: number
    properties?: number[]
}

export default function AddBankAccountForm({ onClose, defaultValues, isAssociatingProperties }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: user } = useUserDataQueryFn()
    const dispatch = useDispatch()
    const { data: currencies } = useCurrencyQueryFn()
    const { editableProperties } = usePermissions(PermissionType.BankAccounts)
    const { mutateAsync: addBankAccount, isPending: isAddingBankAccount } = addBankAccountMutation()
    const { mutateAsync: updateBankAccount, isPending: isUpdatingBankAccount } = updateBankAccountMutation()
    const { mutateAsync: addProperties, isPending: isAddingPropertiesToBankAccount } = addPropertyToBankAccountMutation()
    const { mutateAsync: deletePropertiesFromBankAccount } = deletePropertyFromBankAccountMutation()

    const methods = useForm<AddBankAccountRequest>({
        resolver: yupResolver(AddBankAccountSchema(isAssociatingProperties)),
        mode: "onChange",
        defaultValues: {
            holder: "",
            bank: undefined,
            iban: "",
            currencyId: user?.currency.id,
            properties: currentPropertyId ? [currentPropertyId] : [],
        },
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues && !isAssociatingProperties) {
            reset({
                ...defaultValues,
                properties: defaultValues.properties,
            })
        }
        if (defaultValues && isAssociatingProperties) {
            reset({
                ...defaultValues,
                properties: defaultValues.properties ?? [],
            })
        }
    }, [defaultValues, reset])

    const onSave = useCallback(
        async (data: AddBankAccountRequest) => {
            if (defaultValues?.id != null && defaultValues.properties != null) {
                const operations = jsonpatch.compare({ ...defaultValues, properties: [] }, { ...data, properties: [] })
                if (!isAssociatingProperties) {
                    await updateBankAccount({
                        bankAccountId: defaultValues.id,
                        operations: operations,
                    })
                }
                const currentBankAccountPropertiesIds = defaultValues?.properties
                const propertiesToAdd = data.properties?.filter((property) => !currentBankAccountPropertiesIds.includes(property)) ?? []
                const propertiesToRemove = currentBankAccountPropertiesIds.filter((property) => !data.properties?.includes(property))

                if (propertiesToAdd.length === 0 && propertiesToRemove.length === 0) {
                    onClose()
                    return
                }

                await addProperties({
                    bankAccountId: defaultValues.id,
                    propertyIds: propertiesToAdd ?? [],
                })

                await Promise.all(
                    propertiesToRemove.map(async (propertyId) => {
                        await deletePropertiesFromBankAccount({
                            bankAccountId: defaultValues.id,
                            propertyId: propertyId,
                        })
                    }),
                )
            } else {
                await addBankAccount(data)
            }
            onClose()
        },
        [addBankAccount, onClose, updateBankAccount, defaultValues, isAssociatingProperties, addProperties],
    )

    if (user?.roles.roleName === RoleName.FREE) {
        return (
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' width={"100%"} gap={2}>
                <Typography variant='body2'>{translate("not_allowed")}</Typography>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                        dispatch(setShowSubscriptionPage(true))
                        dispatch(setModalOpen(undefined))
                    }}
                >
                    {translate("change_plan")}
                </Button>
            </Box>
        )
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={1} flexDirection={"column"}>
                {((!isAssociatingProperties && defaultValues) || !defaultValues) && (
                    <>
                        <RHFTextField name='holder' label={translate("holder")} size='small' required />
                        <RHFSelect name={"bank"} label={translate("bank")} required>
                            {Object.keys(Banks)
                                .filter((key) => Number(key))
                                .map((bank) => (
                                    <MenuItem key={bank} value={bank}>
                                        {translate(`bank-name-${bank}`)}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                        <RHFTextField name='iban' label={translate("iban")} size='small' required />

                        <RHFSelect name={"currencyId"} label={translate("currency")} required>
                            {currencies ? (
                                currencies.map((currency) => (
                                    <MenuItem key={currency.id} value={currency.id}>
                                        {currency.code}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                    </>
                )}

                {(defaultValues == null || (defaultValues && isAssociatingProperties)) && (
                    <RHFAutocomplete
                        disabled={!!currentPropertyId}
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                                marginTop: isMobile && isAssociatingProperties ? 1 : undefined,
                            },
                            noOptionsText: translate("no_value_found"),
                            limitTags: 3,
                        }}
                        matchId
                        multiple
                        name={"properties"}
                        options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                        label={translate("associate_properties")}
                        freeSolo={false}
                    />
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button
                        fullWidth={isMobile}
                        color={"primary"}
                        onClick={onClose}
                        disabled={isAddingBankAccount || isUpdatingBankAccount || isAddingPropertiesToBankAccount}
                    >
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isAddingBankAccount || isUpdatingBankAccount || isAddingPropertiesToBankAccount}
                        loading={isAddingBankAccount || isUpdatingBankAccount || isAddingPropertiesToBankAccount}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
