import { RoleName, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import React, { ReactNode, useMemo } from "react"
import { Box } from "@mui/material"
import OwnerTasks from "../../pages/tasks/OwnerTasks"
import { AccountPopover, DashboardContext, Iconify, LanguagePopover, LayoutOption, useLocales, UserContextProvider } from "rentzz"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useChangeLanguage } from "../../utils/useChangeLanguage"
import { AppModals, setContext, setCurrentPropertyId, setCurrentRentingPeriodId, setModalOpen } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"
import { useLogout } from "../../mutations/user"

interface NoPropertyProps {
    children: ReactNode
}

export default function TicketsOnlyGuard({ children }: NoPropertyProps) {
    const { data: user } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { handleLangOption } = useChangeLanguage()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const { mutate: signOut } = useLogout()

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                href: "/",
                onClick: () => window.open(policiesPageUrl),
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                href: "/",
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
            },
        ]

        if (policiesPageUrl.length === 0) {
            toReturn.splice(1, 1)
        }

        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl, isWhitelabel])

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (user == null) return null

        return {
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user?.firstName} ${user?.lastName}`,
            email: user.email ?? "",
            currency: user.currency,
            availableCurrencies: [],
            onLanguageChange: handleLangOption,
            options,
            roleName: isWhitelabel ? undefined : user?.roles.roleName,
            validUntil: isWhitelabel || user?.roles.roleName === RoleName.FREE ? undefined : user?.roles.to,
        }
    }, [user, isWhitelabel, options, dispatch])

    if (user?.isTicketsOnly)
        return (
            <Box display='flex' flexDirection='column' height='100vh' width='100vw'>
                <Box display='flex' flexDirection='row' px={2} justifyContent='space-between'>
                    <Box />
                    <Box>
                        <UserContextProvider userData={userDataConfig}>
                            <>
                                <LanguagePopover />
                                <AccountPopover />
                            </>
                        </UserContextProvider>
                    </Box>
                </Box>
                <Box>
                    <OwnerTasks />
                </Box>
            </Box>
        )

    return <>{children}</>
}
