import { array, number, object, string } from "yup"
import { DateTime } from "luxon"

export const TaskNameSchema = object().shape({
    name: string().required("required").min(3, "minimum_characters"),
})
export const TaskFilesSchema = object().shape({
    files: array().required(),
})

export const AddNewSectionSchema = object().shape({
    name: string().required("required").min(3, "minimum_characters"),
})

export const AddNewTaskBaseSchema = () =>
    object().shape({
        name: string().required("required"),
        description: string().required("required"),
        files: array().required("required"),
        rentingPeriodId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .optional(),
    })

export const OwnerAddTaskSchema = () =>
    AddNewTaskBaseSchema().shape({
        sectionId: string().required("required"),
        deadline: string()
            .nullable()
            .test((date: string | undefined | null) => {
                if (date == null) return true
                const newDate = DateTime.fromISO(date)

                return newDate.isValid
            }),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
    })

export const TaskDescriptionSchema = object().shape({ description: string().required("required") })
export const TaskDeadlineSchema = object().shape({
    deadline: string()
        .nullable()
        .test((date: string | undefined | null) => {
            if (date == null) return true
            const newDate = DateTime.fromISO(date)

            return newDate.isValid
        }),
})
export const SectionIsLastSchema = object().shape({
    isFinal: string().required("required"),
})

export const TaskCommentBaseSchema = () =>
    object().shape({
        message: string().required("required").min(1, "minimum_characters"),
        taskId: string().required("required"),
        files: array().required("required"),
    })

export const OwnerTaskCommentSchema = () =>
    TaskCommentBaseSchema().shape({
        sectionId: string().required("required"),
    })

export const TenantTaskCommentSchema = () =>
    TaskCommentBaseSchema().shape({
        rentingPeriodId: number().required("required"),
    })

export const EditTaskSchema = object().shape({
    name: string().required("required").min(3, "minimum_characters"),
    sectionId: string().required("required"),
    rentingPeriodId: number().required("required"),
    description: string().required("required"),
})
