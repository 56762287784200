import { Box, CircularProgress, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material"
import { Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import React, { FocusEvent, useCallback } from "react"
import { Operation } from "fast-json-patch"
import { useTheme } from "@mui/material/styles"
import { updateRentingPeriodMutation } from "../../../../../mutations/tenants"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { useSelector } from "../../../../../redux/store"

export default function NotificationStatus() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()

    const handleNotificationStatus = useCallback(
        async (event: SelectChangeEvent<string>) => {
            const newValue = event.target?.value === "true"
            if (newValue === rentingPeriodDetails?.showExpirationForm) return

            const operations: Operation[] = [{ op: "replace", path: "/showExpirationForm", value: newValue }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    const handleDaysChange = useCallback(
        async (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = Number(event.target?.value)
            if (newValue === rentingPeriodDetails?.daysUntilExpirationToShowExpirationForm) return
            const operations: Operation[] = [{ op: "replace", path: "/daysUntilExpirationToShowExpirationForm", value: newValue }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    if (rentingPeriodDetails == null)
        return (
            <Box height={100} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Paper elevation={3} square={false}>
            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent='space-between' p={2}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Typography variant='subtitle1' sx={{ color: theme.palette.grey.A700 }}>
                        {translate("inform_tenant_renting_period_status")}
                    </Typography>
                    <LightTooltip
                        arrow
                        slotProps={{
                            tooltip: {
                                sx: {
                                    maxWidth: isMobile ? undefined : 500,
                                },
                            },
                        }}
                        title={
                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                                <Typography variant={"body2"}>{translate("inform_tenant_renting_period_status_tooltip-part1")}</Typography>
                                <Typography variant={"body2"}>{translate("inform_tenant_renting_period_status_tooltip-part2")}</Typography>
                                <Typography variant={"body2"}>{translate("inform_tenant_renting_period_status_tooltip-part3")}</Typography>
                                <Typography variant={"body2"}>{translate("inform_tenant_renting_period_status_tooltip-part4")}</Typography>
                            </Box>
                        }
                    >
                        <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                    </LightTooltip>
                </Box>

                <Box
                    display={"flex"}
                    gap={2}
                    flexDirection={isMobile ? "column" : "row"}
                    width={isMobile ? "100%" : undefined}
                    paddingTop={isMobile ? 2 : undefined}
                >
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {!isRentingPeriodUpdating && (
                            <Select
                                defaultValue={rentingPeriodDetails?.showExpirationForm.toString()}
                                onChange={handleNotificationStatus}
                                size='small'
                                sx={{ color: theme.palette.grey.A700 }}
                                disabled={!canWrite}
                                fullWidth={isMobile}
                            >
                                <MenuItem value={"false"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("no")}
                                </MenuItem>
                                <MenuItem value={"true"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("yes")}
                                </MenuItem>
                            </Select>
                        )}
                        {isRentingPeriodUpdating && <CircularProgress size={18} />}
                    </Box>
                    {rentingPeriodDetails.showExpirationForm && !isRentingPeriodUpdating && (
                        <TextField
                            size={"small"}
                            label={translate("enter_days_when_notification_display")}
                            defaultValue={rentingPeriodDetails.daysUntilExpirationToShowExpirationForm}
                            type={"number"}
                            onBlur={handleDaysChange}
                            disabled={!canWrite}
                            sx={{ width: isMobile ? "100%" : 160 }}
                            inputProps={{ min: 0 }}
                        />
                    )}
                </Box>
            </Stack>
        </Paper>
    )
}
