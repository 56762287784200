import React, { ReactElement } from "react"
import { AccountType, UserFlags, useUserDataQueryFn } from "../queries/userData"
import { Navigate } from "react-router-dom"
import { LoadingIcon } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

interface FeaturesGuardProps {
    children: ReactElement
    featureId: UserFlags
}

export default function FeaturesGuard({ children, featureId }: FeaturesGuardProps) {
    const { data: userData, isLoading } = useUserDataQueryFn()
    const isFeatureOn = useFeatureIsOn(featureId as string)
    if (userData == null || isLoading) return <LoadingIcon />

    if (featureId === UserFlags.WhiteLabel && userData.accountType === AccountType.WHITELABEL_ADMIN_USER) {
        if (isFeatureOn) return <>{children}</>
        return <Navigate replace to={"/dashboard"} />
    }

    if (featureId === UserFlags.HideTenantRevisions) {
        if (isFeatureOn) return <Navigate replace to={"/dashboard"} />
        return <>{children}</>
    }

    if (!isFeatureOn) return <Navigate replace to={"/dashboard"} />
    return <>{children}</>
}
