import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "../../redux/store"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { InsuranceType, PropertyFileType, PropertyInsurance } from "../../redux/slices/AddProperty"
import { CustomDataGrid, Label, useLocales } from "rentzz"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../utils/ActionWithTooltip"
import { AccountType, RoleName, useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import NoDataPage from "../../components/NoDataPage"
import { getNotificationIcon, getTooltipNotificationTile } from "./utils"
import { useUserInsuranceQueryFn } from "../../queries"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import NoPropertyPage from "../../guards/no-property/NoPropertyPage"
import CustomToolbar from "../../components/CustomToolbar"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"

interface DesktopInsuranceProps {
    getAmount: (value: number, currencyId: number) => string
}

export default function DesktopInsuranceList({ getAmount }: DesktopInsuranceProps) {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "endDate", sort: "desc" }])
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: insurancesData, isFetching } = useUserInsuranceQueryFn(paginationModel.page, paginationModel.pageSize, sortModel)
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const { canWrite } = usePermissions(PermissionType.Insurances)

    const insuranceColumns = useMemo(() => {
        const columns: GridColDef<PropertyInsurance>[] = [
            {
                field: "propertyId",
                type: "number",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "insurer",
                flex: 1,
                minWidth: 200,
                type: "string",
                headerAlign: "center",
                align: "center",
                headerName: translate("insurance.insurer"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.8,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("insurance.value"),
                valueFormatter: (value, row) => getAmount(value, row.currencyId),
            },
            {
                field: "insuranceType",
                type: "string",
                flex: 0.5,
                minWidth: 120,
                headerName: translate("insurance.type"),
                headerAlign: "center",
                align: "center",
                renderCell: (v) => {
                    return (
                        <Label color={v.value === InsuranceType.Mandatory ? "warning" : "info"}>
                            {translate(`insurance.type-${v.value as number}`)}
                        </Label>
                    )
                },
            },
            {
                field: "startDate",
                type: "date",
                headerName: translate("insurance.startDate"),
                filterable: true,
                minWidth: 120,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                valueFormatter: (value) => {
                    return (value as DateTime).toLocaleString(DateTime.DATE_SHORT)
                },
            },
            {
                field: "endDate",
                type: "date",
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                filterable: true,
                minWidth: 120,
                headerName: translate("insurance.endDate"),
                valueFormatter: (value) => {
                    return (value as DateTime).toLocaleString(DateTime.DATE_SHORT)
                },
            },
        ]
        if (currentPropertyId) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        return columns
    }, [currentPropertyId, translate, getAmount, userProperties])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            disableReorder: true,
            minWidth: 200,
            headerName: translate("actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            icon={getNotificationIcon(params.row, DESKTOP_ICON_SIZE, params.row.shouldNotifyForRenewal ? "primary" : "disabled")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                if (!params.row.canWrite) return
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditInsuranceNotification))
                            }}
                            label={translate("grid_actions.notifyPeriodStatus")}
                            disabled={params.row.endDate < DateTime.now() || !params.row.canWrite || userData?.roles.roleName === RoleName.FREE}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate(
                        userData?.roles.roleName === RoleName.FREE ? "permission_denied_action" : getTooltipNotificationTile(params.row),
                    ),
                    key: "notifyPeriodStatus",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.AddInsurance))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: !params.row.canWrite || userData?.roles.roleName === RoleName.FREE,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.row.id,
                                        type: PropertyFileType.Insurance,
                                        name: params.row.insurer,
                                        canWrite: params.row.canWrite,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: params.row.endDate < DateTime.now() ? "" : translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteInsurance))
                                dispatch(setEditingItem({ id: params.row.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
            ],
        }),
        [translate],
    )

    if (userProperties?.length === 0 && userData?.accountType === AccountType.WHITELABEL_USER) {
        return <NoPropertyPage />
    }

    return (
        <>
            <CustomToolbar
                onAddClick={canWrite ? () => dispatch(setModalOpen(AppModals.AddInsurance)) : undefined}
                numberOfItems={insurancesData?.count ?? 0}
                buttonText={"insurance.addInsurance"}
            />
            <CustomDataGrid
                paginationMode={"server"}
                rowsLoadingMode={"client"}
                isLoading={isFetching}
                columns={insuranceColumns}
                gridId={"DesktopInsuranceList"}
                rows={insurancesData?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:shield-off"} noDataText={"insurance.noInsurance"} />,
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={insurancesData?.count}
                actionsColumn={[actionsColumn]}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}
