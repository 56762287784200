import { Box, CircularProgress, InputAdornment, Paper, Stack, TextField, Typography } from "@mui/material"
import { Iconify, LightTooltip, useIsMobile, useLocales, useSnackbar } from "rentzz"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import React, { FocusEvent, useCallback } from "react"
import { Operation } from "fast-json-patch"
import { useTheme } from "@mui/material/styles"
import { updateRentingPeriodMutation } from "../../../../../mutations/tenants"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useSelector } from "../../../../../redux/store"

export default function LateFeesPercentage() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const { enqueueSnackbar } = useSnackbar()

    const handleLateFeesPercentage = useCallback(
        async (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = Number(event.target?.value)
            if (newValue === rentingPeriodDetails?.lateFeesPercentage) return
            if (newValue < 0 || newValue > 100) {
                enqueueSnackbar(translate("invalid_late_fee_value"), { variant: "error" })
                return
            }
            const operations: Operation[] = [{ op: "replace", path: "/lateFeesPercentage", value: newValue }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    if (rentingPeriodDetails == null)
        return (
            <Box height={100} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Paper elevation={3} square={false}>
            <Stack direction={isMobile ? "column" : "row"} alignItems='center' justifyContent='space-between' p={2}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                    <Typography variant='subtitle1' sx={{ color: theme.palette.grey.A700 }}>
                        {translate("late_fees_percentage_title")}
                    </Typography>
                    <LightTooltip
                        arrow
                        slotProps={{
                            tooltip: {
                                sx: {
                                    maxWidth: isMobile ? undefined : 500,
                                },
                            },
                        }}
                        title={
                            <Box display={"flex"} flexDirection={"column"} gap={1}>
                                <Typography variant={"body2"}>{translate("late_fees_percentage_description")}</Typography>
                            </Box>
                        }
                    >
                        <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                    </LightTooltip>
                </Box>

                <Box
                    display={"flex"}
                    gap={2}
                    flexDirection={isMobile ? "column" : "row"}
                    width={isMobile ? "100%" : undefined}
                    paddingTop={isMobile ? 2 : undefined}
                >
                    {isRentingPeriodUpdating && <CircularProgress size='2rem' />}
                    {!isRentingPeriodUpdating && (
                        <TextField
                            size={"small"}
                            defaultValue={rentingPeriodDetails.lateFeesPercentage}
                            type={"number"}
                            disabled={!canWrite}
                            onBlur={handleLateFeesPercentage}
                            sx={{ width: isMobile ? "100%" : 100 }}
                            inputProps={{ min: 0 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position={"end"}>
                                        <Typography>%</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </Box>
            </Stack>
        </Paper>
    )
}
