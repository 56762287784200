import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import LinkBankAccountToPropertyForm from "../../../../../sections/nomenclature/bankAccounts/LinkBankAccountForm"
import { useAllUserBankAccounts } from "../../../../../queries/userData"

export default function LinkBankAccountToPropertyGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)
    const { data: userBankAccounts } = useAllUserBankAccounts()

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [modalOpen, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && modalOpen === AppModals.LinkBankAccountToProperty}
                onClose={handleClose}
                maxWidth={"xs"}
                title={userBankAccounts?.count === 0 ? "" : translate("link_bank_accounts")}
            >
                <LinkBankAccountToPropertyForm onClose={handleClose} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.LinkBankAccountToProperty}
                onClose={handleClose}
                isLoading={false}
                title={translate("link_bank_accounts")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <LinkBankAccountToPropertyForm onClose={handleClose} />
            </DetailsSheet>
        </>
    )
}
