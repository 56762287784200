import ResendManagerInvitationGuard from "../managers/ResendManagerInvitationGuard"
import AddNewInvitationSheet from "../managers/AddNewInvitationSheet"
import DeleteOrRemoveManagerGuard from "../managers/DeleteOrRemoveManagerGuard"
import ManagerDetailsSheet from "../../../../sections/propertyDetails/General/management/managers/ManagerDetailsSheet"
import UpdatePropertiesArchivedStatus from "../../../../pages/properties/UpdatePropertiesArchivedStatus"
import ProviderDetailsSheet from "./ProviderDetailsSheet"
import DeleteProvider from "./DeleteProvider"
import AddExpenseProviderGuard from "./AddExpenseProviderGuard"
import PendingReadingsProviderGuard from "./PendingReadingsProviderGuard"
import MobileUnpaidExpenseDetailsSheet from "../../../../pages/properties/MobileUnpaidExpenseDetailsSheet"

export default function PropertyGuard() {
    return (
        <>
            <ManagerDetailsSheet />
            <ResendManagerInvitationGuard />
            <AddNewInvitationSheet />
            <DeleteOrRemoveManagerGuard />
            <UpdatePropertiesArchivedStatus />
            <MobileUnpaidExpenseDetailsSheet />
            <ProviderDetailsSheet />
            <DeleteProvider />
            <AddExpenseProviderGuard />
            <PendingReadingsProviderGuard />
        </>
    )
}
