import { AppModals } from "../../../redux/slices/App"
import { getIncomeTitleColorFromStatus } from "../../../sections/propertyDetails/Tenants/utils"
import { IncomeStatus } from "../../../redux/slices/AddProperty"
import AddIncomeContent from "../../../sections/income/add-income/AddIncomeContent"
import AddIncomeDialog from "../../../sections/income/add-income/AddIncomeDialog"
import React, { useCallback } from "react"
import { AddIncomeRequest } from "../../../sections/income/add-income/types"
import * as jsonpatch from "fast-json-patch"
import { addIncomeMutation, updateIncomeMutation } from "../../../mutations/incomes"
import { useSingleIncomeQuery } from "../../../queries/income"
import { useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import { useSingleExpenseQuery } from "../../../queries/expenses"

interface Props {
    onClose: () => void
}
export default function AddOrEditIncomeGuard({ onClose }: Props) {
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addIncome, isPending: isAddingIncome } = addIncomeMutation()
    const { mutateAsync: updateIncome, isPending: isUpdatingIncome } = updateIncomeMutation()
    const { data: currentIncome } = useSingleIncomeQuery(editingItem?.id)
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)

    const onAddIncomeSave = useCallback(
        async (data: AddIncomeRequest) => {
            if (currentIncome != null && modalOpen !== AppModals.LinkExpenseToIncomeAsOwner) {
                const operations = jsonpatch.compare(
                    { ...currentIncome, date: currentIncome.date.toISO(), files: [] },
                    { ...currentIncome, ...data, referenceId: undefined },
                )
                if (!data.id) return
                await updateIncome({
                    propertyId: currentIncome.propertyId,
                    incomeId: data.id,
                    operations,
                })
            } else {
                await addIncome(data)
            }

            onClose()
        },
        [currentIncome, modalOpen, updateIncome, addIncome, onClose],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.EditIncome ? "income-table.editIncome" : "income-table.addIncome")}
                isOpen={
                    isMobile &&
                    (modalOpen === AppModals.AddIncome || modalOpen === AppModals.LinkExpenseToIncomeAsOwner || modalOpen === AppModals.EditIncome)
                }
                onClose={onClose}
                headerColor={
                    modalOpen === AppModals.LinkExpenseToIncomeAsOwner
                        ? currentExpense?.assignee === ExpenseAssignee.ME
                            ? theme.palette.primary.main
                            : editingItem?.backgroundColor
                        : currentIncome
                          ? getIncomeTitleColorFromStatus(currentIncome?.status ?? IncomeStatus.Accepted, theme)
                          : theme.palette.primary.main
                }
            >
                <AddIncomeContent
                    onClose={onClose}
                    defaultValues={modalOpen === AppModals.LinkExpenseToIncomeAsOwner ? (currentExpense ?? undefined) : currentIncome}
                    isLoading={isUpdatingIncome || isAddingIncome}
                    onSave={onAddIncomeSave}
                />
            </DetailsSheet>
            <AddIncomeDialog
                isVisible={
                    !isMobile &&
                    (modalOpen === AppModals.AddIncome || modalOpen === AppModals.LinkExpenseToIncomeAsOwner || modalOpen === AppModals.EditIncome)
                }
                onSave={onAddIncomeSave}
                onClose={onClose}
                defaultValues={modalOpen === AppModals.LinkExpenseToIncomeAsOwner ? (currentExpense ?? undefined) : currentIncome}
                isLoading={isUpdatingIncome || isAddingIncome}
            />
        </>
    )
}
