import { useMutation, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import { RentingPeriod, RentingPeriodStatusCode } from "../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import * as jsonpatch from "fast-json-patch"
import { setErrorMessage } from "../redux/slices/App"
import { AxiosError } from "axios"
import { CustomFile, useIsMobile } from "rentzz"
import { useDispatch, useSelector } from "../redux/store"
import { AddTenantRequest } from "../sections/types/AddTenant"
import { BackendError } from "../sections/types/user"
import { AddJournalRequest } from "../sections/propertyDetails/Tenants/rentingPeriodDetails/journal/AddOrEditJournalForm"
import { ExpirationReason } from "../sections/tenantContext/ShowExpiration"

export const addTenantMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    return useMutation({
        mutationFn: ({ data }: { data: AddTenantRequest; propertyId?: number }) => Api.addTenant(data),
        onMutate: async ({ data, propertyId }) => {
            dispatch(setErrorMessage(undefined))
            await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.Tenants, { id: propertyId }] })
            const previousData = queryClient.getQueryData([ComplexQueryIDs.Tenants, { id: propertyId }])

            queryClient.setQueryData([ComplexQueryIDs.Tenants, { id: propertyId }], (oldData) => {
                return addTenantToQuery(oldData, { data })
            })

            return { previousData }
        },
        onSettled: async (data, error, { propertyId }, context) => {
            if (error && context) {
                queryClient.setQueryData([ComplexQueryIDs.Tenants, { id: propertyId }], context?.previousData)
                if (isMobile) {
                    const errorResponse = error as AxiosError
                    const errorMessage = errorResponse?.response?.data as BackendError
                    dispatch(setErrorMessage(errorMessage.Message))
                }
            }
            await invalidateQueries([
                ComplexQueryIDs.SummaryCard,
                ComplexQueryIDs.DashboardChart,
                ComplexQueryIDs.DashboardTable,
                ComplexQueryIDs.Meters,
                ComplexQueryIDs.SectionTasks,
                ComplexQueryIDs.RentingPeriodsReport,
                { query: ComplexQueryIDs.ContractTemplates, params: { isArchived: false } },
                SimpleQueryIDs.UserProperties,
                ComplexQueryIDs.TenantsPagination,
                { query: ComplexQueryIDs.Tenants, params: { id: data?.propertyId } },
                { query: ComplexQueryIDs.Tenants, params: { id: undefined } },
            ])
        },
    })
}

export const deleteTenantMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ tenantId, sendDataToOwner }: { tenantId: number; propertyId?: number; sendDataToOwner: boolean }) =>
            Api.deleteTenant({ tenantId, sendDataToOwner }),
        onMutate: async ({ tenantId, propertyId }) => {
            await queryClient.cancelQueries({ queryKey: [ComplexQueryIDs.Tenants, { id: propertyId }] })
            const previousData = queryClient.getQueryData([ComplexQueryIDs.Tenants, { id: propertyId }])

            queryClient.setQueryData([ComplexQueryIDs.Tenants, { id: propertyId }], (oldData) => {
                const converted = oldData as RentingPeriod[]
                return [...(converted ?? []).filter((tenant) => tenant.id !== tenantId)]
            })

            return { previousData }
        },
        onSettled: async (data, error, { propertyId }, context) => {
            if (error) {
                queryClient.setQueryData([ComplexQueryIDs.Tenants, { id: propertyId }], context?.previousData)
            }
            await invalidateQueries([
                ComplexQueryIDs.SummaryCard,
                ComplexQueryIDs.DashboardChart,
                ComplexQueryIDs.DashboardTable,
                SimpleQueryIDs.UserProperties,
                ComplexQueryIDs.Tenants,
                ComplexQueryIDs.TenantsPagination,
                ComplexQueryIDs.RentingPeriodsReport,
            ])
        },
    })
}
export const updateRentingPeriodMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ tenantId, operations }: { propertyId?: number; tenantId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateRentingPeriod({
                tenantId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Tenants,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.SectionTasks,
                    SimpleQueryIDs.UserProperties,
                    SimpleQueryIDs.PendingExpense,
                    ComplexQueryIDs.TenantsPagination,
                    ComplexQueryIDs.RentingPeriodsReport,
                    ComplexQueryIDs.ExpenseLateFees,
                    ComplexQueryIDs.ExpenseLateFees,
                ])
            }
        },
    })
}

export const createRentingPeriodInvoice = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, from, to, invoiceDate }: { rentingPeriodId: number; from: string; to: string; invoiceDate: string }) =>
            Api.createInvoice(rentingPeriodId, from, to, invoiceDate),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expense,
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.Events,
                    ComplexQueryIDs.ExpenseSummary,
                    ComplexQueryIDs.ExpenseSummaryReport,
                    ComplexQueryIDs.ExpenseLateFees,
                ])
            }
        },
    })
}

export const updateRentingPeriodInvoicing = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            rentingPeriodId,
            type,
            id,
        }: {
            rentingPeriodId: number
            type: "series" | "company" | "reinvoicing-series" | "reinvoicing-vat"
            id?: number
        }) => Api.setRentingPeriodInvoicing(rentingPeriodId, type, id),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodDetails, SimpleQueryIDs.PendingExpense])
            }
        },
    })
}

function addTenantToQuery(oldData: unknown, newData: { data: AddTenantRequest }) {
    const converted = oldData as RentingPeriod[]
    return [
        {
            ...newData.data,
            id: -1,
            rentingPeriodStatus: RentingPeriodStatusCode.Created,
            currencyId: Number(newData.data.rentDetails?.currencyId),
            moveInDate: DateTime.fromISO(newData.data.details ? newData.data.details.moveInDate : ""),
            moveOutDate: DateTime.fromISO(newData.data.details ? newData.data.details.moveOutDate : ""),
            invitedDate: DateTime.now(),
        },
        ...(converted ?? []),
    ]
}

export const resendTenantInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, email, invitationLanguage }: { rentingPeriodId: number; email: string; invitationLanguage: string }) =>
            Api.resendTenantInvitation(rentingPeriodId, email, invitationLanguage),
        onSuccess: async () => {
            await invalidateQueries([ComplexQueryIDs.Tenants, ComplexQueryIDs.RentingPeriodDetails, ComplexQueryIDs.TenantsPagination])
        },
    })
}

export const acceptOnTenantBehalfMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            rentingPeriodId,
            email,
            tenantFirstName,
            tenantLastName,
        }: {
            rentingPeriodId: number
            email: string
            tenantFirstName: string
            tenantLastName: string
        }) => Api.acceptOnTenantBehalf(rentingPeriodId, email, tenantFirstName, tenantLastName),
        onSuccess: async () => {
            await invalidateQueries([
                ComplexQueryIDs.Tenants,
                ComplexQueryIDs.RentingPeriodDetails,
                ComplexQueryIDs.TenantsPagination,
                ComplexQueryIDs.RentingPeriodsReport,
            ])
        },
    })
}

export const removeTenantInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, email }: { rentingPeriodId: number; email: string }) => Api.removeTenantInvitation(rentingPeriodId, email),
        onSuccess: async () => {
            await invalidateQueries([
                ComplexQueryIDs.Tenants,
                ComplexQueryIDs.RentingPeriodDetails,
                ComplexQueryIDs.TenantsPagination,
                ComplexQueryIDs.RentingPeriodsReport,
            ])
        },
    })
}

export const removeAcceptedTenantInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, email }: { rentingPeriodId: number; email: string }) =>
            Api.removeAcceptedTenantInvitation(rentingPeriodId, email),
        onSuccess: async () => {
            await invalidateQueries([
                ComplexQueryIDs.Tenants,
                ComplexQueryIDs.RentingPeriodDetails,
                ComplexQueryIDs.TenantsPagination,
                ComplexQueryIDs.RentingPeriodsReport,
            ])
        },
    })
}

export const addNewInvitationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ rentingPeriodId, email, invitationLanguage }: { rentingPeriodId: number; email: string; invitationLanguage: string }) =>
            Api.addInvitation(rentingPeriodId, email, invitationLanguage),
        onSuccess: async () => {
            await invalidateQueries([
                ComplexQueryIDs.Tenants,
                ComplexQueryIDs.RentingPeriodDetails,
                ComplexQueryIDs.TenantsPagination,
                ComplexQueryIDs.RentingPeriodsReport,
            ])
        },
    })
}

export const addJournalMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddJournalRequest }) => Api.addJournal(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Journal])
            }
        },
    })
}

export const updateJournalMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ journalId, operations }: { journalId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateJournal({
                journalId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Journal])
            }
        },
    })
}

export const deleteJournalMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ journalId }: { journalId: number; propertyId?: number }) => Api.deleteJournal({ journalId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.Journal])
            }
        },
    })
}

export const useTenantResponseMutation = () => {
    return useMutation({
        mutationFn: ({ tenantId, response, leaveDate }: { tenantId: number; response: ExpirationReason; leaveDate?: string | null }) =>
            Api.tenantResponse({ tenantId, response, leaveDate }),
    })
}

export const useRevisionTenantResponseMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            rentingPeriodId,
            date,
            maintenanceScheduleId,
        }: {
            rentingPeriodId: number
            date: string | null
            maintenanceScheduleId: number
        }) => Api.revisionTenantResponse({ rentingPeriodId, date, maintenanceScheduleId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.TenantRevisions])
            }
        },
    })
}

export const sendTenantNotificationMutation = (rentingPeriodId?: number) => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    return useMutation({
        mutationFn: ({ content, subject, to, files }: { content: string; subject: string; to: string[]; files?: CustomFile[] }) =>
            Api.sendTenantNotification(content, subject, to, files, currentRentingPeriodId ?? rentingPeriodId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodDetails])
            }
        },
    })
}
