import { CustomPopover } from "rentzz"
import React, { MouseEvent } from "react"
import AddNewTenantInvitationForm from "./AddNewTenantInvitationForm"
import { UsePopoverReturn } from "rentzz/src/components/custom-popover/types"

interface Props {
    popover: UsePopoverReturn
}
export default function AddNewInvitation({ popover }: Props) {
    return (
        <CustomPopover
            open={popover.open}
            anchorEl={popover.anchorEl}
            slotProps={{
                arrow: { placement: "top-right" },
            }}
            onClose={(event: MouseEvent<HTMLElement>) => {
                event.stopPropagation()
                popover.onClose()
            }}
            sx={{
                mt: 1.5,
                ml: 0.75,
                width: 300,
                "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
            }}
        >
            <AddNewTenantInvitationForm onClose={popover.onClose} />
        </CustomPopover>
    )
}
