import { FormProvider, RHFAutocomplete, RHFCheckbox, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddNewInvoicingClientSchema } from "../../../../validations/invoicing-configuration"
import { addNewInvoicingClient, AddNewInvoicingClientRequest, updateInvoicingClient } from "../../../../mutations/invoicingClient"
import { InvoicingConfigurationClient, useAllUserInvoicingCompanies } from "../../../../queries/invoicing-configuration"
import { useCitiesQueryFn, useCountiesQueryFn } from "../../../../queries"
import { Banks } from "../../../../queries/userData"
import * as jsonpatch from "fast-json-patch"

interface Props {
    onClose: () => void
    defaultValues?: InvoicingConfigurationClient | null
}

export default function AddOrEditInvoicingClientForm({ onClose, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { mutateAsync: createNewInvoicingClient, isPending: isCreatingNewInvoicingClient } = addNewInvoicingClient()
    const { mutateAsync: updateClient, isPending: isUpdatingClient } = updateInvoicingClient()
    const { data: userInvoicingCompanies } = useAllUserInvoicingCompanies()
    const { data: allCounties, isFetching: allCountiesFetching } = useCountiesQueryFn()
    const [county, setCounty] = useState<string | undefined>(undefined)
    const { data: allCities, isFetching: allCitiesFetching } = useCitiesQueryFn(allCounties?.find((c) => c.name === county)?.countyId)

    const methods = useForm<AddNewInvoicingClientRequest>({
        resolver: yupResolver(AddNewInvoicingClientSchema),
        mode: "onChange",
        defaultValues: {
            isTaxPayer: false,
        },
    })

    const { handleSubmit, watch, reset, resetField } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "county") {
                setCounty(allCounties?.find((c) => c.name === v.county)?.name ?? "")
                resetField("city")
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, allCounties])

    const { currentCounty, currentCity } = useMemo(() => {
        const countyName = allCounties?.find((c) => c.countyId === Number(defaultValues?.county))?.name ?? ""
        const cityName = allCities?.find((c) => c.cityId === Number(defaultValues?.city))?.name ?? ""
        return { currentCounty: countyName, currentCity: cityName }
    }, [defaultValues, allCounties, allCities])

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                county: currentCounty,
                city: currentCity,
            })
            setCounty(currentCounty.toString())
        }
    }, [defaultValues, reset, currentCity, currentCounty, setCounty])

    const onSave = useCallback(
        async (data: AddNewInvoicingClientRequest) => {
            const countyId = allCounties?.find((c) => c.name === data.county)?.countyId ?? 0
            const cityId = allCities?.find((c) => c.name === data.city)?.cityId ?? 0

            data.city = cityId.toString()
            data.county = countyId.toString()

            if (defaultValues != null) {
                const operations = jsonpatch.compare(
                    {
                        ...defaultValues,
                        isFromExternalSystem: undefined,
                        createdAt: undefined,
                        id: undefined,
                        lastModifiedAt: undefined,
                        invoicingCompanyName: undefined,
                    },
                    {
                        ...data,
                        isFromExternalSystem: undefined,
                        createdAt: undefined,
                        id: undefined,
                        lastModifiedAt: undefined,
                        invoicingCompanyName: undefined,
                    },
                )
                await updateClient({
                    invoicingClientId: defaultValues.id,
                    operations,
                })
            } else {
                await createNewInvoicingClient(data)
            }

            onClose()
        },
        [createNewInvoicingClient, onClose, allCounties, allCities],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                <RHFSelect required name='invoicingCompanyId' label={translate("company")} size='small'>
                    {userInvoicingCompanies?.map((r) => (
                        <MenuItem value={r?.id} key={r?.id} style={{ textTransform: "none" }}>
                            {r.name}
                        </MenuItem>
                    ))}
                </RHFSelect>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFTextField name='cif' label={translate("cif")} size='small' required />
                    <RHFTextField name='name' label={translate("name")} size='small' required />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFAutocomplete
                        name={"county"}
                        textFieldProps={{ size: "small" }}
                        options={allCounties?.map((c) => c.name) ?? []}
                        label={translate("county")}
                        loading={allCountiesFetching}
                        freeSolo={false}
                        autocompleteProps={{
                            size: "small",
                            noOptionsText: translate("no_value_found"),
                            isOptionEqualToValue: (option, value) => option === value || option.name === value,
                        }}
                        required
                    />

                    <RHFAutocomplete
                        name={"city"}
                        options={allCities?.map((c) => c.name) ?? []}
                        loading={allCitiesFetching}
                        label={translate("city")}
                        freeSolo={false}
                        autocompleteProps={{
                            disabled: allCities?.length === 0,
                            size: "small",
                            noOptionsText: translate("no_value_found"),
                            isOptionEqualToValue: (option, value) => option === value || option.name === value,
                        }}
                        textFieldProps={{ disabled: allCities?.length === 0, size: "small" }}
                        required
                    />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFTextField name='email' label={translate("email")} size='small' required />
                    <RHFTextField name='phone' label={translate("phone")} size='small' />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFTextField name='country' label={translate("country")} size='small' />
                    <RHFTextField name='address' label={translate("address")} size='small' />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFSelect name={"bank"} label={translate("bank")}>
                        {Object.keys(Banks)
                            .filter((key) => Number(key))
                            .map((bank) => (
                                <MenuItem key={bank} value={bank}>
                                    {translate(`bank-name-${bank}`)}
                                </MenuItem>
                            ))}
                    </RHFSelect>
                    <RHFTextField name='iban' label={translate("iban")} size='small' />
                </Box>
                <RHFCheckbox name='isTaxPayer' label={translate("is-tax-payer")} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onClose} disabled={isCreatingNewInvoicingClient || isUpdatingClient}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isCreatingNewInvoicingClient || isUpdatingClient}
                        loading={isCreatingNewInvoicingClient || isUpdatingClient}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
