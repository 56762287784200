import { Box, Paper, Stack, Typography } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import { useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../queries/userData"
import { useDispatch, useSelector } from "../../redux/store"
import { useIntl } from "react-intl"
import { sum } from "lodash"
import { AppModals, setModalOpen } from "../../redux/slices/App"

const MobilePropertyUnpaidExpenses = () => {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: properties } = useUserPropertiesQuery()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const intl = useIntl()

    const formatNumber = useCallback(
        (amount: number) => {
            return intl.formatNumber(amount, {
                style: "currency",
                currency: userData?.currency.code,
            })
        },
        [intl, userData],
    )

    const currentProperty = useMemo(() => properties?.find((p) => p.id === currentPropertyId), [currentPropertyId, properties])

    return (
        <Box sx={{ borderColor: "divider" }}>
            <Paper
                elevation={3}
                square={false}
                onClick={() => {
                    dispatch(setModalOpen(AppModals.PropertyUnpaidExpensesDetailsSheet))
                }}
            >
                <Stack direction='column' alignItems='center' p={2}>
                    <Typography variant='subtitle2' sx={{ color: theme.palette.grey.A700 }}>
                        {translate("unpaid_amount")}
                    </Typography>
                    <Typography variant='caption' sx={{ color: theme.palette.grey.A700 }}>
                        {formatNumber(sum(currentProperty?.unpaidExpensesStatus.map((e) => e.amount)))}
                    </Typography>
                </Stack>
            </Paper>
        </Box>
    )
}

export default MobilePropertyUnpaidExpenses
