import { Controller, useFormContext } from "react-hook-form"
import { CircularProgress, InputAdornment, TextField, TextFieldProps } from "@mui/material"
import { Iconify, useLocales } from "rentzz"
import React, { useEffect, useMemo, useRef } from "react"

type RHFSelectProps = TextFieldProps & {
    name: string
    children: React.ReactNode
    showLockWhenDisabled?: boolean
    fetchNext: () => void
    hasNext?: boolean
    isLoading: boolean
}
export default function RHFLazyLoadSelect({
    name,
    helperText,
    children,
    showLockWhenDisabled = true,
    isLoading,
    fetchNext,
    hasNext,
    ...other
}: RHFSelectProps) {
    const { control } = useFormContext()
    const { translate } = useLocales()
    const ref = useRef<HTMLDivElement>(null)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNext) {
                    fetchNext()
                }
            }),
        [fetchNext, hasNext],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [observer])

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => {
                return (
                    <>
                        <TextField
                            {...field}
                            value={field.value ?? ""}
                            select
                            fullWidth
                            size={"small"}
                            error={!!error}
                            helperText={error ? translate(error?.message) : helperText}
                            {...other}
                            SelectProps={{
                                // renderValue: () => {
                                //     if (field.value) return name
                                //     return <Typography sx={{ color: theme.palette.grey["500"] }}>{placeholder}</Typography>
                                // },
                                MenuProps: {
                                    keepMounted: true,
                                    sx: {
                                        maxHeight: 300,
                                    },
                                },
                            }}
                            sx={{
                                ...other.sx,
                            }}
                            InputProps={{
                                startAdornment: other.disabled && showLockWhenDisabled && (
                                    <InputAdornment position={"start"}>
                                        <Iconify icon={"mdi:lock"} width={18} height={18} />
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {children}
                            {(isLoading || hasNext) && (
                                <div
                                    ref={ref}
                                    style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10, paddingBottom: 10 }}
                                >
                                    <CircularProgress size={"1rem"} />
                                </div>
                            )}
                        </TextField>
                    </>
                )
            }}
        />
    )
}
