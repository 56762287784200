import { CustomDataGrid, Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { GridColDef } from "@mui/x-data-grid-pro"
import { PropertySummary, useUserPropertiesQuery } from "../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import EditPropertyPercentageForm from "./EditPropertyPercentageForm"
import { useUserGroupsQueryFn } from "../../../queries/groups"
import CustomToolbar from "../../../components/CustomToolbar"
import { useNavigate } from "react-router-dom"
import { Button } from "@mui/material"
import UnpaidExpensesColumn from "../../../pages/properties/UnpaidExpensesColumn"
import { sum } from "lodash"
import { getReverseTextColor } from "../../../utils/helpers"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"

export interface GroupProperty {
    id: number
    name: number
    county: string
    city: string
    expenseShare: number
}
export default function DesktopGroupPropertiesPage() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: groups } = useUserGroupsQueryFn()
    const { currentGroupId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()
    const currentGroup = useMemo(() => groups?.find((g) => g.id === currentGroupId), [currentGroupId, groups])
    const navigate = useNavigate()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    const currentProperties = useMemo(() => {
        if (currentGroup == null || properties == null) return

        return currentGroup.properties.map((p) => {
            const match = properties.find((property) => property.id == p.propertyId)
            const reverseMatch = currentGroup.properties.find((p) => p.propertyId === match?.id)
            return {
                id: match!.id,
                name: match!.label,
                county: match!.county,
                city: match!.city,
                expenseShare: reverseMatch?.expenseShare,
                area: match!.area,
                unpaidExpensesStatus: match!.unpaidExpensesStatus,
                labelId: match!.labelId,
                rentPrice: match!.rentPrice,
                currencyId: match!.currencyId,
                labelColor: match!.labelColor,
                labelName: match!.labelName,
            }
        })
    }, [properties, currentGroup])

    const propertiesColumns = useMemo(() => {
        const columns: GridColDef<PropertySummary>[] = [
            {
                field: "name",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("properties"),
                renderCell: ({ value, row }) => {
                    return (
                        <Button color='primary' onClick={() => navigate(`/properties/${row.id}/general`)}>
                            {value}
                        </Button>
                    )
                },
            },
            {
                field: "city",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("city"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "area",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("area"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "unpaidExpensesStatus",
                type: "number",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("unpaid_amount"),
                renderCell: ({ row }) => <UnpaidExpensesColumn unpaidExpensesStatus={row.unpaidExpensesStatus} />,
                valueGetter: (_, row) => {
                    return sum(row.unpaidExpensesStatus?.map((e) => e.amount) ?? [])
                },
            },
            {
                field: "labelId",
                type: "string",
                flex: 1,
                minWidth: 100,
                headerAlign: "center",
                align: "center",
                headerName: translate("labelDescription"),
                renderCell: (v) => {
                    if (v.value == null) return ""
                    return (
                        <Label
                            style={{
                                textTransform: "none",
                                backgroundColor: `#${v.row.labelColor}`,
                                color: getReverseTextColor(`#${v.row.labelColor}`),
                            }}
                        >
                            {v.row.labelName}
                        </Label>
                    )
                },
            },
            {
                field: "rentPrice",
                type: "number",
                flex: 0.7,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("rentPrice"),
                renderCell: ({ value, row }) => {
                    return getAmount(value, row.currencyId as number) !== "" ? (
                        getAmount(value, row.currencyId as number)
                    ) : (
                        <ActionWithTooltip
                            element={<Iconify icon={"mdi:account-cancel"} width={20} height={20} />}
                            tooltipText={translate("still_no_tenant")}
                        />
                    )
                },
            },
            {
                field: "expenseShare",
                type: "number",
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("percentage"),
                renderCell: (v) => {
                    return <EditPropertyPercentageForm currentProperty={v.row} />
                },
            },
        ]

        return columns
    }, [translate])

    if (currentGroup == null) return <LoadingIcon />

    return (
        <>
            <CustomToolbar
                onAddClick={() => {
                    dispatch(setModalOpen(AppModals.EditGroupProperties))
                    dispatch(setEditingItem({ id: currentGroupId }))
                }}
                numberOfItems={currentProperties?.length}
                buttonText={"handle_properties"}
            />
            <CustomDataGrid
                sortingMode='client'
                columns={propertiesColumns}
                idKey={"id"}
                gridId={"DesktopGroupPropertiesPage"}
                getRowId={(row) => row.id}
                actionsColumn={[]}
                rows={currentProperties ?? []}
            />
        </>
    )
}
