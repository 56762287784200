import React, { useEffect } from "react"
import { AppContext, FormProvider, RHFDatePicker, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TaskDeadlineSchema } from "../../../validations/kanban"
import * as jsonpatch from "fast-json-patch"
import { updateTaskMutation } from "../../../mutations/kanban"
import { useSelector } from "../../../redux/store"
import { DateTime } from "luxon"
import { Box, IconButton } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../utils/helpers"

interface TaskDescriptionRequest {
    deadline?: string | null
}
interface Props {
    deadline?: DateTime
    taskId: string
    sectionId?: string
    canEdit?: boolean
}
export default function TaskDeadlineForm({ deadline, taskId, sectionId, canEdit }: Props) {
    const { mutateAsync: editTask } = updateTaskMutation()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { context } = useSelector((state) => state.appState)

    const methods = useForm<TaskDescriptionRequest>({
        resolver: yupResolver(TaskDeadlineSchema),
        defaultValues: {
            deadline: deadline?.toISODate(),
        },
    })

    const { handleSubmit, setValue, watch } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "deadline") {
                const operations = jsonpatch.compare({ deadline }, { deadline: v.deadline != null ? DateTime.fromISO(v.deadline).toISODate() : null })
                if (deadline === v.deadline) return
                if (context === AppContext.Owner) {
                    if (sectionId == null) return
                    editTask({
                        sectionId,
                        taskId,
                        operations,
                        newIndex: -1,
                    })
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, deadline])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
            <Box display='flex' flexDirection='row' gap={1}>
                {isMobile ? translate("task_deadline") : null}
                {canEdit ? <RHFDatePicker name='deadline' required /> : deadline?.toLocaleString(DateTime.DATE_SHORT)}

                {canEdit && (
                    <IconButton
                        onClick={() => {
                            setValue("deadline", null)
                        }}
                    >
                        <DeleteIcon sx={{ fontSize: isMobile ? MOBILE_ICON_SIZE : DESKTOP_ICON_SIZE }} color='error' />
                    </IconButton>
                )}
            </Box>
        </FormProvider>
    )
}
