import { RHFAutocomplete, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { PaymentSubtype, PaymentType } from "../redux/slices/AddProperty"
import PaymentTypeOption from "./providers/PaymentTypeOption"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"

interface RHFPaymentTypeInput {
    name: string
    onlyExpenseSubtypes?: boolean
}
export default function RHFPaymentTypeInput({ name, onlyExpenseSubtypes }: RHFPaymentTypeInput) {
    const { translate } = useLocales()
    const expenseOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)

    const allOptions = useMemo(() => {
        if (onlyExpenseSubtypes)
            return Object.values(PaymentSubtype)
                .filter((key) => !isNaN(Number(key)))
                .map((paymentSubtype) => ({ id: paymentSubtype, label: translate(`payment-subtype-${paymentSubtype}`) }))
        return [
            { id: "expenses-group", label: translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-1`) },
            ...Object.values(PaymentSubtype)
                .filter((key) => !isNaN(Number(key)))
                .map((paymentSubtype) => ({ id: `${PaymentType.Expense}_${paymentSubtype}`, label: translate(`payment-subtype-${paymentSubtype}`) })),
            { id: PaymentType.Rent, label: translate("income-table.incomeType-1") },
            { id: PaymentType.SecurityDeposit, label: translate("income-table.incomeType-2") },
            { id: PaymentType.Fees, label: translate("income-table.incomeType-3") },
            { id: PaymentType.Damages, label: translate("income-table.incomeType-4") },
        ]
    }, [translate, expenseOverrideHeader, onlyExpenseSubtypes])

    return (
        <RHFAutocomplete
            name={name}
            textFieldProps={{ size: "small" }}
            options={allOptions}
            required
            label={translate("income-table.chooseExpenseType")}
            freeSolo={false}
            matchId
            autocompleteProps={{
                size: "small",
                renderOption: (props, option) => PaymentTypeOption(option, onlyExpenseSubtypes ?? false, props),
                getOptionLabel: (option) =>
                    option.id.toString().startsWith(`${PaymentType.Expense}_`)
                        ? translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-1`) + ` - ${option.label}`
                        : option.label,
            }}
        />
    )
}
