import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material"
import MobileProvidersWithoutExpenseThisMonthList from "../../../sections/nomenclature/providers/providers-without-expense-this-month/MobileProvidersWithoutExpenseThisMonthList"
import DesktopProvidersWithoutExpenseThisMonthList from "../../../sections/nomenclature/providers/providers-without-expense-this-month/DesktopProvidersWithoutExpenseThisMonthList"

const ProvidersWithoutExpenseThisMonthGuard = () => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("providers_without_expense_this_month")}
                isOpen={isMobile && modalOpen === AppModals.ProvidersWithoutExpenseThisMonth}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                <MobileProvidersWithoutExpenseThisMonthList />
            </DetailsSheet>

            <CustomDialog
                title={translate("providers_without_expense_this_month")}
                open={!isMobile && modalOpen === AppModals.ProvidersWithoutExpenseThisMonth}
                onClose={onClose}
                maxWidth={"lg"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <DesktopProvidersWithoutExpenseThisMonthList />
            </CustomDialog>
        </>
    )
}

export default ProvidersWithoutExpenseThisMonthGuard
