import { useTheme } from "@mui/material/styles"
import React, { useCallback } from "react"
import { updateTaskMutation } from "../../../mutations/kanban"
import * as jsonpatch from "fast-json-patch"
import { LoadingButton } from "@mui/lab"
import { CustomPopover, Iconify, useLocales, usePopover } from "rentzz"
import { MenuItem } from "@mui/material"
import { usePropertyRentingPeriods, useSingleRentingPeriodQuery } from "../../../queries/tenants"
import { DateTime } from "luxon"
import { orderBy } from "lodash"

interface Props {
    sectionId?: string
    taskId: string
    propertyId: number | undefined
    rentingPeriodId?: number
    canEdit?: boolean
}
export default function RentingPeriodInput({ sectionId, taskId, rentingPeriodId, propertyId, canEdit }: Props) {
    const { translate } = useLocales()
    const theme = useTheme()
    const popover = usePopover()
    const { data: rentingPeriodsFromAProperty } = usePropertyRentingPeriods(propertyId)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(rentingPeriodId)
    const { mutateAsync: editTaskProperty, isPending: isEditTaskPropertyLoading } = updateTaskMutation()

    const closePopover = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            event.preventDefault()
            popover.onClose()
        },
        [popover],
    )

    const handleChangeRentingPeriodId = useCallback(
        async (newRentingPeriodId: number) => {
            if (sectionId == null) return
            const operations = jsonpatch.compare({ rentingPeriodId }, { rentingPeriodId: newRentingPeriodId === -1 ? undefined : newRentingPeriodId })
            if (rentingPeriodId === newRentingPeriodId) return
            await editTaskProperty({
                sectionId,
                taskId,
                operations,
                newIndex: -1,
            })
            popover.onClose()
        },
        [sectionId, taskId, editTaskProperty, popover, rentingPeriodId],
    )

    const renderRentingPeriodData = useCallback(() => {
        if (rentingPeriodId == null) return translate("no_renting_period")
        return `${currentRentingPeriod?.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${currentRentingPeriod?.moveOutDate.toLocaleString(
            DateTime.DATE_SHORT,
        )}`
    }, [currentRentingPeriod, rentingPeriodId])

    return (
        <>
            <LoadingButton
                size='small'
                variant='text'
                endIcon={<Iconify icon='mdi:chevron-down' width={16} sx={{ ml: -0.5 }} />}
                sx={{ backgroundColor: theme.palette.grey.A200, width: "100%" }}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => popover.onOpen(event)}
                loading={isEditTaskPropertyLoading}
                disabled={isEditTaskPropertyLoading || !canEdit}
            >
                {renderRentingPeriodData()}
            </LoadingButton>
            <CustomPopover open={popover.open} anchorEl={popover.anchorEl} onClose={closePopover} sx={{ width: 200 }}>
                <MenuItem
                    selected={rentingPeriodId == null}
                    value={-1}
                    style={{ textTransform: "none" }}
                    onClick={async () => {
                        await handleChangeRentingPeriodId(-1)
                    }}
                >
                    {translate("no_renting_period")}
                </MenuItem>
                {orderBy(rentingPeriodsFromAProperty, ["moveOutDate"], ["desc"])?.map((period) => (
                    <MenuItem
                        selected={rentingPeriodId === period.id}
                        key={period.id}
                        value={period.id}
                        style={{ textTransform: "none" }}
                        onClick={async () => {
                            await handleChangeRentingPeriodId(period.id)
                        }}
                    >
                        {`${period.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - ${period.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}`}
                    </MenuItem>
                ))}
            </CustomPopover>
        </>
    )
}
