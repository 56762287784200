import { ExpenseAssignee, Iconify, LightTooltip, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import CustomLinearProgress from "../../components/CustomLinearProgress"
import { sumBy } from "lodash"
import { ExpenseAssignedIncome, PropertyExpense } from "../../queries/expenses"
import { DateTime } from "luxon"
import { ExpensePaymentStatus, getExpensePaymentStatus } from "./utils"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"
import { useTheme } from "@mui/material/styles"
import { HighlightedRow } from "rentzz/lib/components/data-grid/CustomDataGrid"
import { IncomeStatus } from "../../redux/slices/AddProperty"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"

interface Props {
    currentExpense: PropertyExpense | null
    getAmount: (value: number, currencyId?: number) => string
    mergedExpenses: HighlightedRow[]
}
export default function ExpensePaymentStatusComponent({ currentExpense, getAmount, mergedExpenses }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const percent = useFeatureValue(UserFlags.PercentExpensePayment, 0)

    const paidAmount = useMemo(
        () =>
            sumBy(
                currentExpense?.propertyIncomes
                    .filter((pi) =>
                        [IncomeStatus.Accepted, IncomeStatus.CardPaymentSucceeded, IncomeStatus.AcceptedFromExternalSystem].includes(pi.status),
                    )
                    .map((income: ExpenseAssignedIncome) => income.valueInExpenseCurrency),
            ),
        [currentExpense],
    )

    const hasAllIncomesDenied = useMemo(() => {
        return (
            currentExpense?.propertyIncomes.every(
                (income: ExpenseAssignedIncome) => income.status === IncomeStatus.Denied || income.status === IncomeStatus.CardPaymentFailed,
            ) ?? false
        )
    }, [currentExpense])

    const incomesValues = useMemo(
        () =>
            currentExpense?.propertyIncomes.map((income: ExpenseAssignedIncome) => {
                return `${getAmount(income.value, income.currencyId)}`
            }),
        [currentExpense, getAmount],
    )

    const incomesDates = useMemo(
        () =>
            currentExpense?.propertyIncomes.map((income: ExpenseAssignedIncome) => {
                return `${(income.date as DateTime).toLocaleString(DateTime.DATE_SHORT)}`
            }),
        [currentExpense],
    )

    const expensePaymentStatus = getExpensePaymentStatus(currentExpense?.value ?? null, paidAmount, percent)

    const tooltipTitle = useCallback(
        (text: string, optValue?: string) => {
            if (currentExpense == null) return
            return (
                <Box display={"flex"} flexDirection={"column"} gap={1} minWidth={200}>
                    <Typography variant={"caption"}>{translate(text, { value: optValue })}</Typography>
                    <CustomLinearProgress
                        value={(paidAmount / currentExpense.value) * 100}
                        minValue={paidAmount}
                        maxValue={getAmount(currentExpense.value, currentExpense.currencyId)}
                        isPaid={paidAmount === currentExpense.value}
                    />
                    <Typography variant={"caption"}>{translate("payments")}</Typography>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} flexDirection={"column"}>
                            {incomesDates?.map((income: string, index: number) => (
                                <Typography key={index} variant={"caption"}>
                                    {income}
                                </Typography>
                            ))}
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            {incomesValues?.map((income: string, index: number) => (
                                <Typography key={index} variant={"caption"}>
                                    {income}
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </Box>
            )
        },
        [paidAmount, currentExpense, getAmount, incomesDates, incomesValues],
    )

    const colorType = useMemo(() => mergedExpenses.find((ex) => ex.id === currentExpense?.id)?.type, [mergedExpenses, currentExpense])

    if (
        (currentExpense?.propertyIncomes.length ?? 0) > 0 &&
        currentExpense?.propertyIncomes.every((i) => i.status === IncomeStatus.Waiting || i.status === IncomeStatus.CardPaymentWaiting)
    ) {
        return (
            <LightTooltip title={tooltipTitle("expense_pending_payments")} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify icon={"mdi:progress-check"} color={theme.palette.warning.main} width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
                </IconButton>
            </LightTooltip>
        )
    }

    if (currentExpense && currentExpense.propertyIncomesCount > 0 && hasAllIncomesDenied && expensePaymentStatus === ExpensePaymentStatus.NotPaid) {
        return (
            <LightTooltip title={tooltipTitle("income_was_refused")} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify
                        icon={"f7:exclamationmark-triangle-fill"}
                        color={theme.palette.error.main}
                        width={DESKTOP_ICON_SIZE}
                        height={DESKTOP_ICON_SIZE}
                    />
                </IconButton>
            </LightTooltip>
        )
    }

    if (currentExpense?.assignee === ExpenseAssignee.ME) return ""

    if (currentExpense && currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.Overpaid)
        return (
            <LightTooltip title={tooltipTitle("expense_overpaid", getAmount(paidAmount - currentExpense.value, currentExpense.currencyId))} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify icon={"mdi:progress-check"} color={theme.palette.error.main} width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
                </IconButton>
            </LightTooltip>
        )
    if (currentExpense && currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.NotPaid) {
        return (
            <LightTooltip title={tooltipTitle("expense_was_partially_paid")} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify icon={"mdi:progress-check"} color={theme.palette.warning.main} width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
                </IconButton>
            </LightTooltip>
        )
    }

    if (currentExpense && currentExpense.propertyIncomesCount > 0 && expensePaymentStatus === ExpensePaymentStatus.Paid)
        return (
            <LightTooltip title={tooltipTitle("expense_was_paid")} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify icon={"mdi:check-circle"} color={theme.palette.success.main} width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
                </IconButton>
            </LightTooltip>
        )

    if (currentExpense && currentExpense.propertyIncomesCount === 0 && currentExpense.value > 0)
        return (
            <LightTooltip title={translate("has_no_incomes")} arrow>
                <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                    <Iconify
                        icon={"gravity-ui:circle-exclamation-fill"}
                        color={colorType === "error" ? theme.palette.error.main : theme.palette.warning.main}
                        width={DESKTOP_ICON_SIZE}
                        height={DESKTOP_ICON_SIZE}
                    />
                </IconButton>
            </LightTooltip>
        )

    return (
        <LightTooltip title={translate("paid_expense")} arrow sx={{ paddingTop: 1 }}>
            <IconButton sx={{ cursor: "inherit", ":hover": { backgroundColor: "transparent" } }}>
                <Iconify icon={"mdi:check-circle"} color={theme.palette.success.main} width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
            </IconButton>
        </LightTooltip>
    )
}
