import { UnpaidExpenseStatus, useUserDataQueryFn } from "../../queries/userData"
import { sum } from "lodash"
import { LightTooltip } from "rentzz"
import { useIntl } from "react-intl"
import { Box } from "@mui/material"
import React, { useCallback } from "react"
import UnpaidExpensesGroupedItems from "./UnpaidExpensesGroupedItems"

export interface UnpaidExpensesColumnProps {
    unpaidExpensesStatus: UnpaidExpenseStatus[]
}
const UnpaidExpensesColumn = ({ unpaidExpensesStatus }: UnpaidExpensesColumnProps) => {
    const intl = useIntl()
    const { data: userData } = useUserDataQueryFn()

    const formatNumber = useCallback(
        (amount: number) => {
            return intl.formatNumber(amount, {
                style: "currency",
                currency: userData?.currency.code,
            })
        },
        [intl, userData],
    )

    const renderTooltipTitle = useCallback(() => {
        return <UnpaidExpensesGroupedItems unpaidExpensesStatus={unpaidExpensesStatus} />
    }, [unpaidExpensesStatus])

    return (
        <LightTooltip
            title={renderTooltipTitle()}
            arrow
            slotProps={{
                tooltip: {
                    sx: {
                        minWidth: 500,
                    },
                },
            }}
        >
            <Box>{formatNumber(sum(unpaidExpensesStatus?.map((e) => e.amount) ?? []))}</Box>
        </LightTooltip>
    )
}

export default UnpaidExpensesColumn
