import { AppContext, CustomDataGrid, Iconify, useLocales } from "rentzz"
import React, { useMemo, useState } from "react"
import { RecurringExpense, useRecurringExpensesQuery } from "../../../queries/expenses"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { getRecurringTypeLabelColor, getRecurringTypeLabelText } from "../utils"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import NoRecurringExpensesPage from "./NoRecurringExpensesPage"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { useTheme } from "@mui/material/styles"
import CustomExpenseToolbar from "../Toolbar"
import RecurringType from "./RecurringType"

export interface RecurringExpenseTableProps {
    getAmount: (value: number, expenseId?: number) => string
}

const RecurringExpensesListDesktop = ({ getAmount }: RecurringExpenseTableProps) => {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { context, currentPropertyId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "startDate", sort: "desc" }])
    const { data: recurringExpenses, isFetching } = useRecurringExpensesQuery(paginationModel.page, paginationModel.pageSize, sortModel)
    const { data: userProperties } = useUserPropertiesQuery()

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            disableReorder: true,
            minWidth: 100,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             key='edit'
                //             color='primary'
                //             icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                //             onClick={(event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 dispatch(setEditingItem({ id: params.row.id }))
                //                 dispatch(setModalOpen(AppModals.EditRecurringExpense))
                //             }}
                //             label={translate("grid_actions.edit")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate("edit"),
                //     key: "edit",
                //     hidden: !params.row.canWrite,
                // }),
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             color={"info"}
                //             icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                //             onClick={async (event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 dispatch(setEditingItem({ id: params.id, type: PropertyFileType.RecurringExpense, name: params.row.name }))
                //                 dispatch(setModalOpen(AppModals.EntityFileManager))
                //             }}
                //             label={translate("see_files")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate("see_files"),
                //     key: "download",
                // }),
                // ActionWithTooltip({
                //     element: (
                //         <GridActionsCellItem
                //             color={params.row.isActive ? "error" : "success"}
                //             icon={
                //                 params.row.isActive ? (
                //                     <StopCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                //                 ) : (
                //                     <PlayCircleIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                //                 )
                //             }
                //             onClick={async (event: React.MouseEvent<HTMLElement>) => {
                //                 event.stopPropagation()
                //                 console.log("stop")
                //             }}
                //             label={translate(params.row.isActive ? "recurring_expenses.stop_recurrence" : "recurring_expenses.activate_recurrence")}
                //             sx={{ p: 1 }}
                //         />
                //     ),
                //     tooltipText: translate(params.row.isActive ? "recurring_expenses.stop_recurrence" : "recurring_expenses.activate_recurrence"),
                //     key: "stop_recurrence",
                // }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            key='delete'
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteRecurringExpense))
                                dispatch(setEditingItem({ id: params.row.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
                ActionWithTooltip({
                    element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                    tooltipText: translate("only_read_no_write"),
                    key: "unavailable",
                    hidden: params.row.canDelete,
                }),
            ],
        }),
        [translate],
    )

    const expenseColumns = useMemo(() => {
        const columns: GridColDef<RecurringExpense>[] = [
            {
                field: "recurringType",
                type: "actions",
                minWidth: 160,
                flex: 0.5,
                headerClassName: "hideRightSeparator",
                headerName: "",
                renderCell: (v) => <RecurringType v={v} />,
            },
            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                sortable: false,
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("name"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.5,
                minWidth: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("expenses.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "startDate",
                type: "date",
                minWidth: 170,
                headerAlign: "center",
                align: "center",
                headerName: translate("recurring_expenses.startDate"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "expiresAt",
                type: "date",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("expirationDate"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
        ]
        if ((currentPropertyId && context === AppContext.Owner) || currentRentingPeriodId) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        return columns
    }, [recurringExpenses, userProperties, translate, getAmount, getRecurringTypeLabelText, getRecurringTypeLabelColor])

    return (
        <>
            <CustomExpenseToolbar onAddClick={() => dispatch(setModalOpen(AppModals.AddRecurringExpense))} canExport={false} />
            <CustomDataGrid
                gridId={"RecurringExpensesListDesktop"}
                paginationMode={"server"}
                isLoading={isFetching}
                rowsLoadingMode={"client"}
                columns={expenseColumns}
                rows={recurringExpenses?.items ?? []}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={recurringExpenses?.count}
                actionsColumn={[actionsColumn]}
                components={{
                    noRowsOverlay: () => <NoRecurringExpensesPage />,
                }}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}

export default RecurringExpensesListDesktop
