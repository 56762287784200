import { array, number, object } from "yup"
import { AppContext } from "rentzz"

export const AddMeterValueSchema = (context: AppContext | undefined, isPhotoRequired: boolean, isEditMode: boolean, requireInteger: boolean) =>
    object().shape({
        value: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required")
            .positive("invalid_value")
            .test("Min value", "negative_only_owner", (obj, ctx) => {
                return (obj >= ctx.parent.previousValue && context === AppContext.Tenant) || context === AppContext.Owner
            })
            .test("is_integer", "value_must_be_an_integer", (value) => {
                return !requireInteger || Number.isInteger(value)
            }),
        files: array()
            .required("required")
            .min(isPhotoRequired && !isEditMode && context === AppContext.Tenant ? 1 : 0, "reading_with_photo"),

        previousValue: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        difference: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        meterId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
    })
