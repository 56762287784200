import { useDispatch } from "../../redux/store"
import { CustomDataGrid, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useIntl } from "react-intl"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../queries/currency"
import { useQueryClient } from "@tanstack/react-query"
import React, { useCallback, useEffect, useMemo } from "react"
import { ComplexQueryIDs } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { PropertyFileType, PropertyIncome } from "../../redux/slices/AddProperty"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import ActionWithTooltip from "../../utils/ActionWithTooltip"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { Box, Card, CardContent, CircularProgress } from "@mui/material"
import { useIncomeLinkedExpensesQuery } from "../../queries/income"
import { DateTime } from "luxon"
import Header from "../expenses/expense-details/Header"
import NoExpenses from "./NoExpenses"

export interface IncomeDetailsPanelProps {
    currentIncome: GridRowParams<PropertyIncome>
}

const IncomeDetailsPanel = ({ currentIncome }: IncomeDetailsPanelProps) => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const queryClient = useQueryClient()
    const { data: incomeExpenses, isFetching: isLoading } = useIncomeLinkedExpensesQuery(
        currentIncome.row.associatedExpenses?.map((e) => e.id),
        currentIncome.row.id,
    )

    useEffect(() => {
        currentIncome.row.associatedExpenses?.forEach((expense) =>
            queryClient.prefetchQuery({
                queryKey: [ComplexQueryIDs.Expense, { expenseId: expense.id }],
                queryFn: async () => {
                    return await Api.getExpense(expense.id!)
                },
            }),
        )
    }, [currentIncome])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<PropertyIncome>[] = [
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("expenses.name"),
            },
            {
                field: "value",
                type: "number",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "provider",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("provider"),
            },
            {
                field: "date",
                type: "date",
                minWidth: 100,
                flex: 0.7,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate, getAmount])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            disableReorder: true,
            flex: 0.5,
            minWidth: 180,
            type: "actions",
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Expense,
                                        name: translate(`income-table.incomeType-${params.row.type}`),
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
            ],
        }),
        [translate],
    )

    if (isLoading)
        return (
            <Box display='flex' flexDirection='column' width='100%' height={150} alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>
        )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <Header currentItem={currentIncome.row} itemType={"income"} />
                    {currentIncome.row.associatedExpenses && currentIncome.row.associatedExpenses.length > 0 ? (
                        <CustomDataGrid
                            columns={columns}
                            gridId={"IncomeDetailsPanel"}
                            rows={incomeExpenses ?? []}
                            idKey={"id"}
                            totalCount={incomeExpenses?.length}
                            actionsColumn={[actionsColumn]}
                            gridHeight={270}
                            paginationMode={"client"}
                            hidePagination={incomeExpenses && incomeExpenses.length < 4}
                        />
                    ) : (
                        <NoExpenses />
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default IncomeDetailsPanel
