import { useTheme } from "@mui/material/styles"
import { CustomTabs, Page, useLocales } from "rentzz"
import { useAtom } from "jotai"
import { tenantSettingsPageAtom, TenantSettingsPages } from "../../utils/atom"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"
import React, { useCallback } from "react"
import { Tab } from "@mui/material"
import AccountBoxIcon from "@mui/icons-material/AccountBox"
import { TabPanel } from "../../pages/PropertyDetails"
import VpnKeyIcon from "@mui/icons-material/VpnKey"
import GeneralPage from "./general/GeneralPage"
import Security from "./Security"
import { a11yProps } from "../../utils/A11yProps"

export default function TenantSettingsPage() {
    const theme = useTheme()
    const { translate } = useLocales()
    const [settingsPage, setSettingsPage] = useAtom(tenantSettingsPageAtom)
    const deleteUser = useFeatureIsOn(UserFlags.DeleteUser.toString())

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: TenantSettingsPages) => {
        switch (newValue) {
            case TenantSettingsPages.General:
                setSettingsPage(TenantSettingsPages.General)
                break
            case TenantSettingsPages.Security:
                setSettingsPage(TenantSettingsPages.Security)
                break
        }
    }, [])

    return (
        <Page title={translate("settings")} paddingBottom={2} paddingTop={1} px={2} color={theme.palette.grey.A700}>
            <CustomTabs value={settingsPage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                <Tab iconPosition='end' label={translate("general")} icon={<AccountBoxIcon />} {...a11yProps(0)} />
                {deleteUser && <Tab iconPosition='end' label={translate("security")} icon={<VpnKeyIcon />} {...a11yProps(2)} />}
            </CustomTabs>
            <TabPanel value={settingsPage} index={TenantSettingsPages.General}>
                <GeneralPage />
            </TabPanel>
            {deleteUser && (
                <TabPanel value={settingsPage} index={TenantSettingsPages.Security}>
                    <Security />
                </TabPanel>
            )}
        </Page>
    )
}
