import { Box, Card, Grid, InputAdornment, InputBase, inputBaseClasses, Typography } from "@mui/material"
import { getUnitFromId, useUnitsQueryFn } from "../../../../queries/units"
import * as React from "react"
import { useTheme } from "@mui/material/styles"
import { useLocales } from "rentzz"

interface Props {
    meters:
        | {
              id?: number
              name: string
              unitId: number
              currentValue: number
              reportingPeriodStartDay: number
              reportingPeriodEndDay: number
          }[]
        | undefined
    isDisabled: boolean
    handleBlur?: (meterId: number, newValue: number) => void
}
export default function MetersListAddTenant({ meters, handleBlur, isDisabled }: Props) {
    const { data: units } = useUnitsQueryFn()
    const theme = useTheme()
    const { translate } = useLocales()
    return (
        <Box paddingY={2} width={"100%"}>
            {meters?.length === 0 && (
                <Card
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 100,
                        backgroundColor: theme.palette.action.hover,
                        position: "relative",
                        "& .slick-list": {
                            borderRadius: 2,
                        },
                    }}
                >
                    {translate("no_meters")}
                </Card>
            )}
            <Grid container spacing={2} sx={{ overflowY: "auto", maxHeight: 400 }}>
                {meters?.map((meter) => (
                    <Grid item key={meter.id} xs={12} sm={6} md={6}>
                        <Card
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                backgroundColor: theme.palette.background.paper,
                                padding: 2,
                            }}
                        >
                            <Box display={"flex"} flexDirection={"column"} gap={1} width={"100%"}>
                                <Typography
                                    variant='body2'
                                    letterSpacing={0.5}
                                    textTransform='uppercase'
                                    fontWeight={"bold"}
                                    color={theme.palette.primary.dark}
                                    textAlign={"center"}
                                >
                                    {meter.name}
                                </Typography>
                                <Box display='flex' flexDirection='column' gap={1}>
                                    <Box display='flex' flexDirection='column'>
                                        <Typography variant={"caption"} letterSpacing={0.5} fontWeight={"bold"} color={theme.palette.primary.dark}>
                                            {translate("reading_period")}
                                        </Typography>
                                        <Typography
                                            variant={"body2"}
                                            sx={{
                                                color: theme.palette.grey.A700,
                                            }}
                                        >
                                            {meter.reportingPeriodStartDay} - {meter.reportingPeriodEndDay}
                                        </Typography>
                                    </Box>
                                    <Box display='flex' flexDirection='column'>
                                        <Typography variant={"caption"} letterSpacing={0.5} fontWeight={"bold"} color={theme.palette.primary.dark}>
                                            {translate("last_index")}
                                        </Typography>

                                        <InputBase
                                            disabled={isDisabled}
                                            defaultValue={meter.currentValue}
                                            onBlur={(event) => (handleBlur ? handleBlur(meter.id!, Number(event.target.value)) : undefined)}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <Typography textAlign='center' variant={"body2"}>
                                                        {getUnitFromId(units, meter.unitId).code}
                                                    </Typography>
                                                </InputAdornment>
                                            }
                                            sx={{
                                                color: theme.palette.grey.A700,
                                                [`&.${inputBaseClasses.root}`]: {
                                                    borderRadius: 1,
                                                    borderWidth: 1,
                                                    borderStyle: "solid",
                                                    borderColor: "transparent",
                                                    transition: (theme) => theme.transitions.create(["padding-left", "border-color"]),
                                                    paddingRight: 1.5,
                                                    [`&.${inputBaseClasses.focused}`]: {
                                                        borderColor: "text.primary",
                                                        paddingLeft: 1.5,
                                                    },
                                                },

                                                [`& .${inputBaseClasses.input}`]: {
                                                    typography: "caption",
                                                    borderBottomWidth: isDisabled ? 0 : 1,
                                                    borderColor: "black",
                                                    borderStyle: "dashed",
                                                    ":hover": {
                                                        cursor: isDisabled ? "inherit" : "pointer",
                                                    },
                                                    ":focus": {
                                                        borderBottomWidth: 0,
                                                    },
                                                },
                                                ":hover": {
                                                    backgroundColor: isDisabled ? undefined : theme.palette.grey.A200,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
