import { FormProvider, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useState } from "react"
import * as jsonpatch from "fast-json-patch"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import {
    addNewInvoicingConfiguration,
    AddNewInvoicingConfigurationRequest,
    updateInvoicingConfiguration,
} from "../../../../mutations/invoicingConfiguration"
import { AddNewInvoicingConfigurationSchema } from "../../../../validations/invoicing-configuration"
import { InvoiceProvider, InvoicingConfiguration } from "../../../../queries/invoicing-configuration"

interface Props {
    onClose: () => void
    defaultValues?: InvoicingConfiguration
}

export default function AddOrEditInvoicingConfigurationForm({ onClose, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [invoiceProvider, setInvoiceProvider] = useState<InvoiceProvider | undefined>(undefined)
    const { mutateAsync: createNewInvoicingConfiguration, isPending: isCreatingNewInvoicingConfiguration } = addNewInvoicingConfiguration()
    const { mutateAsync: updateConfiguration, isPending: isUpdatingInvoicingConfiguration } = updateInvoicingConfiguration()

    const methods = useForm<AddNewInvoicingConfigurationRequest>({
        resolver: yupResolver(AddNewInvoicingConfigurationSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset, watch, setValue } = methods

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "invoiceProvider") {
                if (v.invoiceProvider !== InvoiceProvider.Smartbill) {
                    setValue("configurationCIF", "111")
                } else {
                    setValue("configurationCIF", "")
                }
                setInvoiceProvider(v.invoiceProvider)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (defaultValues) {
            reset({
                invoiceProvider: defaultValues?.invoiceProvider,
                user: defaultValues?.user,
                password: defaultValues?.password,
            })
        }
    }, [defaultValues, reset])

    const onSave = useCallback(
        async (data: AddNewInvoicingConfigurationRequest) => {
            if (defaultValues) {
                const operations = jsonpatch.compare(
                    { user: defaultValues?.user, password: defaultValues?.password },
                    { user: data.user, password: data.password },
                )

                await updateConfiguration({
                    invoicingConfigurationId: defaultValues.id,
                    operations,
                })
            } else {
                await createNewInvoicingConfiguration(data)
            }

            onClose()
        },
        [createNewInvoicingConfiguration, onClose, updateConfiguration, defaultValues],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                <RHFSelect name='invoiceProvider' label={translate("invoiceProvider")} size='small' required disabled={!!defaultValues}>
                    <MenuItem value={InvoiceProvider.Oblio}>{translate(`invoicing_software-${InvoiceProvider.Oblio}`)}</MenuItem>
                    <MenuItem value={InvoiceProvider.Smartbill}>{translate(`invoicing_software-${InvoiceProvider.Smartbill}`)}</MenuItem>
                </RHFSelect>
                <RHFTextField name='user' label={translate("user")} size='small' required />
                <RHFTextField name='password' label={translate("api_key")} size='small' required />
                {invoiceProvider === InvoiceProvider.Smartbill && (
                    <RHFTextField name='configurationCIF' label={translate("configurationCIF")} size='small' required />
                )}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button
                        fullWidth={isMobile}
                        color={"primary"}
                        onClick={onClose}
                        disabled={isCreatingNewInvoicingConfiguration || isUpdatingInvoicingConfiguration}
                    >
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isCreatingNewInvoicingConfiguration || isUpdatingInvoicingConfiguration}
                        loading={isCreatingNewInvoicingConfiguration || isUpdatingInvoicingConfiguration}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
