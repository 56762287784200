import { FormProvider, Iconify, LoadingIcon, Page, RHFSelect, useIsMobile, useLocales, useResponsive } from "rentzz"
import { Box, CircularProgress, MenuItem, Typography, useColorScheme } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { alpha, useTheme } from "@mui/material/styles"
import { Viewer } from "@react-pdf-viewer/core"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import { NotificationTemplateVariable, useNotificationsTemplatesQuery } from "../../../../../queries/userData"
import { ConfigureNotificationSchema } from "../../../../../validations/notificationsTemplates"
import NotificationVariables from "../../../../documents-templates/notifications/NotificationVariables"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { updateRentingPeriodPaymentNotification } from "../../../../../mutations/notifications"
import Api from "../../../../../api/Api"

export interface ConfigureNotificationRequest {
    notificationId: number
    variables: NotificationTemplateVariable[]
}
export default function PaymentNotifications() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const mdUp = useResponsive("up", "md")
    const dispatch = useDispatch()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const [url, setUrl] = useState<string>("")
    const { mutateAsync: updateNotificationId, isPending: isUpdateNotificationId } = updateRentingPeriodPaymentNotification()
    const defaultLayoutPluginInstance = defaultLayoutPlugin()
    const { mode } = useColorScheme()
    const { data: notificationsTemplates } = useNotificationsTemplatesQuery()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)

    const methods = useForm<ConfigureNotificationRequest>({
        resolver: yupResolver(ConfigureNotificationSchema),
        mode: "onBlur",
        defaultValues: {
            notificationId: notificationsTemplates?.items.find((n) => n.id === rentingPeriodDetails?.paymentNotificationTemplateId)?.id ?? -1,
            variables: rentingPeriodDetails?.paymentNotificationVariables ?? [],
        },
    })

    const { watch } = methods

    const handleNotificationPreview = useCallback(async () => {
        if (currentRentingPeriodId == null) return
        setIsPreviewLoading(true)
        const notificationPreview = await Api.fetchGeneralRentingPeriodNotificationPreview(currentRentingPeriodId, false)
        const url = window.URL.createObjectURL(new Blob([notificationPreview]))
        if (isMobile) {
            dispatch(setEditingItem({ pdfUrl: url }))
        }
        setUrl(url)
        setIsPreviewLoading(false)
    }, [currentRentingPeriodId, setIsPreviewLoading, setUrl, isMobile])

    useEffect(() => {
        handleNotificationPreview()
    }, [])

    useEffect(() => {
        const subscription = watch(async (v, { name }) => {
            if (name === "notificationId") {
                if (v.notificationId === -1) {
                    await updateNotificationId({ notificationId: null })
                }
                const notification = notificationsTemplates?.items.find((n) => n.id === Number(v.notificationId))
                if (notification) {
                    await updateNotificationId({ notificationId: notification.id })
                }
                await handleNotificationPreview()
            }
        })

        return () => subscription.unsubscribe()
    }, [watch, notificationsTemplates, updateNotificationId])

    if (isUpdateNotificationId) return <LoadingIcon />

    if (rentingPeriodDetails?.paymentNotificationTemplateId == null)
        return (
            <FormProvider methods={methods}>
                <Box display={"flex"} width={"100%"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} paddingTop={20}>
                    <Iconify icon={"mdi:note-off-outline"} width={40} height={40} color={theme.palette.primary.main} />
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 3,
                            minWidth: 200,
                        }}
                    >
                        <Typography sx={{ color: "text.secondary" }} fontWeight={"bold"} fontSize={17}>
                            {translate("choose_a_notification")}
                        </Typography>
                        <RHFSelect name={"notificationId"} label={translate("notification.name")}>
                            <MenuItem value={-1} style={{ textTransform: "none" }}>
                                {translate("no_notification")}
                            </MenuItem>
                            {notificationsTemplates ? (
                                notificationsTemplates.items.map((notification) => (
                                    <MenuItem key={notification.id} value={notification.id} style={{ textTransform: "none" }}>
                                        {notification.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                    </Box>
                </Box>
            </FormProvider>
        )

    return (
        <Page title={translate("payment-notifications-title")} sx={{ color: theme.palette.grey.A700 }}>
            <FormProvider methods={methods}>
                <Box paddingRight={isMobile ? 2 : undefined} display={"flex"} justifyContent={"flex-end"}>
                    {isMobile && (
                        <LoadingButton
                            onClick={async () => {
                                await handleNotificationPreview()
                                dispatch(setModalOpen(AppModals.NotificationPreview))
                            }}
                            variant='contained'
                            color='primary'
                            loading={isPreviewLoading}
                            disabled={isPreviewLoading || false}
                        >
                            {translate("load_preview")}
                        </LoadingButton>
                    )}
                </Box>

                <Box
                    display='flex'
                    width={"100%"}
                    height={"100%"}
                    flexDirection={mdUp ? "row" : "column"}
                    paddingTop={2}
                    paddingRight={isMobile ? 2 : undefined}
                    paddingBottom={isMobile ? 8 : undefined}
                    gap={4}
                    sx={{ color: theme.palette.grey.A700 }}
                >
                    <Box
                        width={mdUp ? "50%" : "100%"}
                        height={"100%"}
                        sx={{
                            display: "flex",
                            height: "100%",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <RHFSelect name={"notificationId"} label={translate("notification.name")} fullWidth>
                            <MenuItem value={-1} style={{ textTransform: "none" }}>
                                {translate("no_notification")}
                            </MenuItem>
                            {notificationsTemplates ? (
                                notificationsTemplates.items.map((notification) => (
                                    <MenuItem key={notification.id} value={notification.id} style={{ textTransform: "none" }}>
                                        {notification.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <CircularProgress />
                            )}
                        </RHFSelect>
                        {isUpdateNotificationId ? (
                            <Box width='100%' display='flex' alignItems='center' justifyContent='center' height={300}>
                                <CircularProgress size={"2rem"} />
                            </Box>
                        ) : (
                            <NotificationVariables handleNotificationPreview={handleNotificationPreview} />
                        )}
                    </Box>

                    {!isMobile && rentingPeriodDetails?.paymentNotificationTemplateId && (
                        <Box width={mdUp ? "50%" : "100%"} height={window.innerHeight - 160} position='relative'>
                            {isPreviewLoading && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 1000,
                                        top: 0,
                                        left: 0,
                                        backgroundColor: alpha(theme.palette.grey.A700, 0.4),
                                        width: "100%",
                                        height: "100%",
                                        opacity: 0.5,
                                    }}
                                />
                            )}

                            <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                                {url && <Viewer theme={mode} fileUrl={url} plugins={[defaultLayoutPluginInstance]} />}

                                {isPreviewLoading && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            zIndex: 1000,
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                        }}
                                    >
                                        <Box width='100%' display='flex' alignItems='center' justifyContent='center' height={300}>
                                            <CircularProgress size={"2rem"} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            </FormProvider>
        </Page>
    )
}
