import { useLocales } from "rentzz"
import { Box, Divider, Paper, Typography } from "@mui/material"
import * as React from "react"
import ConfigUnit from "./ConfigUnit"
import ConfigPropertyCrm from "./ConfigPropertyCrm"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../queries/userData"

export default function PropertyDetails() {
    const { translate } = useLocales()
    const isImoCRMAgencySync = useFeatureIsOn(UserFlags.SyncImoCRMAgency.toString())

    return (
        <Paper elevation={3} square={false} sx={{ color: "text.secondary" }}>
            <Box display={"flex"} justifyContent={"space-between"} paddingY={1.7} paddingX={2} alignItems={"center"}>
                <Typography fontWeight={"bold"}>{translate("property_data")}</Typography>
            </Box>
            <Divider sx={{ marginX: 1 }} />
            <Box display={"flex"} flexDirection={"column"} gap={1} padding={2}>
                <Box display='flex' flexDirection='row' height={150}>
                    <Box display={"flex"} flexDirection={"column"} gap={1} alignItems={"start"} flex={1}>
                        {isImoCRMAgencySync && <ConfigPropertyCrm />}
                        {isImoCRMAgencySync && <ConfigUnit />}
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}
