import React, { useMemo } from "react"
import { Box, Paper, Typography } from "@mui/material"
import {
    AccountPopover,
    DashboardContext,
    Iconify,
    LanguagePopover,
    LayoutOption,
    LoadingScreen,
    Page,
    useIsMobile,
    useLocales,
    UserContextProvider,
} from "rentzz"
import { styled, useTheme } from "@mui/material/styles"
import TenantContext from "./TenantContext"
import OwnerContext from "./OwnerContext"
import { RoleName, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { AppModals, setContext, setCurrentPropertyId, setCurrentRentingPeriodId, setModalOpen } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"
import { useLogout } from "../../mutations/user"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useChangeLanguage } from "../../utils/useChangeLanguage"

export default function ChooseContext() {
    const { handleLangOption } = useChangeLanguage()
    const isMobile = useIsMobile()
    const { data: user, isFetching: isUserLoading } = useUserDataQueryFn()
    const { mutate: signOut } = useLogout()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const showManagerContext = useFeatureIsOn(UserFlags.ShowManagerContext.toString())

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 5),
                gap: 20,
            })),
        [isMobile],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
                href: "/",
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                color: theme.palette.error.main,
                href: "/",
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
            },
        ]
        if (policiesPageUrl.length === 0) {
            toReturn.splice(1, 1)
        }

        return toReturn
    }, [translate, signOut, dispatch, isWhitelabel])

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (user == null) return null

        return {
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user?.firstName} ${user?.lastName}`,
            email: user.email ?? "",
            currency: user.currency,
            availableCurrencies: [],
            onLanguageChange: handleLangOption,
            options,
            roleName: isWhitelabel ? undefined : user?.roles.roleName,
            validUntil: isWhitelabel || user?.roles.roleName === RoleName.FREE ? undefined : user?.roles.to,
        }
    }, [user, isWhitelabel, options, dispatch])

    if (isUserLoading) return <LoadingScreen />

    return (
        <Page title={translate("add_property")} height={isMobile ? "100%" : undefined}>
            {isMobile ? (
                <Box
                    display='flex'
                    gap={1}
                    alignItems='center'
                    flexDirection={"column"}
                    width={"100%"}
                    height={"100%"}
                    padding={3}
                    style={{ backgroundColor: theme.palette.action.hover }}
                >
                    <UserContextProvider userData={userDataConfig}>
                        <>
                            <LanguagePopover />
                            <AccountPopover />
                        </>
                    </UserContextProvider>
                    <Typography color={theme.palette.primary.dark} variant='h5'>
                        {`${translate("choose_context")}:`}
                    </Typography>
                    <TenantContext />
                    <OwnerContext name={"owner"} icon={"mdi:home-city"} />
                    {showManagerContext && <OwnerContext name={"manager"} icon={"grommet-icons:user-manager"} />}
                </Box>
            ) : (
                <ContentStyle>
                    <PaperStyle elevation={5}>
                        <Box display='flex' justifyContent='flex-end' gap={1} alignItems='center'>
                            <UserContextProvider userData={userDataConfig}>
                                <>
                                    <LanguagePopover />
                                    <AccountPopover />
                                </>
                            </UserContextProvider>
                        </Box>
                        <Typography color={theme.palette.primary.dark} variant='h5'>
                            {translate("choose_context")}
                        </Typography>
                        <TenantContext />
                        <OwnerContext name={"owner"} icon={"mdi:home-city"} />
                        {showManagerContext && <OwnerContext name={"manager"} icon={"grommet-icons:user-manager"} />}
                    </PaperStyle>
                </ContentStyle>
            )}
        </Page>
    )
}
