import { StyledLabel } from "../utils"
import { Avatar, IconButton, Stack } from "@mui/material"
import AvatarGroup from "@mui/material/AvatarGroup"
import { AppContext, Iconify, IKanbanTask, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { alpha } from "@mui/material/styles"
import KanbanContactsDialog from "./kanban-contacts-dialog"
import React, { useCallback } from "react"
import { useSelector } from "../../../redux/store"
import Api from "../../../api/Api"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { useAtom } from "jotai/index"
import { showTaskAssignDialogAtom } from "../../../utils/atom"
import { useUserDataQueryFn } from "../../../queries/userData"

interface TaskAssigneeProps {
    task: IKanbanTask
}

const TaskAssignee = ({ task }: TaskAssigneeProps) => {
    const { translate } = useLocales()
    const { context } = useSelector((state) => state.appState)
    const [showAssignDialog, setShowAssignDialog] = useAtom(showTaskAssignDialogAtom)
    const isMobile = useIsMobile()
    const { data: userData } = useUserDataQueryFn()

    const handleUserAvatar = useCallback((userId: string) => {
        return `${Api.API}/user/${userId}/profile-picture`
    }, [])

    if (context !== AppContext.Owner || task == null) return null

    return (
        <Stack direction='row' alignItems='center'>
            {!isMobile && <StyledLabel sx={{ height: 40, lineHeight: "40px" }}>{translate("task_assignee")}</StyledLabel>}
            {isMobile && translate("task_assignee")}

            <Stack direction='row' flexWrap='wrap' alignItems='center' spacing={1}>
                <AvatarGroup>
                    {task.assignedUsers.map((user) => (
                        <LightTooltip key={user.userId} title={user.fullName} arrow>
                            <Avatar
                                alt={user.fullName}
                                sx={{ width: isMobile ? 20 : 40, height: isMobile ? 20 : 40 }}
                                src={handleUserAvatar(user.userId)}
                            />
                        </LightTooltip>
                    ))}
                </AvatarGroup>
                {!isMobile && userData?.isTicketsOnly === false && (
                    <LightTooltip title={translate("add_assignee")}>
                        <IconButton
                            onClick={() => {
                                setShowAssignDialog(true)
                            }}
                            sx={{
                                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
                                border: (theme) => `dashed 1px ${theme.palette.divider}`,
                            }}
                        >
                            <Iconify icon='mdi:edit' width={DESKTOP_ICON_SIZE} height={DESKTOP_ICON_SIZE} />
                        </IconButton>
                    </LightTooltip>
                )}
            </Stack>

            <KanbanContactsDialog
                taskId={task.id}
                sectionId={task.sectionId!}
                open={showAssignDialog}
                onClose={() => setShowAssignDialog(false)}
                assignee={task.assignedUsers}
            />
        </Stack>
    )
}

export default TaskAssignee
