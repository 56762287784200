import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useExpenseLateFeesDetails } from "../../../queries/expenses"
import { useDispatch, useSelector } from "../../../redux/store"
import GenerateExpenseLateFeesForm from "../../../sections/expenses/late-fees/GenerateExpenseLateFeesForm"

export default function GenerateExpenseLateFeesGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: lateFeesDetails, isLoading: isLateFeesLoading } = useExpenseLateFeesDetails(editingItem?.expenseId)

    const closeModal = useCallback(() => {
        dispatch(setErrorMessage(undefined))
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate("add_late_fees_expense_title")}
                open={!isMobile && modalOpen === AppModals.GenerateLateFeesExpense}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
                isLoading={isLateFeesLoading}
            >
                <GenerateExpenseLateFeesForm expenseId={editingItem?.expenseId} lateFeesDetails={lateFeesDetails} onClose={closeModal} />
            </CustomDialog>

            <DetailsSheet
                isLoading={isLateFeesLoading}
                title={translate("add_late_fees_expense_title")}
                isOpen={isMobile && modalOpen === AppModals.GenerateLateFeesExpense}
                onClose={closeModal}
            >
                <GenerateExpenseLateFeesForm expenseId={editingItem?.expenseId} lateFeesDetails={lateFeesDetails} onClose={closeModal} />
            </DetailsSheet>
        </>
    )
}
