import { TenantRequiredInfo } from "../sections/contracts/newContract/TenantRequiredData"
import { number, object, string } from "yup"
import { ContactInfoPersonType } from "../queries/userData"

export const TenantInfoSchema = (tenantRequiredInfo: TenantRequiredInfo) => {
    return object().shape({
        fullName: string()
            .test("requiredTest", "required", (val) => {
                return !tenantRequiredInfo.isFullNameRequired || (val != null && val.trim() !== "")
            })
            .required(),
        county: string()
            .test("requiredTest", "required", (val) => {
                return !tenantRequiredInfo.isCountyRequired || (val != null && val.trim() !== "")
            })
            .required(),
        city: string()
            .test("requiredTest", "required", (val) => {
                return !tenantRequiredInfo.isCityRequired || (val != null && val.trim() !== "")
            })
            .required(),
        street: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isStreetRequired || (val != null && val.trim() !== "")
        }),
        streetNumber: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isStreetNumberRequired || (val != null && val.trim() !== "")
        }),
        buildingNumber: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isBuildingNumberRequired || (val != null && val.trim() !== "")
        }),
        stair: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isStairRequired || (val != null && val.trim() !== "")
        }),
        floor: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isFloorRequired || val != null
        }),
        apartment: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isApartmentRequired || (val != null && val.trim() !== "")
        }),
        contactInfoPersonType: number().required("required"),
        identitySeries: string()
            .matches(/^[A-Z]+$/, "must_be_uppercase")
            .min(2, "two_characters")
            .max(2, "two_characters")
            .test("test", "required", (val, context) => {
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Individual)
                    return !tenantRequiredInfo.isIdentitySeriesRequired || (val != null && val.trim() !== "")
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Company) return val == null || val.trim() === ""
            }),

        identityNumber: string()
            .optional()
            .min(6, "six_characters")
            .max(6, "six_characters")
            .test("test", "required", (val, context) => {
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Individual)
                    return !tenantRequiredInfo.isIdentityNumberRequired || (val != null && val.trim() !== "")
                if (context.parent.contactInfoPersonType === ContactInfoPersonType.Company) return val == null || val.trim() === ""
            }),
        phone: string().test("requiredTest", "required", (val) => {
            return !tenantRequiredInfo.isPhoneRequired || (val != null && val.trim() !== "")
        }),

        companyName: string().test("test", "required", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyRegistrationNumber: string().test("test", "required", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
        companyPosition: string().test("test", "required", (val, context) => {
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Company) return val != null && val.trim() !== ""
            if (Number(context.parent.contactInfoPersonType) === ContactInfoPersonType.Individual) return val == null || val.trim() === ""
        }),
    })
}
