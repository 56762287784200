import { allLangs, FormProvider, Image, RHFAutocomplete, RHFDateRangePicker, RHFMailInput, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button, MenuItem } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InviteTenantSchema } from "../../../../validations/addTenant"
import React, { useCallback, useEffect, useState } from "react"
import { DateTime } from "luxon"
import { usePropertyAvailability } from "../../../../queries/tenants"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import { setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useUserDataQueryFn } from "../../../../queries/userData"
import { uniq } from "lodash"
import { TenantDetails } from "../../../types/AddTenant"

interface InvitationProps {
    onClose: () => void
    onNext: (details: TenantDetails) => void
    defaultValues?: TenantDetails
}

export default function Invitation({ onClose, onNext, defaultValues }: InvitationProps) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const [propertyId, setPropertyId] = useState<number | undefined>(undefined)
    const [startDate, setStartDate] = useState<DateTime | undefined>()
    const [endDate, setEndDate] = useState<DateTime | undefined>()
    const { data: isPropertyAvailable } = usePropertyAvailability(startDate, endDate, propertyId)
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.RentingPeriods)
    const { data: userData } = useUserDataQueryFn()
    const [emails, setEmails] = useState<(string | undefined)[]>([])

    const methods = useForm<TenantDetails>({
        resolver: yupResolver(InviteTenantSchema(isPropertyAvailable ?? false)),
        mode: "onBlur",
        reValidateMode: "onChange",
    })

    useEffect(() => {
        trigger("propertyId")
    }, [isPropertyAvailable])

    useEffect(() => {
        setPropertyId(currentPropertyId ?? (editableProperties ?? [])[0].id)
        setStartDate(DateTime.now())
        setEndDate(DateTime.now().plus({ year: 1 }))
        reset(
            defaultValues ?? {
                propertyId: currentPropertyId ?? (editableProperties ?? [])[0].id,
                moveInDate: DateTime.now().toISO() ?? undefined,
                moveOutDate: DateTime.now().plus({ year: 1 }).toISO() ?? undefined,
                mails: [],
                invitationLanguage: userData?.languageCode,
            },
        )
    }, [defaultValues, currentPropertyId, editableProperties])

    const { handleSubmit, trigger, watch, reset } = methods

    useEffect(() => {
        const subscription = watch((value, _) => {
            // TODO: hack
            trigger("moveInDate")
            trigger("moveOutDate")
            trigger("propertyId")
            setEmails(uniq(value.mails ?? []))
            setPropertyId(value.propertyId)
            if (value.moveInDate != null) {
                setStartDate(DateTime.fromISO(value.moveInDate))
            }
            if (value.moveOutDate != null) {
                setEndDate(DateTime.fromISO(value.moveOutDate))
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = useCallback(
        async (data: TenantDetails) => {
            onNext({
                id: -1,
                mails: uniq(data.mails),
                moveInDate: data.moveInDate,
                moveOutDate: data.moveOutDate,
                propertyId: data.propertyId,
                invitationLanguage: data.invitationLanguage,
            })
            dispatch(setEditingItem({ propertyId: data.propertyId }))
        },
        [onNext, dispatch],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Alert
                sx={{
                    marginTop: 2,
                    alignItems: "center",
                    display: "flex",
                    textAlign: "center",
                    color: theme.palette.grey["700"],
                }}
                severity='info'
            >
                {translate("contract_info_message")}
                <Button
                    style={{ cursor: "pointer", textAlign: "center", marginBottom: 3, fontWeight: "bold", color: theme.palette.grey["700"] }}
                    component={"button"}
                    onClick={() => {
                        navigate("/document-templates/contract-templates")
                        dispatch(setEditingItem(undefined))
                        dispatch(setModalOpen(undefined))
                    }}
                >
                    {translate("contract_template")}.
                </Button>
            </Alert>
            <Box display='flex' width='100%' marginTop={1} gap={2} justifyContent='space-around'>
                <Box display='flex' flexDirection='column' width={"100%"} gap={2} marginY={2}>
                    <RHFAutocomplete
                        autocompleteProps={{
                            sx: {
                                width: "100%",
                            },
                            noOptionsText: translate("no_value_found"),
                        }}
                        matchId
                        name={"propertyId"}
                        options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                        disabled={currentPropertyId != null}
                        label={translate("property")}
                        required={currentPropertyId == null}
                        freeSolo={false}
                    />
                    <RHFMailInput
                        name='mails'
                        placeholder={translate("mail_next_enter") ?? ""}
                        label={translate("tenant-data.mail")}
                        required={emails.length === 0}
                    />
                    <RHFDateRangePicker fromName={"moveInDate"} toName={"moveOutDate"} />

                    <RHFSelect name={"invitationLanguage"} size='small' label={translate("invitation_language")} required>
                        {allLangs.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                <Box display='flex' flexDirection='row'>
                                    <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
                                    {option.label}
                                </Box>
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column-reverse" : "row",
                    justifyContent: "flex-end",
                    padding: 0,
                    paddingTop: 2,
                    gap: 2,
                }}
            >
                <Button color={"primary"} onClick={onClose}>
                    {translate("cancel")}
                </Button>
                <Button color={"primary"} type='submit' variant='contained'>
                    {translate("next")}
                </Button>
            </Box>
        </FormProvider>
    )
}
