import { CustomDataGrid, Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useCallback, useMemo, useState } from "react"
import {
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridActionsCellItem,
    GridColDef,
    GridFilterModel,
    GridRowParams,
    GridSortModel,
} from "@mui/x-data-grid-pro"
import { RoleName, useUserPropertiesQuery } from "../../../queries/userData"
import { Box, Divider, Typography } from "@mui/material"
import { DateTime } from "luxon"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { PropertyRevision, RevisionStatus } from "../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import NoDataPage from "../../../components/NoDataPage"
import { useRevisionsQueryFn } from "../../../queries/revision"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges"
import PersonIcon from "@mui/icons-material/Person"
import PersonOffIcon from "@mui/icons-material/PersonOff"
import InfoIcon from "@mui/icons-material/Info"
import CustomRoleBlockedToolbar from "../../../components/CustomRoleBlockedToolbar"
import CustomDetailPanelToggle from "../../expenses/CustomDetailPanelToggle"
import ObservationsDetailsPanel from "./ObservationsDetailsPanel"

export default function DesktopRevisionsList() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { currentPropertyId } = useSelector((state) => state.appState)
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "date", sort: "desc" }])
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const { data: revisions, isFetching } = useRevisionsQueryFn(paginationModel.page, paginationModel.pageSize, sortModel, filterModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const { canWrite } = usePermissions(PermissionType.Revisions)

    const getObservationsPanel = useCallback((row: GridRowParams<PropertyRevision & { canWrite: boolean; canDelete: boolean }>) => {
        return <ObservationsDetailsPanel currentRevision={row.row} />
    }, [])

    const columns = useMemo(() => {
        const columns: GridColDef<PropertyRevision>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"see_observations"} />
                },
            },
            {
                field: "propertyId",
                type: "string",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                headerName: translate("property"),
                valueFormatter: (value) => {
                    return userProperties?.find((p) => p.id === value)?.label
                },
            },
            {
                field: "name",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("title"),
            },
            {
                field: "description",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("description"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 15)}</Typography>,
                        tooltipText: v.value.length > 15 ? v.value : "",
                    }),
            },
            {
                field: "date",
                type: "string",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("revisionDate"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "createdBy",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("added_by"),
                sortable: false,
            },

            {
                field: "tenantChosenDate",
                type: "string",
                minWidth: 120,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("tenantResponseDate"),
                filterable: true,
                renderCell: (v) => {
                    if ((v.value as DateTime | undefined) == null) return "-"
                    return (
                        <Label color={"info"} style={{ textTransform: "none" }}>
                            {(v.value as DateTime).toLocaleString(DateTime.DATE_SHORT)}
                        </Label>
                    )
                },
            },
            {
                field: "status",
                minWidth: 140,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("payment.status"),
                renderCell: (v) => {
                    return (
                        <Label color={v.value === RevisionStatus.Done ? "primary" : "warning"} style={{ textTransform: "none" }}>
                            {translate(v.value === RevisionStatus.Done ? "completed" : "pending")}
                        </Label>
                    )
                },
            },
        ]

        if (currentPropertyId) {
            return columns.filter((c) => c.field !== "propertyId")
        }

        return columns
    }, [userProperties, translate, currentPropertyId])

    const infoColumn = useMemo(
        (): GridColDef => ({
            field: "____",
            type: "actions",
            headerClassName: "hideRightSeparator",
            width: 120,
            headerName: "",
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"primary"}
                            icon={
                                params.row.visibleToTenant ? (
                                    <PersonIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <PersonOffIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )
                            }
                            label={translate("visibleToTenant")}
                            sx={{ p: 1, cursor: "initial" }}
                        />
                    ),
                    tooltipText: (
                        <Typography variant='caption'>
                            {translate(params.row.visibleToTenant ? "revisionVisibleToTenant" : "notVisibleToTenant")}
                        </Typography>
                    ),
                    key: "visibleToTenant",
                }),
                ActionWithTooltip({
                    hidden: !params.row.visibleToTenant,
                    element: (
                        <GridActionsCellItem
                            color={params.row.allowTenantChoseDate ? "success" : "error"}
                            icon={
                                params.row.allowTenantChoseDate ? (
                                    <Iconify icon={"mdi:calendar-edit"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <Iconify icon={"mdi:calendar-remove"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )
                            }
                            label={translate("allowTenantChoseDate")}
                            sx={{ p: 1, cursor: "initial" }}
                        />
                    ),
                    tooltipText: (
                        <Typography variant='caption'>
                            {translate(params.row.allowTenantChoseDate ? "allowTenantChoseDateInfo" : "notAllowTenantChoseDateInfo")}
                        </Typography>
                    ),
                    key: "visibleToTenant",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<InfoIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("visibleToTenant")}
                            sx={{ p: 1, cursor: "initial" }}
                        />
                    ),
                    tooltipText: (
                        <Box display='flex' flexDirection='column' justifyContent='center'>
                            <Typography variant='caption'>{translate("notes.addedAt")}</Typography>
                            <Typography variant='caption'>{(params.row.createdAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                            <Divider />
                            <Typography variant='caption'>{translate("lastModifiedAt")}</Typography>
                            <Typography variant='caption'>{(params.row.lastModifiedAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                        </Box>
                    ),
                }),
            ],
        }),
        [translate],
    )

    const actionsColumn: GridColDef<PropertyRevision> = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 160,
            flex: 0.5,
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<PropertyRevision & { canWrite: boolean; canDelete: boolean }>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={params.row.status === RevisionStatus.Done ? "primary" : "warning"}
                            icon={
                                params.row.status === RevisionStatus.Done ? (
                                    <CheckCircleOutlineIcon sx={{ fontSize: MOBILE_ICON_SIZE }} />
                                ) : (
                                    <PublishedWithChangesIcon sx={{ fontSize: MOBILE_ICON_SIZE }} />
                                )
                            }
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ revisionId: params.row.id }))
                                dispatch(setModalOpen(AppModals.UpdateRevisionStatus))
                            }}
                            label={translate("status")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("change_status"),
                    key: "status",
                    hidden: !params.row.canWrite,
                }),

                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ revisionId: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditRevision))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ revisionId: params.row.id }))
                                dispatch(setModalOpen(AppModals.DeleteRevision))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
            ],
        }),
        [translate, dispatch],
    )

    if (isFetching) return <LoadingIcon />

    return (
        <>
            {canWrite && (
                <CustomRoleBlockedToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddRevision))}
                    buttonText={"add_revision"}
                    blockedRoles={[RoleName.FREE]}
                />
            )}

            <CustomDataGrid
                paginationMode={"server"}
                isLoading={isFetching}
                columns={columns}
                rows={revisions?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:wrench-clock"} noDataText={"no_revisions"} />,
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={revisions?.count}
                actionsColumn={[actionsColumn]}
                onFilterModelChange={setFilterModel}
                filterModel={filterModel}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.id}
                infoColumn={[infoColumn]}
                getDetailPanelContent={getObservationsPanel}
                gridId='DesktopRevisionsList'
            />
        </>
    )
}
