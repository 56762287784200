import { RoleName, useAllUserBankAccounts } from "../../../queries/userData"
import { FormProvider, RHFAutocomplete, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import React, { useCallback, useEffect } from "react"
import { Box, Button, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useDispatch, useSelector } from "../../../redux/store"
import { addPropertyToBankAccountMutation, deletePropertyFromBankAccountMutation } from "../../../mutations/user"
import RoleLockedButton from "../../../components/RoleLockedButton"
import { AppModals, setModalOpen } from "../../../redux/slices/App"

interface Props {
    onClose: () => void
}

export default function LinkBankAccountToPropertyForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: userBankAccounts } = useAllUserBankAccounts()
    const methods = useForm({
        defaultValues: {
            bankAccounts: userBankAccounts?.items.filter((b) => b.properties.includes(currentPropertyId!)).map((i) => i.id) ?? [],
        },
    })
    const dispatch = useDispatch()
    const { mutateAsync: addProperties, isPending: isAddingPropertiesToBankAccount } = addPropertyToBankAccountMutation()
    const { mutateAsync: deletePropertiesFromBankAccount, isPending: isDeletingPropertiesFromBankAccount } = deletePropertyFromBankAccountMutation()
    const { handleSubmit, reset } = methods

    useEffect(() => {
        reset({
            bankAccounts: userBankAccounts?.items.filter((b) => b.properties.includes(currentPropertyId!)).map((i) => i.id) ?? [],
        })
    }, [userBankAccounts, currentPropertyId])

    const onSave = useCallback(
        async (data: { bankAccounts: number[] }) => {
            if (currentPropertyId == null || userBankAccounts == null) return
            const currentPropertyBankAccounts = userBankAccounts.items.filter((b) => b.properties.includes(currentPropertyId))
            const newBankAccounts = data.bankAccounts.filter((b) => !currentPropertyBankAccounts.some((b2) => b2.id == b))
            const deletedBankAccounts = currentPropertyBankAccounts.filter((b) => !data.bankAccounts.some((b2) => b2 == b.id))

            await Promise.all(
                newBankAccounts.map(async (bankAccount) => {
                    await addProperties({
                        bankAccountId: bankAccount,
                        propertyIds: [currentPropertyId],
                    })
                }),
            )

            await Promise.all(
                deletedBankAccounts.map(async (bankAccount) => {
                    await deletePropertiesFromBankAccount({
                        bankAccountId: bankAccount.id,
                        propertyId: currentPropertyId,
                    })
                }),
            )

            onClose()
        },
        [currentPropertyId, userBankAccounts],
    )

    if (userBankAccounts?.count === 0) {
        return (
            <Box display='flex' gap={2} flexDirection='column' width='100%' justifyContent='center' alignItems='center'>
                <Typography variant='body2'>{translate("no_bank_accounts_found_create_one")}</Typography>
                <RoleLockedButton
                    onClick={() => dispatch(setModalOpen(AppModals.AddBankAccount))}
                    buttonText={translate("add_bank_account")}
                    blockedRoles={[RoleName.FREE]}
                />
            </Box>
        )
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={1} flexDirection={"column"}>
                <RHFAutocomplete
                    autocompleteProps={{
                        noOptionsText: translate("no_value_found"),
                        limitTags: 3,
                    }}
                    matchId
                    multiple
                    name={"bankAccounts"}
                    options={userBankAccounts?.items?.map((property) => ({ id: property.id, label: property.iban })) ?? []}
                    label={translate("associate_bank_accounts")}
                    freeSolo={false}
                />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button
                        fullWidth={isMobile}
                        color={"primary"}
                        onClick={onClose}
                        disabled={isAddingPropertiesToBankAccount || isDeletingPropertiesFromBankAccount}
                    >
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        disabled={isAddingPropertiesToBankAccount || isDeletingPropertiesFromBankAccount}
                        loading={isAddingPropertiesToBankAccount || isDeletingPropertiesFromBankAccount}
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
