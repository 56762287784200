import { Banks } from "../userData"
import { DateTime } from "luxon"

export enum BankStatementStatus {
    Created,
    InProcess,
    Finished,
}

export enum BankTransactionStatus {
    Created,
    Accepted,
    Ignored,
}

export interface BankStatementBase {
    id: number
    holder: string
    bank: Banks
    status: BankStatementStatus
}

export interface BankStatementDto extends BankStatementBase {
    periodStart: string
    periodEnd: string
    createdAt: string
    lastModifiedAt: string
}

export interface BankStatement extends BankStatementBase {
    periodStart: DateTime
    periodEnd: DateTime
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface BankStatementTransactionBase {
    id: number
    description: string
    from: string
    amount: number
    currencyId: number
    status: BankTransactionStatus
    linkedPropertyId?: number
    linkedRentingPeriodId?: number
    amountAssociatedToExpenses: number
}

export interface BankStatementTransactionDto extends BankStatementTransactionBase {
    date: string
}

export interface BankStatementTransaction extends BankStatementTransactionBase {
    date: DateTime
}

export interface BankStatementDetailsBase {
    id: number
    holder: string
    bank: Banks
    status: BankStatementStatus
    processedTransactions: number
    totalTransactions: number
}

export interface BankStatementDetailsDto extends BankStatementDetailsBase {
    transactions: BankStatementTransactionDto[]
    periodStart: string
    periodEnd: string
    createdAt: string
    lastModifiedAt: string
}

export interface BankStatementDetails extends BankStatementDetailsBase {
    transactions: BankStatementTransaction[]
    periodStart: DateTime
    periodEnd: DateTime
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface BankStatementTransactionDetailsBase {
    id: number
    description: string
    from: string
    amount: number
    currencyId: number
    status: BankTransactionStatus
    linkedPropertyId?: number
    linkedRentingPeriodId?: number
    amountAssociatedToExpenses: number
}

export interface BankStatementTransactionDetailsDto extends BankStatementTransactionDetailsBase {
    date: string
    associatedExpenses: AssociatedExpenseDto[]
}

export interface BankStatementTransactionDetails extends BankStatementTransactionDetailsBase {
    date: DateTime
    associatedExpenses: AssociatedExpense[]
}

export interface AssociatedExpenseBase {
    propertyExpenseId: number
    propertyExpenseName: string
    amount: number
    currencyId: number
}

export interface AssociatedExpenseDto extends AssociatedExpenseBase {
    date: string
}

export interface AssociatedExpense extends AssociatedExpenseBase {
    date: DateTime
}
