import React, { useMemo } from "react"
import { ICONS } from "./icons"
import { AppContext, Label, NavItemBaseProps, useLocales } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { AccountType, UserFlags, useUserDataQueryFn } from "../queries/userData"
import { useSelector } from "../redux/store"

export const useOwnerNavConfig = (): {
    subheader: string
    items: NavItemBaseProps[]
}[] => {
    const { translate } = useLocales()
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const { context } = useSelector((state) => state.appState)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { data: user } = useUserDataQueryFn()
    const newItem = useMemo(() => <Label color={"info"}>{translate("new_feature")}</Label>, [translate])
    const areBankStatementsProcessingEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())
    const arePromoCodesEnabled = useFeatureIsOn(UserFlags.PromoCode.toString())
    const arePropertyAdsEnabled = useFeatureIsOn(UserFlags.PropertyAd.toString())
    const areTaskItems = useFeatureIsOn(UserFlags.TaskItems.toString())
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useMemo(() => {
        const mainItems: {
            subheader: string
            items: NavItemBaseProps[]
        }[] = [
            {
                subheader: translate("general"),
                items: [
                    {
                        title: translate("dashboard"),
                        path: "/dashboard",
                        icon: ICONS.dashboard,
                    },
                    {
                        title: translate("properties"),
                        path: "/properties",
                        icon: ICONS.properties,
                    },
                    { title: translate("files"), path: "/documents", icon: ICONS.files },
                    {
                        title: translate("expenses.expenses"),
                        path: "/expenses",
                        icon: ICONS.expense,
                    },
                    { title: translate("income"), path: "/income", icon: ICONS.income },
                    {
                        title: translate("renting_periods"),
                        path: "/tenants",
                        icon: ICONS.tenants,
                    },
                    {
                        title: translate("insurance.name"),
                        path: "/insurance",
                        icon: ICONS.insurance,
                    },
                    {
                        title: translate("revisions"),
                        path: "/revisions",
                        icon: ICONS.revision,
                    },
                    { title: translate("groups"), path: "/groups", icon: ICONS.group },
                ],
            },
        ]

        const documentsChildren: NavItemBaseProps[] = [
            {
                title: translate("template"),
                path: "/document-templates/contract-templates",
                icon: ICONS.contractsTemplates,
            },
            {
                title: translate("general_documents"),
                path: "/document-templates/general-documents",
                icon: ICONS.generalDocuments,
            },
        ]

        if (showPaymentNotifications) {
            documentsChildren.push({
                title: translate("notifications_templates"),
                path: "/document-templates/notification-templates",
                icon: ICONS.notificationsTemplates,
            })
        }

        if (context === AppContext.Owner && (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER))) {
            mainItems[0].items.push({
                title: translate("documents_type"),
                path: "/document-templates/contract-templates",
                icon: ICONS.contracts,
                children: documentsChildren,
                info: newItem,
            })
        }

        if (areBankStatementsProcessingEnabled && user?.isNonWhiteLabelOrWhiteLabelAdminUser) {
            mainItems[0].items.push({
                title: translate("bank_statements"),
                path: "/bank-statements",
                icon: ICONS.contracts,
                info: newItem,
            })
        }
        if (mainItems[0].items.length === 0) {
            mainItems.splice(-1)
        }

        if (user?.promoCode && context === AppContext.Owner && arePromoCodesEnabled && !isWhitelabel) {
            mainItems[0].items.push({
                title: translate("promotional_code"),
                path: "/promo-code",
                icon: ICONS.promotionalCode,
            })
        }

        if (arePropertyAdsEnabled && context === AppContext.Owner) {
            mainItems[0].items.push({
                title: translate("marketing.name"),
                path: "/marketing",
                icon: ICONS.marketing,
            })
        }

        const nomenclatureChildren = [
            {
                title: translate("labels"),
                path: "/nomenclature/labels",
                icon: ICONS.labels,
            },
            {
                title: translate("providers"),
                path: "/nomenclature/providers",
                icon: ICONS.providers,
            },
        ]

        nomenclatureChildren.push({
            title: translate("bank-accounts"),
            path: "/nomenclature/bank-accounts",
            icon: ICONS.bankAccount,
        })

        if (areTaskItems) {
            mainItems[0].items.splice(2, 0, {
                title: translate("tasks"),
                path: "/tasks",
                icon: ICONS.tasks,
            })
            nomenclatureChildren.push({
                title: translate("task_sections"),
                path: "/nomenclature/sections",
                icon: ICONS.sections,
            })
        }

        if (areInvoicingConfigurationOn) {
            nomenclatureChildren.push({
                title: translate("invoicing_configuration"),
                path: "/nomenclature/invoicing-configuration",
                icon: ICONS.invoicingConfiguration,
            })
        }

        if (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER)) {
            nomenclatureChildren.push({
                title: "C168",
                path: "/nomenclature/C168",
                icon: ICONS.c168,
            })
        }

        mainItems[0].items.push({
            title: translate("calendar.menu_name"),
            path: "/calendar",
            icon: ICONS.calendar,
        })
        if (!isWhitelabel || (isWhitelabel && user?.accountType === AccountType.WHITELABEL_ADMIN_USER)) {
            mainItems[0].items.push({
                title: translate("nomenclature"),
                path: "/nomenclature",
                icon: ICONS.nomenclature,
                children: nomenclatureChildren,
                info: newItem,
            })
        }

        return mainItems
    }, [
        translate,
        isWhitelabel,
        user,
        areInvoicingConfigurationOn,
        areTaskItems,
        arePropertyAdsEnabled,
        arePromoCodesEnabled,
        context,
        areBankStatementsProcessingEnabled,
        showPaymentNotifications,
    ])
}
