import { FormProvider, RHFRadioGroup, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { Alert, Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { ManagerType } from "../../../propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { NewInvoicingConfigurationAccessSchema } from "../../../../validations/invoicing-configuration"
import { addNewInvoicingConfigurationAccess } from "../../../../mutations/invoicingConfiguration"

interface AddGroupAccessFormProps {
    onClose: () => void
}

export interface NewInvoicingConfigurationAccessRequest {
    email: string
    accessType: ManagerType
}

const AddInvoicingConfigurationAccessForm = ({ onClose }: AddGroupAccessFormProps) => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { errorMessage, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addNewAccess, isPending: isAddingNewConfiguration } = addNewInvoicingConfigurationAccess()

    const methods = useForm<NewInvoicingConfigurationAccessRequest>({
        resolver: yupResolver(NewInvoicingConfigurationAccessSchema()),
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            accessType: ManagerType.READ_WRITE,
        },
    })

    const { handleSubmit, setError } = methods

    const onSubmit = useCallback(
        async (data: NewInvoicingConfigurationAccessRequest) => {
            if (data == null || editingItem == null) return

            await addNewAccess({ invoicingConfigurationId: editingItem.invoicingConfigurationId, email: data.email, accessType: data.accessType })
            onClose()
        },
        [addNewAccess, onClose, setError, editingItem],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={isMobile ? 2 : 1} flexDirection={"column"}>
                <RHFRadioGroup
                    name={"accessType"}
                    options={[
                        {
                            label: translate(`managerType-${ManagerType.READ_WRITE}`),
                            value: ManagerType.READ_WRITE,
                            moreInfo: "invoices_read_write_access_info",
                        },
                    ]}
                    sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
                />

                <RHFTextField name='email' label={translate("email")} required />
                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isAddingNewConfiguration}
                        disabled={isAddingNewConfiguration}
                        color={"primary"}
                        type={"submit"}
                        fullWidth
                        variant={isMobile ? "contained" : "text"}
                    >
                        {translate("save")}
                    </LoadingButton>
                    {isMobile && (
                        <Button color={"primary"} onClick={onClose} disabled={isAddingNewConfiguration}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}

export default AddInvoicingConfigurationAccessForm
