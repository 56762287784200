import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, CommentType, DetailsSheet, Scrollbar, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useSectionItemsQueryFn, useTaskCommentsQueryFn, useTenantTaskCommentsQueryFn, useTenantTasksQueryFn } from "../../../queries/kanban"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import TaskDetailsCommentInput from "../../../sections/kanban/details/TaskDetailsCommentInput"
import { deleteTaskCommentMutation } from "../../../mutations/kanban"
import CommentCard from "../../../sections/kanban/CommentCard"
import { useUserDataQueryFn } from "../../../queries/userData"

export default function TaskCommentsDetailsSheetGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: ownerTasks } = useSectionItemsQueryFn(editingItem?.sectionId)
    const { data: tenantTasks } = useTenantTasksQueryFn(editingItem?.rentingPeriodId)
    const { data: ownerComments } = useTaskCommentsQueryFn(editingItem?.taskId, editingItem?.sectionId)
    const { data: tenantComments } = useTenantTaskCommentsQueryFn(editingItem?.rentingPeriodId, editingItem?.taskId)
    const { mutateAsync: deleteComment, isPending } = deleteTaskCommentMutation()

    const comments = useMemo(() => (context === AppContext.Owner ? ownerComments : tenantComments), [context, ownerComments, tenantComments])
    const tasks = useMemo(() => (context === AppContext.Owner ? ownerTasks : tenantTasks), [context, ownerTasks, tenantTasks])

    const currentTask = useMemo(() => tasks?.find((task) => task.id === editingItem?.taskId), [tasks, editingItem])

    const onDelete = useCallback(
        async (comment: CommentType) => {
            if (comment == null || currentTask == null) return
            await deleteComment({ sectionId: currentTask.sectionId, taskId: comment.taskId, commentId: comment.id })
        },
        [deleteComment, currentTask],
    )

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.TaskComments}
            onClose={() => dispatch(setModalOpen(AppModals.TaskDetails))}
            isLoading={false}
            title={translate("comments")}
            options={[]}
            headerColor={theme.palette.primary.main}
        >
            <Scrollbar sx={{ maxHeight: "65vh" }}>
                {comments?.map((comment) => (
                    <CommentCard
                        key={comment.id}
                        comment={comment}
                        onDelete={userData?.isTicketsOnly === false && context === AppContext.Owner ? () => onDelete(comment) : undefined}
                        isLoading={isPending}
                    />
                ))}
            </Scrollbar>
            {(context === AppContext.Owner ? currentTask?.canEdit : true) && (
                <TaskDetailsCommentInput taskId={currentTask?.id} sectionId={currentTask?.sectionId} />
            )}
        </DetailsSheet>
    )
}
