import { AccountType, useUserDataQueryFn } from "../../queries/userData"
import { Tab } from "@mui/material"
import { CustomTabs, useLocales } from "rentzz"
import { taskFilterState, TasksFilterOptions } from "../../utils/atom"
import React, { useCallback } from "react"
import { useAtom } from "jotai/index"
import { a11yProps } from "../../utils/A11yProps"

const allPages = [TasksFilterOptions.All, TasksFilterOptions.Mine]

// export const getTaskLabelColorByFilter = (filter: TasksFilterOptions, theme: Theme) => {
//     switch (filter) {
//         case TasksFilterOptions.All:
//             return theme.palette.primary.main
//         case TasksFilterOptions.Mine:
//             return theme.palette.info.main
//     }
// }

const TaskFilters = () => {
    const { data: userData } = useUserDataQueryFn()
    // const theme = useTheme()
    const [filterModel, setFilterModel] = useAtom(taskFilterState)
    const { translate } = useLocales()

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: TasksFilterOptions) => {
            switch (newValue) {
                case TasksFilterOptions.All:
                    setFilterModel(TasksFilterOptions.All)
                    break
                case TasksFilterOptions.Mine:
                    setFilterModel(TasksFilterOptions.Mine)
                    break
                default:
                    setFilterModel(TasksFilterOptions.All)
            }
        },
        [setFilterModel],
    )

    if (userData?.accountType !== AccountType.WHITELABEL_ADMIN_USER && !import.meta.env.DEV) return null

    return (
        <>
            <CustomTabs value={filterModel} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
                {allPages.map((f, index) => (
                    <Tab
                        key={f}
                        value={f}
                        iconPosition='end'
                        label={translate(`task_filter_options-${f}`)}
                        // icon={<Label sx={{ mr: 1, color: "white", backgroundColor: getTaskLabelColorByFilter(f, theme) }}>-</Label>}
                        style={{
                            textTransform: "none",
                        }}
                        {...a11yProps(index)}
                    />
                ))}
            </CustomTabs>
        </>
    )
}

export default TaskFilters
