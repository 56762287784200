import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"
import { PropertyFileType, PropertyRevision, RevisionObservation, TenantRevision } from "../../../redux/slices/AddProperty"
import { AppContext, CustomDataGrid, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import NoObservationsPage from "./NoObservationsPage"
import Header from "../../expenses/expense-details/Header"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { useRevisionObservationsQueryFn, useTenantRevisionObservationsQueryFn } from "../../../queries/revision"
import DeleteIcon from "@mui/icons-material/Delete"

export interface DetailsPanelProps {
    currentRevision: (PropertyRevision & { canWrite: boolean; canDelete: boolean }) | TenantRevision
}
const ObservationsDetailsPanel = ({ currentRevision }: DetailsPanelProps) => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { context } = useSelector((state) => state.appState)
    const { data: ownerObservations, isFetching } = useRevisionObservationsQueryFn(currentRevision.id)
    const { data: tenantObservations, isFetching: areTenantObservationsLoading } = useTenantRevisionObservationsQueryFn(currentRevision.id)

    const observations = useMemo(
        () => (context === AppContext.Tenant ? tenantObservations : ownerObservations),
        [context, tenantObservations, ownerObservations],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<RevisionObservation>[] = [
            {
                field: "title",
                type: "string",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("title"),
            },
            {
                field: "description",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("text"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 25)}</Typography>,
                        tooltipText: v.value.length > 25 ? v.value : "",
                    }),
            },

            {
                field: "observationDate",
                type: "date",
                minWidth: 100,
                flex: 0.7,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.5,
            minWidth: 180,
            type: "actions",
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ revisionId: currentRevision.id, observationId: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditObservation))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden:
                        !(currentRevision as PropertyRevision & { canWrite: boolean; canDelete: boolean }).canWrite || context === AppContext.Tenant,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        observationId: params.id,
                                        type: PropertyFileType.RevisionObservation,
                                        canWrite: (currentRevision as PropertyRevision & { canWrite: boolean; canDelete: boolean }).canWrite,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ revisionId: currentRevision?.id, observationId: params.row.id }))
                                dispatch(setModalOpen(AppModals.DeleteObservation))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden:
                        !(currentRevision as PropertyRevision & { canWrite: boolean; canDelete: boolean }).canDelete || context === AppContext.Tenant,
                }),
            ],
        }),
        [translate, currentRevision, dispatch],
    )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                    paddingBottom: 3,
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <Header currentItem={currentRevision} itemType='observation' />
                    {(isFetching || areTenantObservationsLoading) && (
                        <Box display='flex' flexDirection='column' width='100%' height={150} alignItems='center' justifyContent='center'>
                            <CircularProgress />
                        </Box>
                    )}
                    {observations && observations.length > 0 ? (
                        <CustomDataGrid
                            columns={columns}
                            rows={observations ?? []}
                            idKey={"id"}
                            totalCount={observations?.length}
                            actionsColumn={[actionsColumn]}
                            gridHeight={270}
                            paginationMode={"client"}
                            hidePagination={observations?.length < 4}
                            gridId={"ObservationsDetailsPanel"}
                        />
                    ) : (
                        !isFetching && !areTenantObservationsLoading && <NoObservationsPage />
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default ObservationsDetailsPanel
