import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { AppContext, useIsMobile } from "rentzz"
import { useSelector } from "../redux/store"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { sum } from "lodash"
import { PropertyIncome } from "../redux/slices/AddProperty"
import { useRentingPeriodDetails } from "./tenants"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { AppModals } from "../redux/slices/App"
import { useUserPropertiesQuery } from "./userData"
import { useMemo } from "react"
import { useUserGroupsQueryFn } from "./groups"
import { PaginatedData } from "./expenses"

export const useIncomeQueryFn = (page: number, pageSize: number, sortingColumns: GridSortModel, filterModel: GridFilterModel) => {
    const { context, currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId ? Number(currentRentingPeriodId) : undefined)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Incomes, rentingPeriodDetails?.propertyId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (currentRentingPeriodId && rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return properties?.map((p) => p.id)
    }, [properties, currentRentingPeriodId, rentingPeriodDetails, currentPropertyId, currentGroupId])

    return useQuery({
        queryKey: [
            ComplexQueryIDs.Incomes,
            {
                properties: propertiesIds,
                page,
                pageSize,
                sortingColumns,
                rentingPeriodId: rentingPeriodDetails?.id,
                filterModel,
            },
        ],
        queryFn: context === AppContext.Tenant ? Api.fetchPayments : Api.fetchIncome,
        staleTime: Infinity,
        enabled: (context === AppContext.Tenant && currentPropertyId != null) || context === AppContext.Owner,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteIncomesQueryFn = (sortingModel?: GridSortModel, filterModel?: GridFilterModel) => {
    const { context, currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Incomes, rentingPeriodDetails?.propertyId)

    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (currentRentingPeriodId && rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return properties?.map((p) => p.id)
    }, [properties, currentRentingPeriodId, rentingPeriodDetails, currentPropertyId, currentGroupId])

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [
            ComplexQueryIDs.Incomes,
            {
                properties: propertiesIds,
                sortingModel,
                rentingPeriodId: currentRentingPeriodId,
                filterModel,
            },
        ],
        queryFn: context === AppContext.Tenant ? Api.fetchInfinitePayments : Api.fetchInfiniteIncomes,
        staleTime: Infinity,
        enabled: (context === AppContext.Tenant && currentPropertyId != null) || context === AppContext.Owner,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
    })
}

export const useSingleIncomeQuery = (incomeId?: number) => {
    const { currentPropertyId, modalOpen, context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const queryClient = useQueryClient()
    const isMobile = useIsMobile()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Incomes, rentingPeriodDetails?.propertyId)

    return useQuery({
        queryFn: async () => {
            let toReturn: (PropertyIncome & { canWrite: boolean; canDelete: boolean }) | null
            if (isMobile) {
                const incomes = queryClient.getQueryData([
                    ComplexQueryIDs.Income,
                    { propertyId: currentPropertyId ?? rentingPeriodDetails?.propertyId, rentingPeriodId: rentingPeriodDetails?.id },
                ]) as
                    | {
                          pages: PaginatedData<PropertyIncome>[]
                      }
                    | undefined
                const arrayOfIncomes = incomes?.pages.flatMap((r) => r.items)
                const income = arrayOfIncomes?.find((income) => income.id == incomeId)
                if (income) {
                    toReturn = {
                        ...income,
                        canWrite: editablePropertiesIds?.includes(income.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(income.propertyId) ?? false,
                    }
                } else toReturn = income ?? null
            } else {
                const incomesPages = queryClient.getQueriesData({
                    queryKey: [ComplexQueryIDs.Income, { propertyId: currentPropertyId }],
                })

                const arrayOfIncomes = (incomesPages?.flatMap((incomeWithKey) => incomeWithKey[1]) as PaginatedData<PropertyIncome>[])?.flatMap(
                    (r) => r?.items,
                )
                const income = arrayOfIncomes.find((income) => income?.id == incomeId)
                if (income) {
                    toReturn = {
                        ...income,
                        canWrite: editablePropertiesIds?.includes(income.propertyId) ?? false,
                        canDelete: deletablePropertiesIds?.includes(income.propertyId) ?? false,
                    }
                } else toReturn = income ?? null
            }

            const income =
                toReturn ??
                (context === AppContext.Owner ? await Api.getIncome(incomeId!) : await Api.getTenantIncome(incomeId!, currentPropertyId!)) ??
                null

            if (income) {
                toReturn = {
                    ...income,
                    canWrite: editablePropertiesIds?.includes(income.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(income.propertyId) ?? false,
                }
            } else toReturn = null

            return toReturn ?? null
        },
        queryKey: [ComplexQueryIDs.Income, { incomeId }],
        staleTime: Infinity,
        enabled:
            !!incomeId &&
            modalOpen != null &&
            [
                AppModals.IncomeDetails,
                AppModals.PaymentDetails,
                AppModals.EditIncome,
                AppModals.EditPayment,
                AppModals.AddExpense,
                AppModals.AddPayment,
                AppModals.AddIncome,
                AppModals.DeleteIncome,
                AppModals.DeletePayment,
                AppModals.LinkExpenseToIncomeAsOwner,
                AppModals.LinkExpenseToIncomeAsTenant,
                AppModals.HandleIncomeStatus,
                AppModals.DeletePayment,
                AppModals.EditPayment,
            ].includes(modalOpen),
    })
}

export const useIncomeLinkedExpensesQuery = (expenseIds: number[] | undefined, incomeId: number | undefined) => {
    const { context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.Incomes)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.IncomeExpenses,
            {
                incomeId,
                expenseIds,
            },
        ],
        queryFn: Api.fetchIncomeExpenses,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!expenseIds && expenseIds.length > 0,
        select: (data) => {
            if (userProperties == null) return []
            return data.map((i) => ({
                ...i,
                canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
            }))
        },
    })
}

export const getIncomesReportSummaryQueryFn = (showAll: boolean = false) => {
    const { currentPropertyId, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: properties } = useUserPropertiesQuery()
    const { data: groups } = useUserGroupsQueryFn()

    const propertiesIds = useMemo(() => {
        if (showAll) return properties?.map((p) => p.id)
        if (rentingPeriodDetails) return [rentingPeriodDetails.propertyId]
        if (currentPropertyId) return [currentPropertyId]
        if (currentGroupId) return groups?.find((g) => g.id === currentGroupId)?.properties.map((p) => p.propertyId)
        return properties?.map((p) => p.id)
    }, [rentingPeriodDetails, currentPropertyId, currentGroupId, properties, showAll, groups])

    return useQuery({
        queryKey: [ComplexQueryIDs.IncomeSummaryReport, { propertiesIds, rentingPeriodId: currentRentingPeriodId }],
        queryFn: Api.getIncomesSummaryReport,
        staleTime: Infinity,
        enabled: (propertiesIds?.length ?? 0) > 0,
    })
}
