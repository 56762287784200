import React, { SyntheticEvent, useCallback } from "react"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material"
import { deleteInvoicingClientMutation } from "../../../../../mutations/invoicingClient"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteDialog from "../../../../../dialogs/DeleteDialog"
import DeleteSheetContent from "../../../../../sections/DeleteSheetContent"

const DeleteInvoicingClientGuard = () => {
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const { mutateAsync: deleteInvoicingConfigurationClient, isPending: isDeleteLoading } = deleteInvoicingClientMutation()
    const dispatch = useDispatch()
    const theme = useTheme()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, editingItem])

    const onAccept = useCallback(
        async (e: SyntheticEvent) => {
            e.stopPropagation()
            await deleteInvoicingConfigurationClient({ invoicingClientId: editingItem?.invoicingClientId })

            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        },
        [modalOpen, dispatch, deleteInvoicingConfigurationClient],
    )

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_invoicing_client")}
                isOpen={isMobile && modalOpen === AppModals.DeleteInvoicingClient}
                onClose={onClose}
                headerColor={theme.palette.error.main}
            >
                <DeleteSheetContent
                    content={translate("delete_invoicing_client_details")}
                    onClose={onClose}
                    isDeleteLoading={isDeleteLoading}
                    onAccept={onAccept}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteInvoicingClient}
                isDeleteLoading={isDeleteLoading}
                title={translate("delete_invoicing_client")}
                content={translate("delete_invoicing_client_details")}
                onClose={onClose}
                onAccept={onAccept}
            />
        </>
    )
}

export default DeleteInvoicingClientGuard
