import { Page, RoleName, useIsMobile, useLocales } from "rentzz"
import { Alert, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import React, { useCallback, useMemo } from "react"
import RentingPeriodActionCards from "./RentingPeriodActionCards"
import RentingPeriodTenantsCard from "./tenants/RentingPeriodTenantsCard"
import RentingPeriodDetailsCard from "./details/RentingPeriodDetailsCard"
import RentingPeriodNotificationCard from "./notifications/RentingPeriodNotificationCard"
import RentingPeriodDetailsHeader from "../RentingPeriodDetailsHeader"
import { CloseIcon } from "yet-another-react-lightbox"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { Receipt } from "@mui/icons-material"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../../../../queries/userData"
import { ExportType } from "../../../../../utils/actions"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import EventNoteIcon from "@mui/icons-material/EventNote"
import Api from "../../../../../api/Api"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import { RentingPeriodStatusCode } from "../../../../../redux/slices/AddProperty"
import { useSnackbar } from "notistack"
import { DateTime } from "luxon"
import { LoadingButton } from "@mui/lab"
import { updateRentingPeriodMutation } from "../../../../../mutations/tenants"

export default function GeneralPage() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar()
    const { modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const showPaymentNotification = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const { data: userData } = useUserDataQueryFn()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const { mutate, isPending } = updateRentingPeriodMutation()

    const handleC168Send = useCallback(() => {
        if (currentRentingPeriodId == null) return

        mutate({
            tenantId: currentRentingPeriodId,
            operations: [
                {
                    op: "replace",
                    path: "/isC168Sent",
                    value: "true",
                },
            ],
        })
    }, [currentRentingPeriodId])

    const handleGenerate = useCallback(() => {
        dispatch(setModalOpen(AppModals.ExportDetailsSheet))
        dispatch(setEditingItem({ type: ExportType.Balance }))
    }, [dispatch])

    const isCreateInvoiceButtonDisabled = useMemo(() => {
        if (rentingPeriodDetails == null) return
        return (
            rentingPeriodDetails.invoicingCompany == null ||
            rentingPeriodDetails.invoicingSeries == null ||
            rentingPeriodDetails.invoicingClientId == null ||
            rentingPeriodDetails.invoicingCurrencyId === undefined ||
            rentingPeriodDetails.invoiceMaxDays == null ||
            rentingPeriodDetails.invoicingLanguage == null ||
            rentingPeriodDetails.defaultInvoicingText == null ||
            rentingPeriodDetails.defaultInvoicingTemplate == null
        )
    }, [rentingPeriodDetails])

    return (
        <Page title={translate("tenants")} propertyName={translate("general")}>
            {isMobile && modalOpen == null && (
                <SpeedDial
                    sx={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                    }}
                    color='primary'
                    ariaLabel='generalDetailsRentingPeriod'
                    icon={<SpeedDialIcon openIcon={<CloseIcon />} icon={<MoreVertIcon />} />}
                >
                    <SpeedDialAction
                        key={"export"}
                        icon={<FileDownloadIcon />}
                        tooltipTitle={translate("generate_balance_report")}
                        tooltipOpen
                        onClick={handleGenerate}
                    />
                    {areInvoicingConfigurationOn &&
                        (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                            <SpeedDialAction
                                key={"invoicing"}
                                icon={<Receipt />}
                                tooltipTitle={translate("create_invoice")}
                                tooltipOpen
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.CreateInvoice))
                                    dispatch(
                                        setEditingItem({
                                            id: currentRentingPeriodId,
                                            isCreateInvoiceButtonDisabled,
                                        }),
                                    )
                                }}
                            />
                        )}
                    {showPaymentNotification && (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                        <SpeedDialAction
                            key={"payment"}
                            icon={<EventNoteIcon />}
                            tooltipTitle={translate("generate_payment_notification")}
                            tooltipOpen
                            onClick={async () => {
                                if (currentRentingPeriodId == null) return
                                const notificationPreview = await Api.fetchGeneralRentingPeriodNotificationPreview(currentRentingPeriodId, false)
                                const url = window.URL.createObjectURL(new Blob([notificationPreview]))
                                dispatch(
                                    setEditingItem({
                                        url: url,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.PdfViewer))
                            }}
                        />
                    )}

                    {(rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
                        rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.PartiallyAccepted) && (
                        <SpeedDialAction
                            key={"notification"}
                            icon={<ForwardToInboxIcon />}
                            tooltipTitle={translate("send_notification")}
                            tooltipOpen
                            onClick={async () => {
                                if (
                                    userData?.roles.roleName != null &&
                                    [RoleName.FREE, RoleName.BRONZE, RoleName.SILVER].includes(userData?.roles.roleName)
                                ) {
                                    enqueueSnackbar(translate("change_plan"))
                                } else {
                                    dispatch(setModalOpen(AppModals.SendTenantNotification))
                                }
                            }}
                        />
                    )}
                </SpeedDial>
            )}

            {rentingPeriodDetails?.removeTenantAccessAt != null &&
                (rentingPeriodDetails?.removeTenantAccessAt < DateTime.now() ||
                    rentingPeriodDetails.removeTenantAccessAt < DateTime.now().plus({ day: 7 })) && (
                    <Alert sx={{ mt: 2 }} severity={rentingPeriodDetails?.removeTenantAccessAt < DateTime.now() ? "error" : "warning"}>
                        {translate(
                            rentingPeriodDetails?.removeTenantAccessAt < DateTime.now() ? "tenant_does_not_have_access" : "tenant_will_lose_access",
                            { date: rentingPeriodDetails?.removeTenantAccessAt.toLocaleString(DateTime.DATE_SHORT) },
                        )}
                    </Alert>
                )}

            {rentingPeriodDetails?.isC168Sent === false && (
                <Alert
                    sx={{ mt: 2 }}
                    action={
                        <LoadingButton loading={isPending} onClick={handleC168Send}>
                            {translate("mark_c168_sent")}
                        </LoadingButton>
                    }
                    severity={Math.abs(rentingPeriodDetails.moveInDate?.diffNow("day").days) < 30 ? "warning" : "error"}
                >
                    {translate("c168_not_sent_yet")}
                </Alert>
            )}
            <RentingPeriodDetailsHeader page={"general"} />

            <Grid container paddingBottom={2}>
                <RentingPeriodActionCards />

                <Grid item xs={12} md={6} xl={6}>
                    <RentingPeriodDetailsCard />
                </Grid>

                <Grid item xs={12} md={6} xl={6} paddingLeft={isMobile ? "" : 2} paddingTop={isMobile ? 2 : ""}>
                    <RentingPeriodNotificationCard />
                </Grid>
                <Grid item xs={12} md={12} xl={12} paddingTop={2}>
                    <RentingPeriodTenantsCard />
                </Grid>
            </Grid>
        </Page>
    )
}
