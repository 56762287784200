import { Box } from "@mui/material"
import React, { useCallback } from "react"
import { updateRentingPeriodMutation } from "../../../../../mutations/tenants"
import { LightTooltip, LoadingIcon, Page, useLocales } from "rentzz"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { Operation } from "fast-json-patch"
import Invoicing from "./Invoicing"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../queries/userData"
import ConfigCustomCard from "./ConfigCustomCard"
import NotificationStatus from "./NotificationStatus"
import { useSelector } from "../../../../../redux/store"
import LateFeesPercentage from "./LateFeesPercentage"

export default function Configurations() {
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { mutateAsync: updateRentingPeriod, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    const handleAutomaticExpense = useCallback(
        async (event: SelectChangeEvent<string>) => {
            const newValue = event.target?.value === "true"
            if (newValue === rentingPeriodDetails?.isRentExpenseAutomatic) return

            const operations: Operation[] = [{ op: "replace", path: "/isRentExpenseAutomatic", value: newValue }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    const handleCurrencyChange = useCallback(
        async (event: SelectChangeEvent<string>) => {
            if (rentingPeriodDetails == null || !rentingPeriodDetails.isRentExpenseAutomatic) return

            await updateRentingPeriod({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations: [{ op: "replace", path: "automaticExpenseCurrencyId", value: event.target.value }],
            })
        },
        [rentingPeriodDetails],
    )

    const handleTenantOnAddPartialPayments = useCallback(
        async (event: SelectChangeEvent<string>) => {
            const newValue = event.target?.value === "true"
            if (newValue === rentingPeriodDetails?.acceptPartialPayments) return

            const operations: Operation[] = [{ op: "replace", path: "/acceptPartialPayments", value: newValue }]
            if (rentingPeriodDetails != null && operations.length > 0) {
                await updateRentingPeriod({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }
        },
        [rentingPeriodDetails, updateRentingPeriod],
    )

    if (rentingPeriodDetails == null) return <LoadingIcon />
    return (
        <Page
            title={translate(currentRentingPeriodId ? "tenants" : "configurations")}
            propertyName={currentRentingPeriodId ? translate("configurations") : ""}
            paddingBottom={2}
        >
            <Box paddingTop={2} display={"flex"} flexDirection={"column"} gap={1}>
                <NotificationStatus />
                <ConfigCustomCard
                    title={"automatic_expense"}
                    isLoading={isRentingPeriodUpdating}
                    canWrite={canWrite}
                    defaultValue={rentingPeriodDetails.isRentExpenseAutomatic.toString()}
                    handleSelect={handleAutomaticExpense}
                    icon={"mdi:information"}
                    tooltipTitle={translate("automatic_expense_tooltip")}
                    onCurrencyChange={rentingPeriodDetails?.isRentExpenseAutomatic ? handleCurrencyChange : undefined}
                    defaultCurrency={rentingPeriodDetails.automaticExpenseCurrencyId}
                />
                <LightTooltip
                    title={rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0 ? translate("disabled_when_no_mails") : ""}
                    arrow
                >
                    <span>
                        <ConfigCustomCard
                            title={"tenant_can_add_partial_payments"}
                            isLoading={isRentingPeriodUpdating}
                            canWrite={canWrite}
                            defaultValue={rentingPeriodDetails.acceptPartialPayments.toString()}
                            handleSelect={handleTenantOnAddPartialPayments}
                            isActionDisabled={rentingPeriodDetails && rentingPeriodDetails.invitedUsers.length === 0}
                        />
                    </span>
                </LightTooltip>
                <LateFeesPercentage />
                {areInvoicingConfigurationOn && <Invoicing />}
            </Box>
        </Page>
    )
}
