import React, { ReactNode } from "react"
import UpdateContractTemplateArchiveStatusGuard from "./template/UpdateContractTemplateArchiveStatusGuard"
import ExpenseGuard from "./expense/ExpenseGuard"
import EntityFileManagerGuard from "./files/EntityFileManagerGuard"
import DeleteGuard from "./DeleteGuard"
import IncomeGuard from "./income/IncomeGuard"
import MetersGuard from "./meters/MetersGuard"
import InsuranceGuard from "./insurance/InsuranceGuard"
import PaymentsGuard from "./payments/PaymentsGuard"
import DocumentsGuard from "./files/DocumentsGuard"
import DeleteFilesGuard from "./files/DeleteFilesGuard"
import ResendInvitation from "../../sections/propertyDetails/Tenants/ResendInvitation"
import RemoveInvitation from "../../sections/propertyDetails/Tenants/RemoveInvitation"
import ContactGuard from "./contact/ContactGuard"
import EditRentingPeriodDetailsGuard from "./rentingPeriod/EditRentingPeriodDetailsGuard"
import InviteTenantGuard from "./rentingPeriod/InviteTenantGuard"
import TenantsDetailsSheet from "../../sections/propertyDetails/Tenants/rentingPeriodDetails/general/tenants/TenantsDetailsSheet"
import NoteGuard from "./notes/NoteGuard"
import PropertyGuard from "./propertiesDetails/providers/PropertyGuard"
import EditPropertyNameGuard from "./propertiesDetails/general/EditPropertyNameGuard"
import AddPropertyGuard from "./user/AddPropertyGuard"
import EditPropertyGuard from "./user/EditPropertyGuard"
import EditPropertyLocationGuard from "./user/EditPropertyLocationGuard"
import CreateRentingPeriodGuard from "./rentingPeriod/CreateRentingPeriodGuard"
import DeleteRentingPeriod from "./rentingPeriod/DeleteRentingPeriod"
import ExportGuard from "./ExportGuard"
import UserGuard from "./user/UserGuard"
import EditExternalSystemCodeName from "./rentingPeriod/EditExternalSystemCodeName"
import UserHelpGuard from "../../sections/user/guide/UserHelpGuard"
import TasksGuard from "./kanban/TasksGuard"
import AcceptOnTenantBehalf from "../../sections/propertyDetails/Tenants/AcceptOnTenantBehalf"
import JournalGuard from "./rentingPeriod/journal/JournalGuard"
import DashboardGuard from "../../sections/user/dashboard-preview/DashboardGuard"
import PdfViewerGuard from "../PdfViewerGuard"
import InvoicingGuard from "./rentingPeriod/invoices/InvoicingGuard"
import RevisionGuard from "./revisions/RevisionGuard"
import SendTenantNotification from "./rentingPeriod/SendTenantNotification"
import ProviderSendAutomaticallyToTenant from "../../sections/propertyDetails/configurations/providers/ProviderSendAutomaticallyToTenant"
import MarketingGuard from "./marketing/MarketingGuard"
import DeleteBankStatementTransactionAssociatedExpenseGuard from "./bank-statements/DeleteBankStatementTransactionAssociatedExpenseGuard"
import DeleteBankStatementGuard from "./bank-statements/DeleteBankStatementGuard"
import BankStatementDetailsSheet from "./bank-statements/BankStatementDetailsSheet"
import InvoicingConfigurationsGuard from "./invoicing-configurations/InvoicingConfigurationsGuard"
import ProvidersWithoutExpenseThisMonthGuard from "./dashboard/ProvidersWithoutExpenseThisMonthGuard"

interface Props {
    children: ReactNode
}

const AlertsGuard = ({ children }: Props) => {
    return (
        <>
            <UpdateContractTemplateArchiveStatusGuard />
            <UserHelpGuard />
            <CreateRentingPeriodGuard />
            <DeleteRentingPeriod />
            <ResendInvitation />
            <AcceptOnTenantBehalf />
            <RemoveInvitation />
            <EditRentingPeriodDetailsGuard />
            <InvoicingGuard />
            <InviteTenantGuard />
            <TenantsDetailsSheet />
            <EditExternalSystemCodeName />
            <NoteGuard />
            <ContactGuard />
            <DeleteGuard />
            <ExpenseGuard />
            <IncomeGuard />
            <InsuranceGuard />
            <MetersGuard />
            <PaymentsGuard />
            <DocumentsGuard />
            <EntityFileManagerGuard />
            <DeleteFilesGuard />
            <JournalGuard />
            <UserGuard />
            <PropertyGuard />
            <AddPropertyGuard />
            <EditPropertyGuard />
            <EditPropertyNameGuard />
            <EditPropertyLocationGuard />
            <ExportGuard />
            <TasksGuard />
            <DashboardGuard />
            <PdfViewerGuard />
            <RevisionGuard />
            <SendTenantNotification />
            <ProviderSendAutomaticallyToTenant />
            <MarketingGuard />
            <DeleteBankStatementTransactionAssociatedExpenseGuard />
            <DeleteBankStatementGuard />
            <BankStatementDetailsSheet />
            <InvoicingConfigurationsGuard />
            <ProvidersWithoutExpenseThisMonthGuard />
            {children}
        </>
    )
}
export default AlertsGuard
