import { DetailsSheet, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { useUserPropertiesQuery } from "../../queries/userData"
import UnpaidExpensesGroupedItems from "./UnpaidExpensesGroupedItems"

export default function MobileUnpaidExpenseDetailsSheet() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, currentPropertyId } = useSelector((state) => state.appState)
    const { data: properties } = useUserPropertiesQuery()

    const currentProperty = useMemo(() => properties?.find((p) => p.id === currentPropertyId), [currentPropertyId, properties])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [modalOpen, dispatch])

    return (
        <DetailsSheet
            onClose={closeModal}
            isOpen={modalOpen === AppModals.PropertyUnpaidExpensesDetailsSheet}
            isLoading={false}
            title={translate("unpaid_amount")}
        >
            <UnpaidExpensesGroupedItems unpaidExpensesStatus={currentProperty?.unpaidExpensesStatus ?? []} />
        </DetailsSheet>
    )
}
