import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
    AccountPopover,
    DashboardContext,
    FormProvider,
    Iconify,
    LanguagePopover,
    LayoutOption,
    LoadingScreen,
    Page,
    RHFCheckbox,
    useIsMobile,
    useLocales,
    UserContextProvider,
} from "rentzz"
import { AccountCreationAcceptanceSchema } from "../../validations/accountCreationAcceptanceSchema"
import { Box, Link, Paper, Typography, useTheme } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { styled } from "@mui/material/styles"
import { RoleName, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { AppModals, setContext, setCurrentPropertyId, setCurrentRentingPeriodId, setModalOpen } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"
import { acceptPrivacyPolicyMutation, acceptTOSMutation, useLogout } from "../../mutations/user"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useChangeLanguage } from "../../utils/useChangeLanguage"

interface AccountCreationAcceptForm {
    privacyPolicy: boolean
    termsOfUse: boolean
}

export const AccountCreationAccept = () => {
    const methods = useForm<AccountCreationAcceptForm>({
        resolver: yupResolver<AccountCreationAcceptForm>(AccountCreationAcceptanceSchema),
        mode: "onBlur",
        defaultValues: {
            privacyPolicy: false,
            termsOfUse: false,
        },
    })
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { handleLangOption } = useChangeLanguage()
    const [isPrivacyPolicy, setIsPrivacyPolicy] = useState<boolean | undefined>(undefined)
    const [isTermsOfUse, setIsTermsOfUse] = useState<boolean | undefined>(undefined)
    const { mutateAsync: acceptPrivacyPolicy, isPending: isPrivacyPolicyAccepting } = acceptPrivacyPolicyMutation(false)
    const { mutateAsync: acceptTos, isPending: isTOSAccepting } = acceptTOSMutation(false)
    const { data: userData, refetch: refetchUserData } = useUserDataQueryFn()
    const { mutate: signOut } = useLogout()
    const theme = useTheme()
    const { handleSubmit, watch } = methods
    const isMobile = useIsMobile()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const privacyPolicyUrl = useFeatureValue(UserFlags.PrivacyPolicyUrl.toString(), "")
    const tosURL = useFeatureValue(UserFlags.TosUrl.toString(), "")

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "privacyPolicy") setIsPrivacyPolicy(v.privacyPolicy)
            if (name === "termsOfUse") setIsTermsOfUse(v.termsOfUse)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 1),
                gap: 20,
            })),
        [isMobile],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:cog"} />,
                label: translate("settings"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Settings)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                href: "/",
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                href: "/",
                color: theme.palette.error.main,
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
            },
        ]
        if (policiesPageUrl.length === 0) {
            toReturn.splice(2, 1)
        }

        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl])

    const onSubmit = useCallback(async () => {
        await acceptTos()
        await acceptPrivacyPolicy()
        await refetchUserData()
    }, [acceptTos, acceptPrivacyPolicy])

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (userData == null) return null

        return {
            firstName: userData.firstName,
            lastName: userData.lastName,
            fullName: `${userData?.firstName} ${userData?.lastName}`,
            email: userData.email ?? "",
            currency: userData.currency,
            availableCurrencies: [],
            onLanguageChange: handleLangOption,
            options,
            roleName: isWhitelabel ? undefined : userData?.roles.roleName,
            validUntil: isWhitelabel || userData?.roles.roleName === RoleName.FREE ? undefined : userData?.roles.to,
        }
    }, [userData, isWhitelabel, options, dispatch])

    if (!userData) return <LoadingScreen />

    return (
        <Page title={translate("acceptPage.title")}>
            <ContentStyle>
                <PaperStyle elevation={5}>
                    <Box display='flex' justifyContent='flex-end' gap={1} alignItems='center'>
                        <UserContextProvider userData={userDataConfig}>
                            <>
                                <LanguagePopover />
                                <AccountPopover />
                            </>
                        </UserContextProvider>
                    </Box>
                    <Typography fontWeight={"bold"} fontSize={20}>
                        {translate("last_step")}
                    </Typography>
                    <Typography fontWeight={"bold"} fontSize={14}>
                        {translate("acceptPage.content")}
                    </Typography>

                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Box display='flex' flexDirection='column'>
                            <RHFCheckbox
                                name={"privacyPolicy"}
                                label={
                                    <>
                                        {translate("agree_with")}{" "}
                                        <Link target='_blank' href={privacyPolicyUrl} style={{ textTransform: "lowercase" }}>
                                            {translate("privacy_policy")}
                                        </Link>
                                    </>
                                }
                            />
                            <RHFCheckbox
                                name={"termsOfUse"}
                                label={
                                    <>
                                        {translate("agree_with")}{" "}
                                        <Link target='_blank' href={tosURL} style={{ textTransform: "lowercase" }}>
                                            {translate("terms_and_conditions")}
                                        </Link>
                                    </>
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                                padding: 2,
                            }}
                        >
                            <LoadingButton
                                type='submit'
                                loading={isPrivacyPolicyAccepting || isTOSAccepting}
                                variant='contained'
                                color='primary'
                                sx={{ textTransform: "none", width: "100%" }}
                                disabled={
                                    isPrivacyPolicy === false ||
                                    isTermsOfUse === false ||
                                    isPrivacyPolicy === undefined ||
                                    isTermsOfUse === undefined ||
                                    isPrivacyPolicyAccepting ||
                                    isTOSAccepting
                                }
                            >
                                <Typography>{translate("continue")}</Typography>
                            </LoadingButton>
                        </Box>
                    </FormProvider>
                </PaperStyle>
            </ContentStyle>
        </Page>
    )
}
