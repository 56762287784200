import { array, boolean, number, object, string } from "yup"

export const AddNoteSchema = object().shape({
    noteId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    title: string().required("required").min(3, "min_3_characters"),
    text: string().required("required").min(3, "min_3_characters"),
    isVisibleToTenant: boolean().required("required"),
    files: array().required("required"),
})
