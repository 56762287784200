import { getProvidersWithoutExpenseThisMonth } from "../../../../queries"
import { Box, CircularProgress, useTheme } from "@mui/material"
import React from "react"
import CustomNomenclatureCard from "../../CustomNomenclatureCard"
import { useNavigate } from "react-router-dom"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { DateTime } from "luxon"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useLocales } from "rentzz"

const MobileProvidersWithoutExpenseThisMonthList = () => {
    const { data } = getProvidersWithoutExpenseThisMonth()
    const navigate = useNavigate()
    const { data: userProperties } = useUserPropertiesQuery()
    const theme = useTheme()
    const { translate } = useLocales()

    if (data == null)
        return (
            <Box display='flex' height={200} justifyContent='center' alignItems='center'>
                <CircularProgress size='2rem' />
            </Box>
        )

    if (data.length === 0) {
        return (
            <Box display='flex' flexDirection={"column"} height={200} gap={2} justifyContent='center' alignItems='center'>
                <CheckCircleIcon color={"primary"} sx={{ fontSize: 40 }} />
                {translate("no_providers_without_expense_this_month")}
            </Box>
        )
    }

    return (
        <>
            {data.map((item) => (
                <CustomNomenclatureCard
                    key={item.providerId}
                    onClick={() => navigate(`/properties/${item.propertyId}`)}
                    name={`${userProperties?.find((p) => p.id === item.propertyId)?.label} - ${item.providerName}`}
                    rightTitle={item.lastExpenseDate != null ? item.lastExpenseDate.toLocaleString(DateTime.DATE_SHORT) : "-"}
                    isLabel={false}
                    iconColor={theme.palette.primary.main}
                />
            ))}
        </>
    )
}

export default MobileProvidersWithoutExpenseThisMonthList
