import React, { useMemo } from "react"
import { DateTime } from "luxon"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import { Box } from "@mui/material"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import DeleteIcon from "@mui/icons-material/Delete"
import { useBankStatementDetailsMobile } from "../../../queries/bank-statements/bankStatements"
import { useDispatch, useSelector } from "../../../redux/store"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { BankStatementStatus } from "../../../queries/bank-statements/types"
import { renderActionSheetInfo } from "../utils"
import { useNavigate } from "react-router-dom"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"

export default function BankStatementDetailsSheet() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentStatement } = useBankStatementDetailsMobile(editingItem?.id)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentStatement == null) return []
        return [
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_transactions",
                onClick: () => {
                    navigate(`/bank-statements/${editingItem?.id}`)
                    dispatch(setEditingItem(undefined))
                    dispatch(setModalOpen(undefined))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "delete",
                hidden: currentStatement?.status === BankStatementStatus.Finished,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteBankStatement))
                },
            },
        ]
    }, [currentStatement, dispatch, theme, editingItem])

    const meterHeaderColor = useMemo(() => {
        return currentStatement?.status === BankStatementStatus.Finished ? theme.palette.success.main : theme.palette.warning.main
    }, [currentStatement, theme])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.BankStatementDetailsSheet}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={currentStatement == null}
            title={translate("bank_statement_sheet_title")}
            options={options}
            subtitle={`${currentStatement?.periodStart.toLocaleString(DateTime.DATE_SHORT)}-${currentStatement?.periodEnd.toLocaleString(DateTime.DATE_SHORT)}`}
            headerColor={meterHeaderColor}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:date",
                    `${translate("holder")}: ${currentStatement?.holder}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {renderActionSheetInfo(
                    "mdi:date",
                    `${translate("statement_period")}: ${currentStatement?.periodStart.toLocaleString(DateTime.DATE_SHORT)}-${currentStatement?.periodEnd.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
