import { CustomDialog, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { AddIncomeRequest } from "./types"
import { PropertyIncome } from "../../../redux/slices/AddProperty"
import AddIncomeContent from "./AddIncomeContent"
import { AppModals } from "../../../redux/slices/App"
import { useSelector } from "../../../redux/store"
import { PropertyExpense } from "../../../queries/expenses"

interface AddIncomeDialogProps {
    isVisible: boolean
    onClose: () => void
    defaultValues: PropertyIncome | PropertyExpense | undefined | null
    onSave: (data: AddIncomeRequest) => Promise<void>
    isLoading: boolean
}

const AddIncomeDialog = ({ isVisible, onClose, defaultValues, isLoading, onSave }: AddIncomeDialogProps) => {
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()

    return (
        <CustomDialog
            title={translate(translate(modalOpen === AppModals.EditIncome ? "income-table.editIncome" : "income-table.addIncome"))}
            open={isVisible}
            onClose={onClose}
            maxWidth={"sm"}
            showCloseIcon
            titleAlign={"center"}
            isFullScreen={isMobile}
        >
            <AddIncomeContent onClose={onClose} defaultValues={defaultValues} isLoading={isLoading} onSave={onSave} />
        </CustomDialog>
    )
}
export default AddIncomeDialog
