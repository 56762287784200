import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useAllInvoicingConfigurationsQuery } from "../../../../../queries/invoicing-configuration"
import AddOrEditInvoicingConfigurationForm from "../../../../../sections/nomenclature/invoicing-configuration/configurations/AddOrEditInvoicingConfigurationForm"

export default function AddOrEditInvoicingConfigurationGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: allInvoicingConfigurations } = useAllInvoicingConfigurationsQuery()

    const currentInvoicingConfiguration = useMemo(
        () => allInvoicingConfigurations?.find((section) => section.id === editingItem?.id),
        [editingItem, allInvoicingConfigurations],
    )

    const handleClose = useCallback(() => {
        if (isMobile && modalOpen === AppModals.EditInvoicingConfiguration) {
            dispatch(setModalOpen(AppModals.InvoicingConfigurationDetails))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, isMobile])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && (modalOpen === AppModals.AddInvoicingConfiguration || modalOpen === AppModals.EditInvoicingConfiguration)}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(currentInvoicingConfiguration ? "edit_invoicing_configuration" : "add_invoicing_configuration")}
            >
                <AddOrEditInvoicingConfigurationForm onClose={handleClose} defaultValues={currentInvoicingConfiguration} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddInvoicingConfiguration || modalOpen === AppModals.EditInvoicingConfiguration)}
                onClose={handleClose}
                isLoading={false}
                title={translate(currentInvoicingConfiguration ? "edit_invoicing_configuration" : "add_invoicing_configuration")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditInvoicingConfigurationForm onClose={handleClose} defaultValues={currentInvoicingConfiguration} />
            </DetailsSheet>
        </>
    )
}
