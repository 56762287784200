import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import AddInvoicingConfigurationAccessForm from "../../../sections/nomenclature/invoicing-configuration/configurations/AddInvoicingConfigurationAccessForm"

export default function AddInvoicingConfigurationAccess() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen } = useSelector((state) => state.appState)

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                title={translate("add_group_access")}
                open={!isMobile && modalOpen === AppModals.AddInvoicingConfigurationAccess}
                onClose={closeModal}
                maxWidth={"xs"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddInvoicingConfigurationAccessForm onClose={closeModal} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("add_group_access")}
                isOpen={isMobile && modalOpen === AppModals.AddInvoicingConfigurationAccess}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddInvoicingConfigurationAccessForm onClose={closeModal} />
                </Box>
            </DetailsSheet>
        </>
    )
}
