import { FormProvider, RHFDayPicker, RHFUnitInput, useIsMobile, useLocales } from "rentzz"
import { Box, Button, Typography } from "@mui/material"
import * as React from "react"
import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentDetailsSchema } from "../../../../validations/paymentPeriod"
import { useUserDataQueryFn } from "../../../../queries/userData"
import { useCurrencyQueryFn } from "../../../../queries/currency"
import { RentDetails } from "../../../types/AddTenant"

interface PaymentProps {
    onBack: () => void
    onNext: (rent: RentDetails) => void
    defaultValues?: RentDetails
}

export default function Payment({ onNext, onBack, defaultValues }: PaymentProps) {
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const isMobile = useIsMobile()

    const methods = useForm<RentDetails>({
        resolver: yupResolver<RentDetails>(RentDetailsSchema),
        mode: "onChange",
    })
    const { handleSubmit, trigger, watch, reset } = methods

    useEffect(() => {
        reset(
            defaultValues ?? {
                paymentPeriod: [],
                currencyId: user?.currency.id,
                securityDepositCurrencyId: user?.currency.id,
            },
        )
    }, [reset, user])

    useEffect(() => {
        const subscription = watch(() => {
            trigger("paymentPeriod")
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = useCallback(
        (data: RentDetails) => {
            onNext(data)
        },
        [onNext],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' width='100%' marginTop={4} justifyContent='space-around' flexDirection={"column"} alignItems={"center"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} marginBottom={2} width={"100%"}>
                    <RHFUnitInput
                        unitName={"currencyId"}
                        unitType={"currency"}
                        name='value'
                        type='number'
                        size='small'
                        data={currencies ?? []}
                        label={translate("tenant-data.price")}
                        required
                        fullWidth={isMobile}
                    />
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName={"securityDepositCurrencyId"}
                        unitType={"currency"}
                        name='securityDeposit'
                        type='number'
                        size='small'
                        label={translate("tenant-data.securityDeposit")}
                        fullWidth={isMobile}
                    />
                </Box>

                <Box display='flex' gap={2} flexDirection={"column"} width={"100%"}>
                    <Box display='flex' flexDirection={"column"} alignItems={"center"} gap={2}>
                        <Typography alignSelf={"center"} marginTop={1} marginBottom={-4} fontSize={14} fontWeight={"bold"}>
                            {translate("pick_a_payment_period")} *
                        </Typography>
                        <RHFDayPicker name={"paymentPeriod"} defaultValue={[]} />
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: -3, gap: 2 }} flexDirection={isMobile ? "column-reverse" : "row"}>
                <Button color={"primary"} onClick={onBack}>
                    {translate("back")}
                </Button>
                <Button color={"primary"} type='submit' variant='contained'>
                    {translate("next")}
                </Button>
            </Box>
        </FormProvider>
    )
}
