import { Box, Button, Typography } from "@mui/material"
import { AppContext, ExpenseAssignee, Iconify, LightTooltip, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { PropertyExpense } from "../../../queries/expenses"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import { PaymentType, PropertyIncome, PropertyRevision, TenantRevision } from "../../../redux/slices/AddProperty"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { RoleName, UserBankAccount, UserFlags, useTenantRentingPeriodsQuery } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { DateTime } from "luxon"
import RoleLockedButton from "../../../components/RoleLockedButton"

type ExpenseHeaderProps = {
    currentItem: PropertyExpense
    itemType: "expense"
}

type IncomeHeaderProps = {
    currentItem: PropertyIncome
    itemType: "income"
}

type ProviderHeaderProps = {
    currentItem: { name: string; id: number }
    itemType: "provider"
}

type PropertiesBankAccountHeaderProps = {
    currentItem: UserBankAccount
    itemType: "bankAccount"
}

type ObservationHeaderProps = {
    currentItem: PropertyRevision | TenantRevision
    itemType: "observation"
}

type HeaderProps = ExpenseHeaderProps | IncomeHeaderProps | ProviderHeaderProps | PropertiesBankAccountHeaderProps | ObservationHeaderProps

const Header = ({ currentItem, itemType }: HeaderProps) => {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Expenses)
    const isPaymentOnlineEnabled = useFeatureIsOn(UserFlags.ExpenseOnlinePayment.toString())
    const showLateFeesGenerateButton = useFeatureIsOn(UserFlags.ShowLateFeesGenerateButton.toString())
    const { data: tenantRentingPeriods } = useTenantRentingPeriodsQuery()

    const currentTenantRentingPeriod = useMemo(
        () => tenantRentingPeriods?.find((t) => t.rentingPeriodId === currentRentingPeriodId),
        [tenantRentingPeriods, currentRentingPeriodId],
    )

    const handleAdd = useCallback(() => {
        dispatch(setEditingItem({ id: currentItem.id }))
        if (itemType === "expense") {
            if (context === AppContext.Owner) {
                dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsOwner))
            } else {
                if (isPaymentOnlineEnabled) {
                    dispatch(setModalOpen(AppModals.TenantPayment))
                } else {
                    dispatch(setModalOpen(AppModals.LinkExpenseToIncomeAsTenant))
                }
            }
        }
        if (itemType === "income") {
            dispatch(setModalOpen(AppModals.AddExpense))
        }
        if (itemType === "bankAccount") {
            dispatch(setModalOpen(AppModals.EditBankAccount))
            dispatch(setEditingItem({ id: currentItem.id, isAssociatingProperties: true }))
        }
        if (itemType === "income") {
            dispatch(setModalOpen(AppModals.AddExpense))
        }
        if (itemType === "observation") {
            dispatch(setModalOpen(AppModals.AddObservation))
            dispatch(setEditingItem({ revisionId: currentItem.id }))
        }
    }, [dispatch, context, itemType, isPaymentOnlineEnabled])

    const isIconVisible = useMemo(() => {
        if (itemType === "provider") return false
        if (context === AppContext.Tenant) return true
        if (itemType === "expense") return canWrite
        if (itemType === "bankAccount") return true
        if (itemType === "observation") return true
    }, [context, itemType, canWrite])

    const panelTitle = useMemo(() => {
        if (itemType === "expense") {
            if (context === AppContext.Tenant) return translate("payments")
            return translate("income")
        }
        if (itemType === "provider") return currentItem.name
        if (itemType === "income") return translate("assigned_expenses")
        if (itemType === "observation") return translate("observations")
        if (itemType === "bankAccount") return translate("assigned_properties")
    }, [itemType, context, currentItem])

    const lateFeesTooltipTitle = useMemo(() => {
        const item = currentItem as PropertyExpense
        if (item.dueDate >= DateTime.now().startOf("day")) {
            return "late_fees_not_overdue"
        }
        if (item.paymentType === PaymentType.Fees) {
            return "late_fees_cant_generate_late_fees"
        }
        return ""
    }, [])

    const isAddButtonVisible = useMemo(() => {
        if (
            !currentTenantRentingPeriod?.acceptPartialPayments &&
            (currentItem as PropertyExpense).propertyIncomesCount >= 1 &&
            context === AppContext.Tenant
        )
            return false
        if (itemType === "observation" && context === AppContext.Tenant) return false
        return !!isIconVisible
    }, [currentTenantRentingPeriod, currentItem, isIconVisible, context])

    const addTitle = useMemo(() => {
        if (itemType === "bankAccount") return "associate_a_property"
        if (itemType === "observation") return "add_observation"
        if (context === AppContext.Tenant) return "payment.addPayment"
        if (context === AppContext.Owner) return "income-table.addIncome"
    }, [context, itemType])

    return (
        <Box display={"flex"} justifyContent={"space-between"} alignItems='center' gap={2} flex={1}>
            <Box>
                <Typography variant='h5' color={theme.palette.grey.A700}>
                    {panelTitle}
                </Typography>
                {itemType === "expense" && (
                    <Box display='flex' flexDirection='column'>
                        <Typography variant='body2' color={theme.palette.grey.A700}>
                            {currentItem.name}
                        </Typography>
                        <Typography variant='caption' color={theme.palette.grey.A700} sx={{ whiteSpace: "pre-line" }}>
                            {currentItem.description}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box display='flex' flexDirection='row' gap={2}>
                {itemType === "expense" && canWrite && showLateFeesGenerateButton && currentItem.assignee === ExpenseAssignee.TENANT && (
                    <LightTooltip title={lateFeesTooltipTitle != "" ? translate(lateFeesTooltipTitle) : ""} arrow>
                        <span>
                            <RoleLockedButton
                                variant='text'
                                buttonText={<Typography>{translate("add_late_fees_expense")}</Typography>}
                                isDisabled={lateFeesTooltipTitle != ""}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.GenerateLateFeesExpense))
                                    dispatch(setEditingItem({ expenseId: currentItem.id }))
                                }}
                                blockedRoles={[RoleName.FREE, RoleName.SILVER, RoleName.BRONZE]}
                                startIcon={<Iconify icon={"mdi:invoice-schedule"} sx={{ height: DESKTOP_ICON_SIZE, width: DESKTOP_ICON_SIZE }} />}
                            />
                        </span>
                    </LightTooltip>
                )}

                {isAddButtonVisible && (
                    <Button
                        startIcon={<Iconify icon={"mdi:add"} sx={{ height: DESKTOP_ICON_SIZE, width: DESKTOP_ICON_SIZE }} />}
                        variant='text'
                        color={"primary"}
                        onClick={handleAdd}
                        size='small'
                    >
                        <Typography>{translate(addTitle)}</Typography>
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default Header
