import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDataGrid, IKanbanTask, LoadingIcon, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"
import { useTenantTasksQueryFn } from "../../../queries/kanban"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn } from "../../../queries/userData"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { Typography } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import DeleteIcon from "@mui/icons-material/Delete"
import { seenByTenantMutation } from "../../../mutations/kanban"
import CustomToolbar from "../../../components/CustomToolbar"

export default function DesktopTenantTasksList() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: rentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: user } = useUserDataQueryFn()
    const currentRentingPeriod = useMemo(() => rentingPeriods?.find((r) => r.propertyId === currentPropertyId), [rentingPeriods, currentPropertyId])
    const { data: tasks, isLoading: areTasksLoading } = useTenantTasksQueryFn(currentRentingPeriod?.rentingPeriodId)
    const { mutateAsync: seenByTenant } = seenByTenantMutation()

    const tasksColumns = useMemo(() => {
        const columns: GridColDef<IKanbanTask>[] = [
            {
                field: "name",
                type: "string",
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                flex: 0.6,
                headerName: translate("name"),
            },
            {
                field: "description",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("task_description"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 25)}</Typography>,
                        tooltipText: v.value.length > 25 ? v.value : "",
                    }),
            },
            {
                field: "createdBy",
                type: "string",
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                flex: 0.6,
                headerName: translate("createdBy"),
            },

            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 130,
            disableReorder: true,
            flex: 0.4,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<IKanbanTask>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteTask))
                                dispatch(setEditingItem({ taskId: params.row.id }))
                            }}
                            label={"delete"}
                            sx={{ p: 1 }}
                            disabled={!params.row.canEdit}
                        />
                    ),
                    tooltipText: !params.row.canEdit ? translate("can_not_delete_task") : "",
                    key: "delete",
                }),
            ],
        }),
        [translate, context, user],
    )

    const handleDetails = useCallback(
        async (task: IKanbanTask) => {
            dispatch(setModalOpen(AppModals.TaskDetails))
            dispatch(setEditingItem({ taskId: task.id, rentingPeriodId: currentRentingPeriod?.rentingPeriodId }))

            if (task.rentingPeriodId == null || task.id == null) return
            await seenByTenant({
                rentingPeriodId: task.rentingPeriodId,
                taskId: task.id,
            })
        },
        [dispatch, context, seenByTenant],
    )

    const infoColumn = useMemo(
        (): GridColDef => ({
            field: "____",
            type: "actions",
            headerClassName: "hideRightSeparator",
            width: 50,
            headerName: "",
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    key: "see_comments",
                    tooltipText: translate("details"),
                    element: (
                        <GridActionsCellItem
                            label={"Details"}
                            onClick={() => handleDetails(params.row)}
                            icon={<RemoveRedEyeIcon color={"primary"} sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                        />
                    ),
                }),
            ],
        }),
        [translate, currentPropertyId],
    )

    if (areTasksLoading) return <LoadingIcon />

    return (
        <>
            <CustomToolbar onAddClick={() => dispatch(setModalOpen(AppModals.AddTask))} buttonText={"add_task"} />
            <CustomDataGrid
                gridId={"DesktopTenantTasksList"}
                columns={tasksColumns}
                rows={tasks ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage icon={""} noDataText={"no_tasks"} />,
                }}
                infoColumn={[infoColumn]}
                idKey={"id"}
                actionsColumn={[actionsColumn]}
            />
        </>
    )
}
