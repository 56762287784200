import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, Iconify, useLocales } from "rentzz"
import React, { SyntheticEvent, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { DESKTOP_ICON_SIZE, MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useAllUserInvoicingCompanies } from "../../../../queries/invoicing-configuration"
import { useSnackbar } from "notistack"
import { updateInvoicingConfigurationCompany } from "../../../../mutations/invoicingConfiguration"
import { Refresh } from "@mui/icons-material"

export default function InvoicingCompanyDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: allInvoicingCompanies } = useAllUserInvoicingCompanies()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { enqueueSnackbar } = useSnackbar()
    const { mutate } = updateInvoicingConfigurationCompany()

    const currentInvoicingCompany = useMemo(
        () => allInvoicingCompanies?.find((section) => section.id === editingItem?.invoiceCompanyId),
        [allInvoicingCompanies, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        if (currentInvoicingCompany == null) return []

        return [
            {
                icon: <Iconify icon={"mdi:invoice-add"} color={theme.palette.primary.main} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "invoicing",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.InvoiceType))
                    dispatch(
                        setEditingItem({
                            invoiceCompanyId: currentInvoicingCompany.id,
                            invoicingConfigurationId: currentInvoicingCompany.invoicingConfigurationId,
                        }),
                    )
                },
            },
            {
                icon: <Refresh sx={{ fontSize: DESKTOP_ICON_SIZE }} color='primary' />,
                title: "grid_actions.update_company",
                onClick: () => {
                    mutate(
                        {
                            invoicingCompanyId: currentInvoicingCompany.id,
                            invoicingConfigurationId: currentInvoicingCompany.invoicingConfigurationId,
                        },
                        {
                            onSuccess: () => {
                                enqueueSnackbar(translate("update_company_will_start_soon"))
                            },
                        },
                    )
                },
            },
        ]
    }, [currentInvoicingCompany, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InvoicingCompanyDetailsSheet}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentInvoicingCompany?.name}
            subtitle={currentInvoicingCompany?.cif}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-companies.name")}: ${currentInvoicingCompany?.name}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-companies.cif")}: ${currentInvoicingCompany?.cif}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentInvoicingCompany?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
