import { Box, Divider, Grid } from "@mui/material"
import { Iconify, useIsMobile, useLocales } from "rentzz"
import { getExpenseTypeIcon } from "../../sections/expenses/utils"
import { PaymentSubtype, PaymentType } from "../../redux/slices/AddProperty"
import { sumBy } from "lodash"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { UnpaidExpenseStatus, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { useFeatureValue } from "@growthbook/growthbook-react"

export interface UnpaidExpensesGroupedItemsProps {
    unpaidExpensesStatus: UnpaidExpenseStatus[]
}

const UnpaidExpensesGroupedItems = ({ unpaidExpensesStatus }: UnpaidExpensesGroupedItemsProps) => {
    const subtypes = Object.values(PaymentSubtype).filter((key) => !isNaN(Number(key)))
    const intl = useIntl()
    const { data: userData } = useUserDataQueryFn()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const expenseOverrideHeader = useFeatureValue<string | null>(UserFlags.ExpenseHeaderOverride, null)

    const formatNumber = useCallback(
        (amount: number) => {
            return intl.formatNumber(amount, {
                style: "currency",
                currency: userData?.currency.code,
            })
        },
        [intl, userData],
    )

    return (
        <Box minWidth={isMobile ? undefined : 500}>
            <Grid container rowGap={1}>
                {!isMobile && (
                    <Grid item xs={12}>
                        {translate(`expense_filter_options${expenseOverrideHeader ? "-" + expenseOverrideHeader : ""}-1`)}
                    </Grid>
                )}
                {subtypes.map((subtype) => (
                    <Grid item key={subtype.toString()} xs={isMobile ? 12 : 6}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' px={2}>
                            <Box display='flex' flexDirection='row' gap={1}>
                                <Iconify icon={getExpenseTypeIcon(PaymentType.Expense, subtype as PaymentSubtype)} />
                                {translate(`payment-subtype-${subtype}`)}
                            </Box>
                            <Box display='flex' flexDirection='row' gap={1}>
                                {formatNumber(
                                    sumBy(
                                        unpaidExpensesStatus.filter((e) => e.paymentType === PaymentType.Expense && e.paymentSubtype === subtype),
                                        "amount",
                                    ),
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
                {!isMobile && (
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                )}
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' px={2}>
                        <Box display='flex' flexDirection='row' gap={1}>
                            <Iconify icon={getExpenseTypeIcon(PaymentType.Rent, PaymentSubtype.None)} />
                            {translate("income-table.incomeType-1")}
                        </Box>
                        <Box display='flex' flexDirection='row' gap={1}>
                            {formatNumber(
                                sumBy(
                                    unpaidExpensesStatus.filter((e) => e.paymentType === PaymentType.Rent),
                                    "amount",
                                ),
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' px={2}>
                        <Box display='flex' flexDirection='row' gap={1}>
                            <Iconify icon={getExpenseTypeIcon(PaymentType.SecurityDeposit, PaymentSubtype.None)} />
                            {translate("income-table.incomeType-2")}
                        </Box>
                        <Box display='flex' flexDirection='row' gap={1}>
                            {formatNumber(
                                sumBy(
                                    unpaidExpensesStatus.filter((e) => e.paymentType === PaymentType.SecurityDeposit),
                                    "amount",
                                ),
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' px={2}>
                        <Box display='flex' flexDirection='row' gap={1}>
                            <Iconify icon={getExpenseTypeIcon(PaymentType.Fees, PaymentSubtype.None)} />
                            {translate("income-table.incomeType-3")}
                        </Box>
                        <Box display='flex' flexDirection='row' gap={1}>
                            {formatNumber(
                                sumBy(
                                    unpaidExpensesStatus.filter((e) => e.paymentType === PaymentType.Fees),
                                    "amount",
                                ),
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' px={2}>
                        <Box display='flex' flexDirection='row' gap={1}>
                            <Iconify icon={getExpenseTypeIcon(PaymentType.Damages, PaymentSubtype.None)} />
                            {translate("income-table.incomeType-4")}
                        </Box>
                        <Box display='flex' flexDirection='row' gap={1}>
                            {formatNumber(
                                sumBy(
                                    unpaidExpensesStatus.filter((e) => e.paymentType === PaymentType.Damages),
                                    "amount",
                                ),
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default UnpaidExpensesGroupedItems
