import { Alert, Box, CircularProgress, MenuItem } from "@mui/material"
import { PropertyIncome, PropertyIncomePaymentMethod } from "../../../redux/slices/AddProperty"
import { RHFDatePicker, RHFSelect, RHFUnitInput, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { getReferenceLabelTitle, isReferenceDisabled, renderMenuItem } from "../utils"
import { getOwnerMaxSize } from "../../../utils/actions"
import React, { useEffect, useMemo } from "react"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUserDataQueryFn } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"
import { AppForm, useFormResetter } from "../../../hooks/useFormResetter"
import { AddIncomeRequest } from "./types"
import { useFormContext } from "react-hook-form"
import { useRentingPeriodDetails } from "../../../queries/tenants"
import { DateTime } from "luxon"
import { useInfiniteExpensesQueryFn } from "../../../queries/expenses"
import RHFLazyLoadSelect from "../../../components/RHFLazyLoadSelect"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useTheme } from "@mui/material/styles"
import { AppModals } from "../../../redux/slices/App"

interface Props {
    defaultValues?: PropertyIncome
}

export default function AddIncomeTypeExpenseForm({ defaultValues }: Props) {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { currentPropertyId, errorMessage, currentRentingPeriodId, modalOpen } = useSelector((state) => state.appState)
    const { data: currencies } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { resetFields } = useFormResetter<AddIncomeRequest>()
    const { setValue, watch, reset } = useFormContext<AddIncomeRequest>()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editableProperties } = usePermissions(PermissionType.Incomes)

    const {
        data: expenses,
        hasNextPage,
        fetchNextPage,
        isFetching,
        isLoading,
    } = useInfiniteExpensesQueryFn(false, [
        { field: "assignee", sort: "desc" },
        { field: "date", sort: "desc" },
    ])

    const references = useMemo(
        () =>
            expenses?.pages
                .flatMap((r) => r.items)
                ?.map((r) =>
                    renderMenuItem(
                        isReferenceDisabled(r, true) ? translate("expense_assigned_to_owner") : "",
                        r.name ?? "",
                        {
                            value: r.id,
                        },
                        translate,
                        `${translate("due_date")}: ${r.dueDate.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme,
                    ),
                ),

        [translate, expenses],
    )

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "referenceId") {
                const reference = (expenses?.pages.flatMap((r) => r.items) ?? []).find((r) => r.id.toString() === v.referenceId?.toString())
                if (reference) {
                    setValue("value", reference.value, { shouldValidate: true })
                    setValue("currencyId", reference.currencyId, { shouldValidate: true })
                } else if (expenses != null && !isLoading) {
                    resetFields(AppForm.AddIncome, "referenceId")
                    setValue("currencyId", userData?.currency.id ?? 1, { shouldValidate: true })
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, resetFields, setValue, userData, isLoading, expenses])

    useEffect(() => {
        let newFormValues: unknown | undefined

        if (defaultValues) {
            newFormValues = {
                date: defaultValues.date.toISO() ?? undefined,
                files: [],
                referenceId: modalOpen === AppModals.LinkExpenseToIncomeAsOwner ? defaultValues?.id : defaultValues.associatedExpenses?.at(0)?.id,
                paymentMethod: defaultValues?.paymentMethod ?? PropertyIncomePaymentMethod.Bank,
                currencyId: defaultValues?.currencyId,
                value: defaultValues?.value,
                id: modalOpen === AppModals.LinkExpenseToIncomeAsOwner ? undefined : defaultValues?.id,
            }
        } else {
            newFormValues = {
                currencyId: userData?.currency.id,
                date: DateTime.now().toISO() ?? undefined,
                paymentMethod: PropertyIncomePaymentMethod.Bank,
                files: [],
            }
        }

        reset(newFormValues as AddIncomeRequest)
    }, [defaultValues, userData, editableProperties, modalOpen])

    if (isLoading)
        return (
            <Box display='flex' width='100%' height={100} justifyContent='center' alignItems='center'>
                <CircularProgress size={"1.5rem"} />
            </Box>
        )

    return (
        <>
            <Box display='flex' flexDirection='column' gap={2} marginY={2} justifyContent={"center"}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <Box display='flex' width='100%' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFLazyLoadSelect
                            name='referenceId'
                            label={translate(getReferenceLabelTitle(true))}
                            size='small'
                            required
                            disabled={defaultValues != null}
                            hasNext={hasNextPage}
                            isLoading={isFetching}
                            fetchNext={fetchNextPage}
                        >
                            {references?.length === 0 ? (
                                <MenuItem style={{ textTransform: "none" }} disabled>
                                    {translate("no_expenses_found_for_property")}
                                </MenuItem>
                            ) : (
                                references
                            )}
                        </RHFLazyLoadSelect>
                    </Box>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName='currencyId'
                        unitType='currency'
                        name='value'
                        size='small'
                        label={translate("amount")}
                        required
                    />
                    <RHFSelect name='paymentMethod' label={translate("income-table.paymentMethod")} size='small'>
                        <MenuItem value={PropertyIncomePaymentMethod.Cash}>{translate("income-table.paymentMethod-0")}</MenuItem>
                        <MenuItem value={PropertyIncomePaymentMethod.Bank}>{translate("income-table.paymentMethod-1")}</MenuItem>
                    </RHFSelect>
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFDatePicker
                        name='date'
                        disableFuture
                        inputProps={{ fullWidth: true, size: "small" }}
                        label={translate("date")}
                        minDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveInDate : undefined}
                        maxDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveOutDate : undefined}
                    />
                </Box>
            </Box>
            {defaultValues == null && (
                <RHFUpload
                    name={"files"}
                    multiple
                    maxSize={getOwnerMaxSize(
                        editableProperties,
                        userData?.features?.maxPropertySize,
                        currentPropertyId ?? editableProperties?.at(0)?.id,
                    )}
                    icon={"mdi:file-document-plus-outline"}
                />
            )}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
        </>
    )
}
