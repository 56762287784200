import { useTheme } from "@mui/material/styles"
import { CustomDataGrid, Iconify, IKanbanSection, useLocales } from "rentzz"
import { useDispatch } from "../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowModel, GridRowOrderChangeParams, GridRowParams } from "@mui/x-data-grid-pro"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"
import { useAllSectionsQueryFn } from "../../../queries/kanban"
import { updateUserSectionMutation } from "../../../mutations/kanban"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"
import CustomRoleBlockedToolbar from "../../../components/CustomRoleBlockedToolbar"
import LastSectionForm from "./LastSectionForm"

export default function DesktopSectionsList() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { data: allSections, isLoading: areAllSectionsLoading } = useAllSectionsQueryFn()
    const { mutateAsync: updateSection } = updateUserSectionMutation()
    const { data: user } = useUserDataQueryFn()

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            minWidth: 80,
            flex: 0.1,
            disableReorder: true,
            headerName: translate("tenant-data.actions"),
            getActions: (params: GridRowParams<IKanbanSection>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.taskSectionId }))
                                dispatch(setModalOpen(AppModals.EditSection))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: !params.row.canEdit || user?.roles == null,
                }),
                ActionWithTooltip({
                    key: "delete",
                    tooltipText: translate("delete"),
                    hidden: !params.row.canEdit,
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteSection))
                                dispatch(setEditingItem({ id: params.row.taskSectionId }))
                            }}
                            label={translate("delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    element: <Iconify icon={"mdi:account-lock"} color={theme.palette.error.main} />,
                    tooltipText: translate("only_read_no_write"),
                    key: "unavailable",
                    hidden: params.row.canEdit,
                }),
            ],
        }),
        [translate, dispatch],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<IKanbanSection>[] = [
            {
                field: "name",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "order",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("section_order"),
            },
            {
                field: "isFinal",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("is_last_sections"),
                renderCell: ({ value, row }) => {
                    return <LastSectionForm isFinal={value} sectionId={row.taskSectionId} canEdit={row.canEdit} />
                },
            },
        ]

        return columns
    }, [translate])

    const updateRowPosition = useCallback(
        (initialIndex: number, newIndex: number, rows: Array<GridRowModel>): Promise<any> => {
            return new Promise((resolve) => {
                setTimeout(
                    () => {
                        const rowsClone = [...rows]
                        const row = rowsClone.splice(initialIndex, 1)[0]
                        rowsClone.splice(newIndex, 0, row)
                        resolve(rowsClone)
                    },
                    Math.random() * 500 + 100,
                )
            })
        },
        [setTimeout],
    )

    const handleRowOrderChange = useCallback(
        async (params: GridRowOrderChangeParams) => {
            if (allSections == null) return
            const newRows = (await updateRowPosition(params.oldIndex, params.targetIndex, allSections)) as IKanbanSection[]
            newRows.forEach((row, index) => {
                updateSection({
                    sectionId: row.taskSectionId,
                    operations: [{ op: "replace", path: "/order", value: index }],
                })
            })
        },
        [allSections, updateSection, updateSection],
    )

    return (
        <>
            <CustomRoleBlockedToolbar
                onAddClick={() => dispatch(setModalOpen(AppModals.AddSection))}
                buttonText={"add_section"}
                blockedRoles={[RoleName.FREE]}
            />
            <CustomDataGrid
                columns={columns}
                gridId='DesktopSectionsList'
                actionsColumn={[actionsColumn]}
                rows={allSections ?? []}
                infoColumn={[]}
                idKey={"taskSectionId"}
                getRowId={(row) => row.taskSectionId}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_sections"} />,
                }}
                toolbarProps={{
                    onAddClick: () => dispatch(setModalOpen(AppModals.AddSection)),
                }}
                isLoading={areAllSectionsLoading}
                rowHeight={50}
                handleRowOrderChange={handleRowOrderChange}
            />
        </>
    )
}
