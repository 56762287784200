import {
    DashboardContext,
    Iconify,
    LayoutOption,
    LoadingScreen,
    Page,
    PromoCodeRequest,
    SubscriptionsList,
    useLocales,
    UserContextProvider,
} from "rentzz"
import { getSubscriptionQuery, Subscription } from "../../queries/subscription"
import { useCurrencyQueryFn } from "../../queries/currency"
import React, { useCallback, useMemo, useState } from "react"
import { AppModals, setContext, setCurrentPropertyId, setModalOpen, setShowSubscriptionPage } from "../../redux/slices/App"
import { UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { useDispatch, useSelector } from "../../redux/store"
import { checkCodeMutation, getSubscriptionCheckoutLinkMutation, useLogout } from "../../mutations/user"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useTheme } from "@mui/material/styles"

export default function SubscriptionPage() {
    const theme = useTheme()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isPromoCodeOn = useFeatureIsOn("promo-code")
    const { showSubscriptionPage } = useSelector((state) => state.appState)
    const { data: subscriptions } = getSubscriptionQuery()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: user, refetch: refetchUserData } = useUserDataQueryFn()
    const { mutate: signOut } = useLogout()
    const { mutate: subscriptionCheckout, isPending: isCheckoutLoading } = getSubscriptionCheckoutLinkMutation()
    const { mutate: checkPromoCode, isPending: isCheckPromoCodeLoading, isError: isCheckPromoCodeError, data } = checkCodeMutation()
    const [currentPromoCode, setCurrentPromoCode] = useState<string | undefined>(undefined)
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")

    const handleOnGoBack = useCallback(() => {
        dispatch(setShowSubscriptionPage(false))
    }, [dispatch])

    const subscriptionsWithCurrency = useCallback(() => {
        if (subscriptions == null) return []

        const parsed: Subscription[] = subscriptions.map((subscription) => ({
            ...subscription,
            prices: subscription.prices.map((price) => ({
                ...price,
                currency: currencies?.find((currency) => currency.id === price.currencyId)?.code ?? "",
            })),
        }))
        return parsed
    }, [subscriptions, currencies])

    const onPlanChoose = useCallback(
        async (id: string) => {
            subscriptionCheckout(
                { id, promotionalCode: currentPromoCode },
                {
                    onSuccess: (trialLink) => {
                        if (trialLink !== "") {
                            window.open(trialLink, "_self")
                        } else {
                            refetchUserData()
                        }
                    },
                },
            )
        },
        [subscriptionCheckout, refetchUserData, currentPromoCode],
    )

    const handleCheckPromoCode = useCallback(
        async (data: PromoCodeRequest) => {
            checkPromoCode(data, {
                onSuccess: () => {
                    setCurrentPromoCode(data.promoCode)
                },
            })
        },
        [checkPromoCode],
    )

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:cog"} />,
                label: translate("settings"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Settings)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:account-reactivate"} />,
                label: translate("change_context"),
                href: "/",
                onClick: () => dispatch(setContext(undefined)),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                href: "/",
                onClick: () => window.open(policiesPageUrl),
                color: theme.palette.primary.dark,
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                href: "/",
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    signOut()
                },
            },
        ]
        if (policiesPageUrl.length === 0) {
            toReturn.splice(3, 1)
        }
        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl])

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (user == null) return null

        return {
            firstName: user.firstName,
            lastName: user.lastName,
            fullName: `${user?.firstName} ${user?.lastName}`,
            email: user.email ?? "",
            currency: user.currency,
            availableCurrencies: [],
            onLanguageChange: console.log,
            options,
            roleName: user?.roles.roleName,
            validUntil: user?.roles.to,
            canTrial: user.canTrial,
        }
    }, [user, options, dispatch])

    if (subscriptions == null || currencies == null || user == null) {
        return <LoadingScreen />
    }

    return (
        <Page title={translate("subscriptions")} display={"flex"} justifyContent={"center"} alignItems={"center"} p={3} flexDirection={"column"}>
            <UserContextProvider userData={userDataConfig}>
                <SubscriptionsList
                    subscriptionExpired={user.isSubscriptionExpired}
                    subscriptions={subscriptionsWithCurrency()}
                    onChoose={onPlanChoose}
                    isButtonLoading={isCheckoutLoading}
                    showSubscriptionPage={showSubscriptionPage}
                    onGoBack={handleOnGoBack}
                    handlePromoCode={handleCheckPromoCode}
                    promoCodeDetails={data}
                    isError={isCheckPromoCodeError}
                    isLoading={isCheckPromoCodeLoading}
                    onRemovePromoCode={() => {
                        setCurrentPromoCode(undefined)
                    }}
                    currentPromoCode={currentPromoCode}
                    isPromoCodeInputVisible={isPromoCodeOn && user.canTrial}
                    currentPlan={user.roles.roleName}
                />
            </UserContextProvider>
        </Page>
    )
}
