import { useMemo } from "react"
import { ICONS } from "./icons"
import { useLocales } from "rentzz"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"

export const useTenantNavConfig = () => {
    const { translate } = useLocales()
    const areRevisionsHidden = useFeatureIsOn(UserFlags.HideTenantRevisions.toString())
    const areTaskItems = useFeatureIsOn(UserFlags.TaskItems.toString())

    return useMemo(() => {
        const nav = [
            {
                subheader: translate("general"),
                items: [
                    {
                        title: translate("dashboard"),
                        path: "/dashboard",
                        icon: ICONS.dashboard,
                    },
                    {
                        title: translate("notes.title"),
                        path: "/notes",
                        icon: ICONS.notes,
                    },
                    { title: translate("files"), path: "/documents", icon: ICONS.files },
                    {
                        title: translate("expenses.expenses"),
                        path: "/expenses",
                        icon: ICONS.expense,
                    },
                    { title: translate("meters"), path: "/meters", icon: ICONS.meters },
                ],
            },
        ]
        if (areTaskItems) {
            nav[0].items.splice(1, 0, {
                title: translate("tasks"),
                path: "/tasks",
                icon: ICONS.tasks,
            })
        }
        if (!areRevisionsHidden) {
            nav[0].items.push({
                title: translate("revisions"),
                path: "/maintenance",
                icon: ICONS.revision,
            })
        }

        return nav
    }, [translate, areTaskItems, areRevisionsHidden])
}
