import CustomCard from "../../components/CustomCard"
import { Box, Typography } from "@mui/material"
import { Iconify } from "rentzz"
import React from "react"
import { alpha, useTheme } from "@mui/material/styles"

// TODO use this custom card also to labels !!!!
interface Props {
    onClick: () => void
    iconColor: string
    isLabel: boolean
    name: string
    rightTitle: string
}
export default function CustomNomenclatureCard({ isLabel, iconColor, rightTitle, onClick, name }: Props) {
    const theme = useTheme()

    return (
        <CustomCard isCardClickable onClick={onClick} px={1} py={1}>
            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} gap={1} paddingTop={2}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={"mdi:account-tie"}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: isLabel ? undefined : 1,
                            borderColor: alpha(theme.palette.primary.main, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: iconColor,
                            backgroundColor: isLabel ? undefined : alpha(theme.palette.primary.main, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                        <Typography fontWeight={"bold"} fontSize={15}>
                            {name}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Typography fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {rightTitle ?? "-"}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
