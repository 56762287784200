import { CustomDialog, FormProvider, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { Box, MenuItem } from "@mui/material"
import {
    DashboardChartType,
    SummaryCardType,
    useAvailableChartsQuery,
    useAvailableSummaryCardsQuery,
    useUserDataQueryFn,
} from "../../../queries/userData"
import SummaryCard from "../../../components/SummaryCard"
import { addChartMutation, addSummaryCardMutation } from "../../../mutations/user"
import { LoadingButton } from "@mui/lab"
import { addGroupChartMutation, addGroupSummaryCardMutation } from "../../../mutations/groups"
import Chart from "../../../components/Chart"
import NoDataPage from "../../../components/NoDataPage"

export enum DashboardItemType {
    SummaryCard,
    Chart,
    Table,
}
const DashboardItemsPreview = () => {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { modalOpen, currentGroupId, editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const { data: allowedSummaryCards } = useAvailableSummaryCardsQuery()
    const { data: allowedCharts } = useAvailableChartsQuery()
    const [selectedCardId, setSelectedCardId] = useState<number | undefined>(undefined)
    const { mutateAsync: addSummaryCard, isPending: isAddSummaryCardLoading } = addSummaryCardMutation()
    const { mutateAsync: addChart, isPending: isAddChartLoading } = addChartMutation()
    const { mutateAsync: addGroupSummaryCard, isPending: isAddGroupSummaryCardLoading } = addGroupSummaryCardMutation()
    const { mutateAsync: addGroupChart, isPending: isAddGroupChartLoading } = addGroupChartMutation()
    const { data: user } = useUserDataQueryFn()
    const methods = useForm()
    const { handleSubmit, watch, reset } = methods

    const allowedCards = useMemo(() => {
        if (editingItem?.itemType === DashboardItemType.SummaryCard) return allowedSummaryCards
        if (editingItem?.itemType === DashboardItemType.Chart) return allowedCharts
    }, [editingItem, allowedCharts, allowedSummaryCards])

    const cardValue = useCallback(
        (card: SummaryCardType | DashboardChartType) => {
            if (editingItem?.itemType === DashboardItemType.SummaryCard) return (card as SummaryCardType).summaryCardId
            if (editingItem?.itemType === DashboardItemType.Chart) return (card as DashboardChartType).dashboardChartId
        },
        [editingItem],
    )

    useEffect(() => {
        if (modalOpen === AppModals.DashboardItemsPreview) {
            setSelectedCardId(undefined)
            reset()
        }
    }, [modalOpen])

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (name === "cardId") {
                setSelectedCardId(v.cardId)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, setSelectedCardId])

    const onSubmit = useCallback(async () => {
        if (user == null || selectedCardId == null) return

        if (currentGroupId) {
            if (editingItem?.itemType === DashboardItemType.SummaryCard) {
                await addGroupSummaryCard({ summaryCardId: selectedCardId })
            }
            if (editingItem?.itemType === DashboardItemType.Chart) {
                await addGroupChart({ chartId: selectedCardId })
            }
        } else {
            if (editingItem?.itemType === DashboardItemType.SummaryCard) {
                await addSummaryCard({ summaryCardId: selectedCardId })
            }
            if (editingItem?.itemType === DashboardItemType.Chart) {
                await addChart({ chartId: selectedCardId })
            }
        }

        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [user, addSummaryCard, selectedCardId, addGroupSummaryCard, currentGroupId, editingItem, addChart])

    return (
        <CustomDialog
            isFullScreen={false}
            title={translate(editingItem?.itemType === DashboardItemType.SummaryCard ? "add_new_summary_card" : "new_chart")}
            onClose={onClose}
            open={!isMobile && modalOpen === AppModals.DashboardItemsPreview}
            maxWidth='sm'
            closeButtonColor={"primary"}
            titleAlign={"center"}
        >
            <Box height={editingItem?.itemType === DashboardItemType.SummaryCard ? 230 : 520} display='flex' flexDirection={"column"} gap={2}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <RHFSelect name='cardId'>
                        {allowedCards?.map((card, index) => (
                            <MenuItem key={index} value={cardValue(card)}>
                                {translate(card.title)}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <Box minHeight={editingItem?.itemType === DashboardItemType.SummaryCard ? 120 : 400} marginTop={2}>
                        {selectedCardId == null && editingItem?.itemType === DashboardItemType.SummaryCard ? (
                            <NoDataPage icon={""} noDataText={"no_summary_card"} paddingTop={2} />
                        ) : (
                            editingItem?.itemType === DashboardItemType.SummaryCard &&
                            selectedCardId && <SummaryCard cardId={selectedCardId} isPreview={true} />
                        )}

                        {selectedCardId == null && editingItem?.itemType === DashboardItemType.Chart ? (
                            <NoDataPage icon={""} noDataText={"no_chart_selected"} paddingTop={10} />
                        ) : (
                            editingItem?.itemType === DashboardItemType.Chart && selectedCardId && <Chart chartId={selectedCardId} isPreview={true} />
                        )}
                    </Box>

                    <LoadingButton
                        fullWidth
                        color='primary'
                        variant='contained'
                        type={"submit"}
                        disabled={isAddSummaryCardLoading || isAddGroupSummaryCardLoading || isAddChartLoading || isAddGroupChartLoading}
                        loading={isAddSummaryCardLoading || isAddGroupSummaryCardLoading || isAddChartLoading || isAddGroupChartLoading}
                        sx={{ marginTop: 2 }}
                    >
                        {translate("add")}
                    </LoadingButton>
                </FormProvider>
            </Box>
        </CustomDialog>
    )
}

export default DashboardItemsPreview
