import { CustomPopover } from "rentzz"
import React, { MouseEvent } from "react"
import AddNewManagerInvitationForm from "./AddNewManagerInvitationForm"
import { UsePopoverReturn } from "rentzz/src/components/custom-popover/types"

interface Props {
    popover: UsePopoverReturn
}
export default function AddNewInvitation({ popover }: Props) {
    return (
        <CustomPopover
            open={popover.open}
            anchorEl={popover.anchorEl}
            slotProps={{
                arrow: {
                    placement: "top-center",
                },
            }}
            onClose={(event: MouseEvent<HTMLElement>) => {
                event.stopPropagation()
                popover.onClose()
            }}
            sx={{
                mt: 1.5,
                ml: 0.75,
                width: 280,
                "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
            }}
        >
            <AddNewManagerInvitationForm onClose={popover.onClose} />
        </CustomPopover>
    )
}
