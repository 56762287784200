import { useMutation, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import * as jsonpatch from "fast-json-patch"
import { AddIncomeRequest } from "../sections/income/add-income/types"
import { AxiosError } from "axios"
import { setErrorMessage } from "../redux/slices/App"
import { useDispatch } from "../redux/store"
import { useIsMobile } from "rentzz"
import { BackendError } from "../sections/types/user"

export const addIncomeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: (data: AddIncomeRequest) => Api.addIncome(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.Income,
                    ComplexQueryIDs.Incomes,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.ExpenseIncomes,
                    ComplexQueryIDs.IncomeSummary,
                    ComplexQueryIDs.ExpenseLateFees,
                    ComplexQueryIDs.IncomeSummaryReport,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}

export const deleteIncomeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ incomeId }: { incomeId: number; propertyId?: number }) => Api.deletePropertyIncome({ incomeId }),
        onSettled: async (_, error, { incomeId }) => {
            queryClient.removeQueries({ queryKey: [ComplexQueryIDs.Income, { incomeId }] })
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.Expense,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.OwnerPropertyFiles,
                    ComplexQueryIDs.TenantPropertyFiles,
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.Income,
                    ComplexQueryIDs.Incomes,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.RentingPeriodDetails,
                    ComplexQueryIDs.ExpenseIncomes,
                    ComplexQueryIDs.IncomeSummary,
                    ComplexQueryIDs.ExpenseLateFees,
                    ComplexQueryIDs.IncomeSummaryReport,
                ])
            }
        },
    })
}

export const updateIncomeMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        mutationFn: ({ incomeId, operations }: { propertyId?: number; incomeId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateIncomeAsync({
                incomeId,
                operations,
            }),
        onSettled: async (data, error) => {
            if (!error) {
                await invalidateQueries([
                    ComplexQueryIDs.Property,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Income,
                    ComplexQueryIDs.Incomes,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.ExpenseIncomes,
                    ComplexQueryIDs.IncomeSummaryReport,
                    ComplexQueryIDs.ExpenseLateFees,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
