import { Iconify } from "rentzz"
import React from "react"

export const ICONS = {
    tenants: <Iconify icon={"mdi:account-multiple"} />,
    properties: <Iconify icon={"mdi:home-city"} />,
    files: <Iconify icon={"mdi:file-document-multiple"} />,
    income: <Iconify icon={"mdi:finance"} />,
    expense: <Iconify icon={"mdi:tag-multiple"} />,
    dashboard: <Iconify icon={"mdi:speedometer"} />,
    payment: <Iconify icon={"mdi:account-cash"} />,
    promotionalCode: <Iconify icon={"mdi:account-star"} />,
    contracts: <Iconify icon={"mdi:file-document-edit-outline"} />,
    marketing: <Iconify icon={"mdi:shopping-outline"} />,
    insurance: <Iconify icon={"mdi:shield-home"} />,
    meters: <Iconify icon={"mdi:meter-electric"} />,
    notes: <Iconify icon={"mdi:text-box-search"} />,
    calendar: <Iconify icon={"mdi:calendar"} />,
    nomenclature: <Iconify icon={"mdi:folder-settings-variant"} />,
    support: <Iconify icon={"mdi:support"} />,
    providers: <Iconify icon={"mdi:account-tie"} />,
    labels: <Iconify icon={"mdi:label-multiple"} />,
    sections: <Iconify icon={"mdi:subtasks"} />,
    tasks: <Iconify icon={"mdi:format-list-checks"} />,
    invoicingConfiguration: <Iconify icon={"mdi:invoice-text-multiple-outline"} />,
    c168: <Iconify icon={"mdi:invoice-text-edit-outline"} />,
    revision: <Iconify icon={"mdi:wrench"} />,
    group: <Iconify icon={"mdi:house-group"} />,
    contractsTemplates: <Iconify icon={"mdi:contract-sign"} />,
    notificationsTemplates: <Iconify icon={"mdi:note-alert-outline"} />,
    generalDocuments: <Iconify icon={"mdi:file-document-multiple"} />,
    bankAccount: <Iconify icon={"mdi:bank"} />,
}
