import { number, object, string } from "yup"

export const SelectProviderSchema = object().shape({
    providerId: string().required("required"),
    propertyId: number().required("required"),
    meterId: number().optional(),
})

export const CreateNewProviderSchema = object().shape({
    name: string().required("required"),
})
