import { ExpenseLateFeesResponse, PaymentType } from "../../../redux/slices/AddProperty"
import { useForm } from "react-hook-form"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { FormProvider, Iconify, LightTooltip, RHFCheckbox, RHFDatePicker, RHFTextField, RHFUnitInput, useIsMobile, useLocales } from "rentzz"
import { Alert, Box, Button, Collapse, InputAdornment, Typography } from "@mui/material"
import { GenerateExpenseLateFeesRequest, generateLateFeesForExpense } from "../../../mutations/expenses"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { orderBy, sum } from "lodash"
import { DateTime } from "luxon"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { useDispatch, useSelector } from "../../../redux/store"
import { LoadingButton } from "@mui/lab"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import { UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useNavigate } from "react-router-dom"
import { setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useTheme } from "@mui/material/styles"
import { useIntl } from "react-intl"
import { yupResolver } from "@hookform/resolvers/yup"
import { GenerateExpenseForFees } from "../../../validations/expenses"

export interface GenerateExpenseLateFeesFormProps {
    lateFeesDetails?: ExpenseLateFeesResponse
    onClose: () => void
    expenseId?: number
}

const GenerateExpenseLateFeesForm = ({ lateFeesDetails, onClose, expenseId }: GenerateExpenseLateFeesFormProps) => {
    const methods = useForm<GenerateExpenseLateFeesRequest>({
        mode: "onChange",
        defaultValues: {
            date: DateTime.now().toISO(),
            dueDate: DateTime.now().plus({ day: 3 }).toISO(),
            value: sum(lateFeesDetails?.lateFees.map((f) => Number(f.amount.toFixed(2)))),
            currencyId: lateFeesDetails?.currencyId,
            shouldCreateInvoice: false,
            files: [],
            isCompounded: true,
        },
        resolver: yupResolver<GenerateExpenseLateFeesRequest>(GenerateExpenseForFees()),
    })
    const [hasDescription, setHasDescription] = useState<boolean>(true)
    const { errorMessage } = useSelector((state) => state.appState)
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)
    const { mutateAsync, isPending: isAddingLateFeesExpense } = generateLateFeesForExpense()
    const isInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const defaultInvoicingProductName = useFeatureValue<Record<string, string>>(UserFlags.DefaultLateFeesProductName.toString(), {})
    const { data: expense } = useSingleExpenseQuery(expenseId)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: allCurrencies } = useCurrencyQueryFn()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const intl = useIntl()
    const { handleSubmit, trigger, watch, reset, setValue } = methods
    // const { editableProperties } = usePermissions(PermissionType.Expenses, expense?.propertyId)
    const { data: user } = useUserDataQueryFn()
    const lateFeesInvoiceDisabledTooltip = useMemo(() => {
        if (!expense?.canCreateLateFees) {
            return "late_fees_not_configured"
        }

        return ""
    }, [lateFeesDetails, expense])
    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (allCurrencies == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(allCurrencies, currencyId).code,
            })
        },
        [allCurrencies],
    )

    const lateFeesDescription = useMemo(() => {
        if (expense == null) return

        if (expense.dueDate >= DateTime.now().startOf("day")) {
            return "late_fees_not_overdue"
        }
        if (expense.paymentType === PaymentType.Fees) {
            return "late_fees_cant_generate_late_fees"
        }
        return ""
    }, [expense])

    const description = useCallback(
        (isCompounded: boolean) => {
            return (
                ((lateFeesDetails?.alreadyGeneratedExpenses.length ?? 0) > 0
                    ? translate("late_fees_previously_created") +
                      "\n" +
                      lateFeesDetails?.alreadyGeneratedExpenses?.map((f) =>
                          translate("late_fees_previously_created_row", {
                              amount: getAmount(f.amount, f.currencyId),
                              date: f.date.toLocaleString(DateTime.DATE_SHORT),
                              amountInExpenseCurrency: getAmount(f.amountInMainExpenseCurrency, lateFeesDetails?.currencyId),
                          }),
                      ) +
                      "\n\n"
                    : "") +
                ((lateFeesDetails?.lateFees.length ?? 0) > 0
                    ? translate("late_fees_description_header", {
                          expenseName: expense?.name,
                          expenseDate: expense?.date.toLocaleString(DateTime.DATE_SHORT),
                          percentage: lateFeesDetails?.percentage,
                      }) +
                      "\n" +
                      lateFeesDetails?.lateFees
                          .map((f) =>
                              translate("late_fees_row_description", {
                                  periodStart: f.startDate.toLocaleString(DateTime.DATE_SHORT),
                                  periodEnd: f.endDate.toLocaleString(DateTime.DATE_SHORT),
                                  amount: getAmount(isCompounded ? f.amount : f.amountNotCompounded, lateFeesDetails?.currencyId),
                                  expenseAmount: getAmount(f.expenseRemainingAmount, lateFeesDetails?.currencyId),
                              }),
                          )
                          .join("\n") +
                      translate("late_fees_total_days", {
                          totalDays: sum(lateFeesDetails?.lateFees.map((f) => f.endDate.diff(f.startDate, "day").days)),
                      })
                    : "")
            )
        },
        [translate, lateFeesDetails, expense],
    )

    useEffect(() => {
        reset({
            date: DateTime.now().toISO(),
            dueDate: DateTime.now().plus({ day: 3 }).toISO(),
            value: sum(lateFeesDetails?.lateFees.map((f) => Number(f.amount.toFixed(2)))),
            currencyId: lateFeesDetails?.currencyId,
            name: translate("late_fees"),
            shouldCreateInvoice: false,
            isCompounded: true,
            files: [],
            description: description(true),
            __: getAmount(
                sum(lateFeesDetails?.alreadyGeneratedExpenses.map((f) => Number(f.amount.toFixed(2)))),
                lateFeesDetails?.alreadyGeneratedExpenses.at(0)?.currencyId,
            ),
        })
    }, [lateFeesDetails])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            setIsCreatingInvoice(v.shouldCreateInvoice ?? false)
            if (v.shouldCreateInvoice && user && expense && name === "shouldCreateInvoice") {
                setValue("invoiceProductName", defaultInvoicingProductName[user?.languageCode])
                setValue("invoiceProductDescription", description(v.isCompounded ?? false))
            }

            if (name === "isCompounded") {
                if (v.isCompounded) {
                    setValue("value", sum(lateFeesDetails?.lateFees.map((f) => Number(f.amount.toFixed(2)))))
                    setValue("description", description(true))
                } else {
                    setValue("value", sum(lateFeesDetails?.lateFees.map((f) => Number(f.amountNotCompounded.toFixed(2)))))
                    setValue("description", description(false))
                }
                if (v.shouldCreateInvoice && user && expense) {
                    setValue("invoiceProductDescription", description(v.isCompounded ?? false))
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [trigger, user, expense, description])

    // const getMaxSize = useCallback(() => {
    //     if (user == null || editableProperties == null || expense == null) return 0
    //
    //     const property = editableProperties.find((p) => p.id === expense?.propertyId)
    //
    //     if (property == null) return 0
    //
    //     if (user.features?.maxPropertySize) {
    //         return user.features?.maxPropertySize - property.currentSize
    //     }
    // }, [user, editableProperties, expense])

    const onSubmit = useCallback(
        async (data: GenerateExpenseLateFeesRequest) => {
            if (expenseId == null) return
            await mutateAsync({
                data,
                expenseId,
            })
            onClose()
        },
        [expenseId],
    )

    if (expenseId == null || expense == null || lateFeesDetails == null) return null

    if ((lateFeesDetails?.percentage ?? 0) === 0)
        return (
            <Box height={isMobile ? 200 : 100} display='flex' width='100%'>
                <Alert
                    severity='error'
                    sx={{
                        width: "100%",
                        "& .MuiAlert-message": {
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        },
                        "& .MuiAlert-icon": {
                            display: "none",
                        },
                        "& .MuiAlert-action": {
                            alignItems: "center",
                        },
                    }}
                >
                    <Box display='flex' flexDirection='column' width='100%' justifyContent='center' alignItems={"center"}>
                        {translate("late_fees_percentage_not_set")}
                        <Button
                            onClick={() => {
                                dispatch(setModalOpen(undefined))
                                dispatch(setEditingItem(undefined))
                                navigate(`/tenants/${expense?.rentingPeriodId}/configurations`)
                            }}
                        >
                            {translate("configure_now")}
                        </Button>
                    </Box>
                </Alert>
            </Box>
        )

    if ((lateFeesDescription !== "" || lateFeesDetails.percentage === -1) && isMobile)
        return (
            <Box height={isMobile ? 200 : 100} display='flex' width='100%'>
                <Alert
                    severity='error'
                    sx={{
                        width: "100%",
                        "& .MuiAlert-message": {
                            width: "100%",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                        },
                        "& .MuiAlert-icon": {
                            display: "none",
                        },
                        "& .MuiAlert-action": {
                            alignItems: "center",
                        },
                    }}
                >
                    <Box display='flex' flexDirection='column' width='100%' justifyContent='center' alignItems={"center"}>
                        {translate(lateFeesDetails.percentage === -1 ? "no_tenant_late_fees" : lateFeesDescription)}
                    </Box>
                </Alert>
            </Box>
        )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection='column' gap={2} pt={2}>
                {lateFeesDetails.alreadyGeneratedExpenses.length > 0 && (
                    <RHFTextField
                        name={"__"}
                        disabled
                        label={translate("late_fees_already_invoiced")}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    <LightTooltip
                                        title={
                                            <Box display={"flex"} flexDirection={"column"} width={200}>
                                                {lateFeesDetails.alreadyGeneratedExpenses?.map((e, i) => (
                                                    <Box
                                                        key={i}
                                                        display='flex'
                                                        flexDirection='row'
                                                        justifyContent='space-between'
                                                        alignItems='center'
                                                    >
                                                        <Typography variant={"caption"} textAlign={"start"}>
                                                            {e.date.toLocaleString(DateTime.DATE_SHORT)}
                                                        </Typography>
                                                        <Typography variant={"caption"} textAlign={"start"}>
                                                            {getAmount(e.amount, e.currencyId)}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        }
                                        arrow
                                    >
                                        <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                    </LightTooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                    <RHFCheckbox name={"isCompounded"} label={translate("is_compounded_interest")} />
                </Box>
                <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                    <RHFTextField name={"name"} label={translate("name")} size='small' />
                    <RHFUnitInput
                        label={translate("amount")}
                        unitName={"currencyId"}
                        name='value'
                        size='small'
                        unitSelectSize='small'
                        data={allCurrencies ?? []}
                        unitType={"currency"}
                    />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"}>
                    <RHFDatePicker
                        name='date'
                        label={translate("date")}
                        required
                        disableFuture
                        minDate={orderBy(lateFeesDetails?.lateFees ?? [], "startDate").at(0)?.startDate}
                    />
                    <RHFDatePicker name='dueDate' label={translate("due_date")} maxDate={DateTime.now()} required />
                </Box>
                <Button variant='text' fullWidth={false} disableTouchRipple={true} onClick={() => setHasDescription((prev) => !prev)}>
                    <Box display='flex' flexDirection='row' gap={2}>
                        {translate("add_description")}
                        {hasDescription ? (
                            <ArrowUpward sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                        ) : (
                            <ArrowDownward sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                        )}
                    </Box>
                </Button>
                <Collapse in={hasDescription}>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFTextField label={translate("expense_description")} rows={5} fullWidth multiline={true} name={"description"} />
                    </Box>
                </Collapse>
                {isInvoicingConfigurationOn && (
                    <LightTooltip title={lateFeesInvoiceDisabledTooltip != "" ? translate(lateFeesInvoiceDisabledTooltip) : ""} arrow>
                        <span>
                            <RHFCheckbox
                                name={"shouldCreateInvoice"}
                                disabled={lateFeesInvoiceDisabledTooltip != ""}
                                label={translate("should_create_invoice")}
                            />
                        </span>
                    </LightTooltip>
                )}
                {isCreatingInvoice && <RHFTextField name={"invoiceProductName"} label={translate("invoice_product_name")} size='small' />}
                {isCreatingInvoice && (
                    <RHFTextField
                        name={"invoiceProductDescription"}
                        label={translate("invoice_product_description")}
                        size='small'
                        multiline={true}
                        rows={5}
                    />
                )}
                {/* <RHFUpload name={"files"} multiple maxSize={getMaxSize()} icon={"mdi:file-document-plus-outline"} /> */}

                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button disabled={isAddingLateFeesExpense} color={"primary"} onClick={onClose}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        type={"submit"}
                        variant='contained'
                        color={"primary"}
                        loading={isAddingLateFeesExpense}
                        disabled={isAddingLateFeesExpense}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default GenerateExpenseLateFeesForm
