import { Box, IconButton, InputAdornment, Switch, TextField, Tooltip } from "@mui/material"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import CustomExpenseToolbar from "../../../expenses/Toolbar"
import React, { MouseEvent, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomPopover, Iconify, useCopyToClipboard, useIsMobile, useLocales, usePopover } from "rentzz"
import { useRentingPeriodDetails } from "../../../../queries/tenants"
import { RentingPeriodStatusCode } from "../../../../redux/slices/AddProperty"
import { useSnackbar } from "notistack"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../queries/userData"
import { updateRentingPeriodMutation } from "../../../../mutations/tenants"
import { Operation } from "fast-json-patch"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import Buttons from "./Buttons"
import EditExternalSystemCodeNameForm from "./EditExternalSystemCodeNameForm"
import CustomToolbar from "../../../../components/CustomToolbar"
import { ExportType } from "../../../../utils/actions"

interface Props {
    page: string | undefined
}
export default function RentingPeriodDetailsHeader({ page }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { currentRentingPeriodId, currentGroupId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { enqueueSnackbar } = useSnackbar()
    const { copy } = useCopyToClipboard()
    const invoicingConfigurations = useFeatureValue(UserFlags.InvoicingConfigurations.toString(), null)
    const popover = usePopover()
    const { mutateAsync: updateRentingPeriod } = updateRentingPeriodMutation()
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    const handleSwitchChange = useCallback(async () => {
        const operations: Operation[] = [
            { op: "replace", path: "/enableExternalSystemAccess", value: !rentingPeriodDetails?.enableExternalSystemAccess },
        ]
        if (rentingPeriodDetails != null && operations.length > 0) {
            await updateRentingPeriod({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations,
            })
        }
    }, [rentingPeriodDetails])

    const onCopy = useCallback(
        (text: string) => {
            if (text) {
                enqueueSnackbar(translate("copied"))
                copy(text)
            }
        },
        [copy, enqueueSnackbar, translate],
    )

    const isRentingPeriodCompleted = useMemo(
        () =>
            rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
            rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Finished,
        [rentingPeriodDetails],
    )

    const handleOpen = useCallback(
        (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            if (isMobile) {
                dispatch(setModalOpen(AppModals.EditExternalSystemCodeName))
            } else {
                popover.onOpen(event)
            }
        },
        [isMobile, dispatch, popover],
    )

    if (rentingPeriodDetails == null) {
        return null
    }

    return (
        <Box
            marginTop={isMobile ? 1 : undefined}
            display={isMobile ? undefined : "flex"}
            gap={isMobile ? 2 : undefined}
            flexDirection={isMobile ? "column" : "row"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            {page !== "expenses" && page !== "incomes" && page !== "general" && <Box />}
            {page === "general" && (
                <>
                    {invoicingConfigurations ? (
                        <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent={"space-between"}
                            marginTop={2}
                            gap={2}
                            alignItems='center'
                            width='100%'
                        >
                            <Buttons />

                            <TextField
                                size={"small"}
                                label={translate("external_systems_code")}
                                value={rentingPeriodDetails.externalSystemId}
                                sx={{ width: 420 }}
                                InputProps={{
                                    disabled: true,
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <Tooltip title={translate("copy")} arrow>
                                                <IconButton onClick={() => onCopy(rentingPeriodDetails.externalSystemId)}>
                                                    <Iconify icon='eva:copy-fill' width={24} />
                                                </IconButton>
                                            </Tooltip>
                                            {canWrite && (
                                                <>
                                                    <Tooltip title={translate("edit")} arrow>
                                                        <IconButton onClick={handleOpen}>
                                                            <Iconify icon='mdi:edit' width={24} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip
                                                        arrow
                                                        title={translate(`external_integrations_${rentingPeriodDetails.enableExternalSystemAccess}`)}
                                                    >
                                                        <Switch
                                                            onChange={handleSwitchChange}
                                                            checked={rentingPeriodDetails.enableExternalSystemAccess}
                                                        />
                                                    </Tooltip>
                                                </>
                                            )}

                                            <CustomPopover
                                                open={popover.open}
                                                anchorEl={popover.anchorEl}
                                                slotProps={{
                                                    arrow: { placement: "top-center" },
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                                onClose={(event: MouseEvent<HTMLElement>) => {
                                                    event.stopPropagation()
                                                    popover.onClose()
                                                }}
                                                sx={{
                                                    mt: 1.5,
                                                    ml: 0.75,
                                                    width: 200,
                                                    "& .MuiMenuItem-root": { px: 1, typography: "body2", borderRadius: 0.75 },
                                                }}
                                            >
                                                <EditExternalSystemCodeNameForm onClose={() => popover.onClose()} />
                                            </CustomPopover>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    ) : (
                        <Buttons />
                    )}
                </>
            )}
            {page === "incomes" && currentRentingPeriodId && !isMobile && (
                <CustomToolbar
                    onAddClick={canWrite && currentGroupId == null ? () => dispatch(setModalOpen(AppModals.AddIncome)) : undefined}
                    numberOfItems={(rentingPeriodDetails?.totalIncome ?? 0) > 0 ? 1 : 0}
                    canExport={true}
                    isDisabled={!isRentingPeriodCompleted}
                    tooltipTitle={isRentingPeriodCompleted ? "" : translate("tenant_need_to_accept")}
                    buttonText={"income-table.addIncome"}
                    exportType={ExportType.Income}
                />
            )}
            {page === "expenses" && currentRentingPeriodId && !isMobile && (
                <CustomExpenseToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddExpense))}
                    canExport={true}
                    numberOfItems={(rentingPeriodDetails?.totalExpenses ?? 0) > 0 ? 1 : 0}
                    isAddDisabled={!isRentingPeriodCompleted}
                    tooltipTitle={isRentingPeriodCompleted ? "" : translate("no_tenants_no_expense")}
                />
            )}
        </Box>
    )
}
