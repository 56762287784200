import { FormProvider, RHFSelect, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Box, Button, CircularProgress, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AccountType, useSingleActiveUserQuery } from "../../../queries/userData"
import { updateActiveUser } from "../../../mutations/user"

interface Props {
    userId: string
    onClose?: () => void
}

const UserAccountTypeForm = ({ userId, onClose }: Props) => {
    const isMobile = useIsMobile()
    const { data: currentUser } = useSingleActiveUserQuery(userId)
    const { translate } = useLocales()
    const { mutateAsync: updateUser, isPending: isUpdateUserPending } = updateActiveUser()

    const methods = useForm({
        defaultValues: {
            accountType: currentUser?.accountType.toString(),
        },
    })
    const { handleSubmit, watch, getValues, reset } = methods

    useEffect(() => {
        reset({ accountType: currentUser?.accountType.toString() })
    }, [currentUser])

    const onSave = useCallback(async () => {
        console.log(currentUser, "Dan")
        if (currentUser == null) return
        const newAccountType = getValues("accountType")

        if (currentUser.accountType.toString() !== newAccountType) {
            await updateUser({
                userId,
                accountType:
                    newAccountType === AccountType.WHITELABEL_ADMIN_USER.toString()
                        ? AccountType.WHITELABEL_ADMIN_USER
                        : newAccountType === AccountType.TICKETS_ONLY.toString()
                          ? AccountType.TICKETS_ONLY
                          : AccountType.WHITELABEL_USER,
            })
            if (onClose) {
                onClose()
            }
        }
    }, [currentUser, updateUser, onClose, getValues])

    useEffect(() => {
        const subscription = watch((_) => {
            if (!isMobile) {
                onSave()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, onSave])

    if (isUpdateUserPending && !isMobile) return <CircularProgress size='1rem' />

    return (
        <Box display='flex' flexDirection='column' width='100%' sx={{ textAlign: "center" }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                {isMobile ? (
                    <Box display={"flex"} gap={2} paddingTop={2} flexDirection={"column"}>
                        <RHFSelect fullWidth name={"accountType"} size='small'>
                            <MenuItem value={AccountType.WHITELABEL_USER.toString()} style={{ textTransform: "none" }}>
                                {translate("user")}
                            </MenuItem>
                            <MenuItem value={AccountType.WHITELABEL_ADMIN_USER.toString()} style={{ textTransform: "none" }}>
                                {translate("manager")}
                            </MenuItem>
                        </RHFSelect>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingX: 0,
                                paddingTop: 2,
                                gap: 2,
                                flexDirection: isMobile ? "column-reverse" : "row",
                            }}
                        >
                            <Button onClick={onClose} color={"primary"} fullWidth={isMobile} disabled={isUpdateUserPending}>
                                {translate("cancel")}
                            </Button>
                            <LoadingButton
                                type={"submit"}
                                variant='contained'
                                color={"primary"}
                                fullWidth={isMobile}
                                loading={isUpdateUserPending}
                                disabled={isUpdateUserPending}
                            >
                                {translate("update")}
                            </LoadingButton>
                        </Box>
                    </Box>
                ) : (
                    <Box display={"flex"} paddingY={1}>
                        <RHFSelect fullWidth name={"accountType"} size='small'>
                            <MenuItem value={AccountType.WHITELABEL_USER.toString()} style={{ textTransform: "none" }}>
                                {translate("user")}
                            </MenuItem>
                            <MenuItem value={AccountType.WHITELABEL_ADMIN_USER.toString()} style={{ textTransform: "none" }}>
                                {translate("manager")}
                            </MenuItem>
                            <MenuItem value={AccountType.TICKETS_ONLY.toString()} style={{ textTransform: "none" }}>
                                {translate("tickets_only")}
                            </MenuItem>
                        </RHFSelect>
                    </Box>
                )}
            </FormProvider>
        </Box>
    )
}

export default UserAccountTypeForm
