import { ReactNode, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"
import { AppContext, LoadingScreen, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../redux/store"
import { setContext, setCurrentPropertyId, setCurrentRentingPeriodId } from "../redux/slices/App"
import { useTenantRentingPeriodsQuery, useUserDataQueryFn, useUserTenantRequests } from "../queries/userData"
import { setCulture } from "@syncfusion/ej2-base"

type AuthGuardProps = {
    children: ReactNode
}

export default function AuthGuard({ children }: AuthGuardProps) {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { pathname } = useLocation()
    const { data: user, isFetching: isUserFetching } = useUserDataQueryFn()
    const { data: userRentingPeriods } = useTenantRentingPeriodsQuery()
    const { data: tenantRequests } = useUserTenantRequests()
    const { onChangeLang } = useLocales()
    const dispatch = useDispatch()
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null)

    useEffect(() => {
        if (user && user.isTicketsOnly) {
            dispatch(setContext(AppContext.Owner))
        }
    }, [user])
    useEffect(() => {
        if (user == null) return
        if (context === AppContext.Tenant) {
            const rentingPeriod = (userRentingPeriods ?? [])[0]
            if (rentingPeriod && currentPropertyId === undefined) {
                dispatch(setCurrentPropertyId(rentingPeriod.propertyId))
                dispatch(setCurrentRentingPeriodId(rentingPeriod.rentingPeriodId))
            }
        }
        onChangeLang(user.languageCode)
        setCulture(user.languageCode)
    }, [user, context, userRentingPeriods])

    if (requestedLocation && pathname !== requestedLocation && user) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    if (user && tenantRequests) {
        return <>{children}</>
    }
    if (isUserFetching && !user) return <LoadingScreen />

    if (!user) {
        return <Navigate to={"/login"} />
    }
    if (user) {
        return <>{children}</>
    }
    return <LoadingScreen />
}
