import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"

export interface AddNewInvoicingClientRequest {
    invoicingCompanyId: number
    cif: string
    name: string
    county: string
    city: string
    country?: string
    email: string
    phone?: string
    address?: string
    bank?: string
    iban?: string
    isTaxPayer: boolean
}

export const addNewInvoicingClient = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: AddNewInvoicingClientRequest) => Api.addNewInvoicingClientRequest(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    SimpleQueryIDs.UserInvoicingConfigurations,
                    ComplexQueryIDs.UserInvoicingClients,
                    SimpleQueryIDs.UserInvoicingCompanies,
                ])
            }
        },
    })
}

export const updateInvoicingClient = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingClientId, operations }: { invoicingClientId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInvoicingClient({
                invoicingClientId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.UserInvoicingClients, ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}

export const deleteInvoicingClientMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    return useMutation({
        mutationFn: ({ invoicingClientId }: { invoicingClientId: number }) => Api.deleteInvoicingClient({ invoicingClientId }),
        onSettled: async (_, error, { invoicingClientId }) => {
            queryClient.removeQueries({ queryKey: [ComplexQueryIDs.InvoicingConfigurationClients, { invoicingClientId }] })
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.UserInvoicingClients, ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}
