import { useSelector } from "../../redux/store"
import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../../hooks/useQueryInvalidator"
import Api from "../../api/Api"
import { AppContext, useIsMobile } from "rentzz"
import { UserFlags } from "../userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { sum } from "lodash"
import { PropertyExpense } from "../expenses"
import { AppModals } from "../../redux/slices/App"

export const useBankStatementDetails = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())

    return useQuery({
        queryKey: [
            ComplexQueryIDs.BankStatements,
            {
                id,
            },
        ],
        queryFn: () => Api.fetchBankStatementDetails(id!),
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areBankStatementsEnabled && id != null,
    })
}

export const useBankStatementDetailsMobile = (id?: number) => {
    const { context, modalOpen } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())
    const isMobile = useIsMobile()

    return useQuery({
        queryKey: [
            ComplexQueryIDs.BankStatements,
            {
                id,
            },
        ],
        queryFn: () => Api.fetchBankStatementDetails(id!),
        staleTime: Infinity,
        enabled:
            context === AppContext.Owner && areBankStatementsEnabled && id != null && isMobile && modalOpen === AppModals.BankStatementDetailsSheet,
    })
}

export const useTransactionDetailsQuery = (statementId?: number, id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())

    return useQuery({
        queryKey: [
            ComplexQueryIDs.BankStatementTransactionDetails,
            {
                id,
            },
        ],
        queryFn: () => Api.fetchBankStatementTransactionDetails(statementId!, id!),
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areBankStatementsEnabled && id != null && statementId != null,
    })
}

export const useTransactionPossibleAssociatedExpensesQuery = (statementId?: number, id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())

    return useQuery({
        queryKey: [
            ComplexQueryIDs.BankStatementTransactionPossibleExpenses,
            {
                id,
            },
        ],
        queryFn: () => Api.fetchBankStatementTransactionPossibleExpenses(statementId!, id!),
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areBankStatementsEnabled && id != null && statementId != null,
        select: (data): (PropertyExpense & { remainingToPay: number })[] => {
            return data.map((e) => ({
                ...e,
                remainingToPay: e.value - sum(e.propertyIncomes.map((i) => i.valueInExpenseCurrency)),
            }))
        },
    })
}

export const useBankStatements = (page: number, pageSize: number, sortingColumns: GridSortModel, filterModel?: GridFilterModel) => {
    const { context } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())

    return useQuery({
        queryKey: [
            ComplexQueryIDs.BankStatements,
            {
                page,
                pageSize,
                context,
                sortingColumns,
                filterModel,
            },
        ],
        queryFn: Api.fetchBankStatements,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areBankStatementsEnabled,
    })
}

export const useInfiniteBankStatementsQuery = (sortingColumns?: GridSortModel, filterModel?: GridFilterModel) => {
    const { context } = useSelector((state) => state.appState)
    const areBankStatementsEnabled = useFeatureIsOn(UserFlags.BankStatementsProcessing.toString())

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.BankStatements, { sortingColumns, filterModel }],
        queryFn: Api.fetchInfiniteBankStatements,
        enabled: context === AppContext.Owner && areBankStatementsEnabled,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}
