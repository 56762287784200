import { Box, Card, CardHeader, CircularProgress, Divider, Typography } from "@mui/material"
import { Iconify, useIsMobile, useLocales } from "rentzz"
import { ProviderType } from "../../../../redux/slices/AddProperty"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useTheme } from "@mui/material/styles"
import React from "react"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { useConfiguredExpenseProviders } from "../../../../queries"
import MobileProvidersList from "./MobileProvidersList"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import NoDataPage from "../../../../components/NoDataPage"
import CustomProviderCard from "../../../../components/CustomProviderCard"
import RoleLockedButton from "../../../../components/RoleLockedButton"
import { RoleName } from "../../../../queries/userData"

export default function ProvidersTable() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Properties, currentPropertyId)
    const { data: providers, isLoading: areProvidersLoading } = useConfiguredExpenseProviders()

    return (
        <Card sx={{ width: "100%", minHeight: 400 }}>
            <CardHeader
                title={
                    <Box display='flex' flexDirection='row' gap={1} alignItems='center'>
                        <Typography variant='subtitle1'>{translate("providers")}</Typography>
                    </Box>
                }
                sx={{
                    p: 2,
                    color: theme.palette.grey.A700,
                    ".MuiCardHeader-action ": { alignSelf: "center" },
                }}
                action={
                    canWrite && (
                        <RoleLockedButton
                            onClick={() => {
                                dispatch(setEditingItem({ providerType: ProviderType.Expense }))
                                dispatch(setModalOpen(AppModals.AddExpenseProvider))
                            }}
                            variant={"text"}
                            buttonText={
                                <>
                                    <Iconify icon={"mdi:add"} />
                                    <Typography>{translate("add_provider")}</Typography>
                                </>
                            }
                            blockedRoles={[RoleName.FREE, RoleName.BRONZE]}
                        />
                    )
                }
            />
            <Divider />
            {providers?.length === 0 && <NoDataPage noDataText={"no_providers"} />}

            {isMobile ? (
                <MobileProvidersList />
            ) : (
                <>
                    {areProvidersLoading ? (
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ overflowY: "auto", maxHeight: isMobile ? undefined : 300 }}>
                            <Box padding={1} display={"flex"} flexDirection={"column"} rowGap={1}>
                                {providers?.map((provider) => <CustomProviderCard isEditable key={provider.id} provider={provider} />)}
                            </Box>
                        </Box>
                    )}
                </>
            )}
        </Card>
    )
}
