import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Currencies, CustomFile, ExpenseAssignee, Furniture, IFileManager, TVType } from "rentzz"
import { DateTime } from "luxon"
import { IconifyIcon } from "@iconify/react"
import { ManagerType } from "../../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { NotificationTemplateVariable, PropertyAutoAcceptType } from "../../queries/userData"
import { PresentationFile } from "../../queries"

export interface RevisionObservationBase {
    id: number
    title: string
    description: string
    maintenanceScheduleId: number
}

export interface RevisionObservationDto extends RevisionObservationBase {
    observationDate: string
    createdAt: string
    lastModifiedAt: string
}
export interface RevisionObservation extends RevisionObservationBase {
    observationDate: DateTime
    createdAt: DateTime
    lastModifiedAt: DateTime
}
export interface TenantRevisionBase {
    id: number
    name: string
    description: string
    status: RevisionStatus
    showDatePicker: boolean
}

export interface TenantRevisionDto extends TenantRevisionBase {
    date: string
    tenantChosenDate?: string
}
export interface TenantRevision extends TenantRevisionBase {
    date: DateTime
    tenantChosenDate?: DateTime
}
export enum RevisionStatus {
    Pending,
    Done,
}
export interface PropertyRevisionBase {
    id: string
    name: string
    propertyId: number
    description: string
    createdByUserId: string
    createdBy: string
    status: RevisionStatus
    files: CustomFile[]
    visibleToTenant: boolean
    allowTenantChoseDate: boolean
}

export interface PropertyRevisionDto extends PropertyRevisionBase {
    date: string
    createdAt: string
    lastModifiedAt: string
    tenantChosenDate?: string
}
export interface PropertyRevision extends PropertyRevisionBase {
    date: DateTime
    createdAt: DateTime
    lastModifiedAt: DateTime
    tenantChosenDate?: DateTime
}

export enum ProviderType {
    Expense,
    Meter,
}
export enum ProviderStatus {
    Pending = 0,
    Active = 1,
    Rejected = 2,
}

export enum RejectReasonType {
    IncorrectCredentials = 0,
    MissingCredentials = 1,
}

export interface ExpenseProviderFieldDefinition {
    name: string
    label: string
    description: string
    fieldType: ExpenseFieldType
}
export interface MeterProviderFieldDefinition {
    name: string
    label: string
    description: string
    fieldType: MeterFieldType
}

export interface ExpenseProvider {
    id: string
    name: string
    requiredFields: ExpenseProviderFieldDefinition[]
}

export interface MeterProvider {
    id: string
    name: string
    requiredFields: MeterProviderFieldDefinition[]
}

export interface ConfiguredExpenseProviderBase {
    id: string
    providerName: string
    providerLogo: string
    status: ProviderStatus
    expenseCreationPeriod: number[]
    fields: {
        name: string
        value: string
        type: ExpenseFieldType
        label: string
    }[]
    rejectReason?: RejectReasonType
    sendAutomaticallyToTenant: boolean
    arePaymentsSync: boolean
    assignee: ExpenseAssignee
    lastBalance?: number
    lastBalanceCurrencyId?: number
}

export enum ExpenseFieldType {
    Text = 0,
    Password,
}

export interface ConfiguredExpenseProviderDto extends ConfiguredExpenseProviderBase {
    createdAt: string
    lastExpenseCreationDate: string
}

export interface ConfiguredExpenseProvider extends ConfiguredExpenseProviderBase {
    createdAt: DateTime
    lastExpenseCreationDate: DateTime
}

export interface ConfiguredMeterProviderBase {
    id: string
    providerName: string
    providerLogo: string
    status: ProviderStatus
    fields: {
        name: string
        value: string
        type: MeterFieldType
        label: string
    }[]
    rejectReason?: RejectReasonType
}

export enum MeterFieldType {
    Text = 0,
    Password,
}

export interface ConfiguredMeterProviderDto extends ConfiguredMeterProviderBase {
    createdAt: string
}

export interface ConfiguredMeterProvider extends ConfiguredMeterProviderBase {
    createdAt: DateTime
}

export interface LatLng {
    lat: number
    lng: number
}

export interface PropertyDetails extends PropertyDataInfo {
    rooms?: number
    year?: number
    size?: number
}

export interface PropertyDataInfo {
    city: string
    county: string
    area?: string
    street?: string
    streetNumber?: string
    buildingNumber?: string
    stair?: string
    floor?: number
    apartment?: string
}

export interface PropertyMeterBase {
    id: number
    name: string
    currentValue: number
    unitId: number
    requirePhotoForReading: boolean
    propertyId: number
    reportingPeriodStartDay: number
    reportingPeriodEndDay: number
}

export interface TenantPropertyMeterDto extends PropertyMeterBase {
    lastModified?: string
}

export interface TenantPropertyMeter extends PropertyMeterBase {
    lastModified?: DateTime
}

export interface OwnerPropertyMeterBase extends PropertyMeterBase {
    initialValue: number
    files?: PropertyFileDto[]
    hasReadingsWithFiles: boolean
    requireInteger: boolean
    isActive: boolean
    configuredProvider: ConfiguredMeterProvider
    isMaintainingValueWhenNoReadingSent: boolean
}

export interface OwnerPropertyMeterDto extends OwnerPropertyMeterBase {
    lastModified: string
}

export interface OwnerPropertyMeter extends OwnerPropertyMeterBase {
    lastModified: DateTime
}

export interface PropertyMeterValueBase {
    id: number
    currentValue: number
    previousValue: number
    createdBy: string
    files?: PropertyFileDto[]
    meterFileIds?: number[]
    canEdit: boolean
    sentToProvider: boolean
    status: MeterValueStatus
    unitId: number
    propertyId: number
    meterName: string
    canAutoSend: boolean
    meterId: number
}

export enum MeterValueStatus {
    Created = 0,
    Sending = 1,
    FailedToSend = 2,
    Sent = 3,
}

export interface PropertyMeterValueDto extends PropertyMeterValueBase {
    createdAt: string
    sentToProviderAt?: string
    sendAutomaticallyAt: string
}

export interface PropertyMeterValue extends PropertyMeterValueBase {
    createdAt: DateTime
    sentToProviderAt?: DateTime
    sendAutomaticallyAt?: DateTime
}

export interface PropertyFilesGrouped {
    expenses: IFileManager[]
    income: IFileManager[]
    documents: IFileManager[]
    contracts: IFileManager[]
    insurance: IFileManager[]
    meterValues: IFileManager[]
    tasks: IFileManager[]
    observations: IFileManager[]
}
export interface PropertyFileDtoBase {
    id: string
    name: string
    size: number
    type: PropertyFileType
    extension: string | undefined
}

export interface PropertyFileDto extends PropertyFileDtoBase {
    propertyId: number
}

export enum PropertyFileType {
    PropertyImage = 0,
    Expense = 1,
    Income = 2,
    Document = 3,
    Contract = 4,
    Insurance = 5,
    MeterValue = 6,
    SupportTicket = 7,
    RecurringExpense = 8,
    Note = 9,
    TaskFile = 10,
    Report = 11,
    Journal = 12,
    Attachment = 13,
    RevisionObservation = 14,
    TaskComment = 15,
}

export enum AppEntityType {
    Expense,
    Income,
}

export interface RentingPeriodBase {
    id: number
    invitedMails: string[]
    value: number
    currencyId: number
    securityDeposit: number
    securityDepositCurrencyId: number
    propertyId: number
    rentingPeriodStatus: RentingPeriodStatusCode
    contractFileId: string | undefined
    paymentNotificationVariables: NotificationTemplateVariable[]
    isC168Sent?: boolean
}

export enum RentingPeriodStatusCode {
    Created,
    NotificationSent,
    ExtendNotificationSent,
    Declined,
    Accepted,
    Finished,
    PendingTenantSignature,
    PartiallyAccepted,
}

export enum DashboardDataType {
    Number,
    Date,
    Money,
    Text,
    Missing,
    NumberArray,
    Meters,
}

export enum DashboardSentiment {
    Neutral,
    Positive,
    Negative,
}

export enum DashboardReferencedType {
    None,
    Property,
    Expense,
    Income,
    Tenant,
    Meter,
    EndingSoon,
    ProvidersWithoutExpenseThisMonth,
    UnpaidRentingPeriodsThisMonth,
}

export interface SummaryCardDto {
    value: string
    title: string
    previousValue: string
    icon: string | IconifyIcon
    dataType: DashboardDataType
    sentiment: DashboardSentiment
    referencedType: DashboardReferencedType
}

export interface DashboardChartCommon {
    title: string
    tooltipTitle: string
    dataType: DashboardDataType
    sentiment: DashboardSentiment
}

export interface DashboardChartDto extends DashboardChartCommon {
    values: DashboardChartValueDto[]
}

export interface DashboardChart extends DashboardChartCommon {
    values: DashboardChartValue[]
}

export enum DashboardTableCodes {
    RemainingMeters,
    TenantRemainingMeters,
}

export interface DashboardMetersTableCommon {
    title: string
    code: DashboardTableCodes
}

export interface DashboardMetersTableValuesCommon {
    lastReadBy: string
    name: string
    reportingPeriodStartDay: number
    reportingPeriodEndDay: number
}

export interface DashboardMetersTableValuesDto extends DashboardMetersTableValuesCommon {
    averageReadingDifference: string
    readThisMonth: string
    readingPeriod: string
    currentValue: string
    previousValue: string
    id: string
    unitId: string
    propertyId: string
}

export interface DashboardMetersTableValues extends DashboardMetersTableValuesCommon {
    id: number
    averageReadingDifference: number
    readThisMonth: boolean
    readingPeriod: number[]
    currentValue: number
    previousValue: number
    unitId: number
    propertyId: number
}

export interface DashboardMetersTableDto extends DashboardMetersTableCommon {
    values: DashboardMetersTableValuesDto[]
}

export interface DashboardMetersTable extends DashboardMetersTableCommon {
    values: DashboardMetersTableValues[]
}

export interface DashboardChartValueDto {
    dateTime: string
    value: string
}

export interface DashboardChartValue {
    dateTime: DateTime
    value: string
}

export interface RentingPeriodDto extends RentingPeriodBase {
    invitedDate?: string
    acceptedDate?: string
    moveInDate: string
    moveOutDate: string
    removeTenantAccessAt?: string
}

export enum RentingPeriodUserAcceptanceStatus {
    None = 0,
    Accepted = 1,
    Declined = 2,
    AcceptedButAccessRemoved = 3,
}
export enum RentingPeriodMailNotificationStatus {
    None,
    Notified,
    NotificationFailed,
}

export enum IncomeStatus {
    Waiting,
    Accepted,
    Denied,
    AcceptedFromExternalSystem,
    CardPaymentWaiting,
    CardPaymentFailed,
    CardPaymentSucceeded,
}
export interface PropertyIncomeBase {
    id: number
    canModify: boolean
    type: PaymentType
    files: PropertyFileDto[]
    propertyId: number
    propertyName: string
    paymentMethod: PropertyIncomePaymentMethod
    associatedExpenses?: {
        id: number
        name: string
    }[]
    associatedExpensesCount: number
    rentingPeriodId?: number
    value: number
    currencyId: number
    status: IncomeStatus
    createdBy: string
}

export interface PropertyIncomeDto extends PropertyIncomeBase {
    date: string
}

export interface PropertyIncome extends PropertyIncomeBase {
    date: DateTime
}

export interface ExpenseLateFeesBase {
    percentage: number
    currencyId: number
}

export interface LateFeesPeriodBase {
    amount: number
    expenseRemainingAmount: number
    amountNotCompounded: number
    expenseRemainingAmountNonCompounded: number
}

export interface LateFeesPeriodDto extends LateFeesPeriodBase {
    endDate: string
    startDate: string
}

export interface LateFeesPeriod extends LateFeesPeriodBase {
    endDate: DateTime
    startDate: DateTime
}

export interface AlreadyGeneratedLateFeesExpensesBase {
    amount: number
    currencyId: number
    amountInMainExpenseCurrency: number
}

export interface AlreadyGeneratedLateFeesExpensesDto extends AlreadyGeneratedLateFeesExpensesBase {
    date: string
}

export interface AlreadyGeneratedLateFeesExpenses extends AlreadyGeneratedLateFeesExpensesBase {
    date: DateTime
}

export interface ExpenseLateFeesDto extends ExpenseLateFeesBase {
    lateFees: LateFeesPeriodDto[]
    alreadyGeneratedExpenses: AlreadyGeneratedLateFeesExpensesDto[]
}

export interface ExpenseLateFeesResponse extends ExpenseLateFeesBase {
    lateFees: LateFeesPeriod[]
    alreadyGeneratedExpenses: AlreadyGeneratedLateFeesExpenses[]
}

export enum PaymentType {
    Expense,
    Rent,
    SecurityDeposit,
    Fees,
    Damages,
}

export enum PaymentSubtype {
    None = 0,
    Gas = 1,
    Electricity = 2,
    Association = 3,
    Water = 4,
    Parking = 5,
    Sanitation = 6,
    InternetTV = 7,
}

export enum PropertyIncomePaymentMethod {
    Cash,
    Bank,
    Online,
}

export interface RentingPeriod extends RentingPeriodBase {
    invitedDate?: DateTime
    acceptedDate?: DateTime
    moveInDate: DateTime
    moveOutDate: DateTime
    removeTenantAccessAt?: DateTime
}

export interface RentingPeriodUserBase {
    name: string
    mail: string
    status: RentingPeriodUserAcceptanceStatus
}
export interface RentingPeriodUserDto extends RentingPeriodUserBase {
    date: string
}

export interface RentingPeriodUser extends RentingPeriodUserBase {
    date: DateTime
}

export interface RentingPeriodMailNotificationBase {
    id: number
    mail: string
    status: RentingPeriodMailNotificationStatus
}
export interface RentingPeriodMailNotificationDto extends RentingPeriodMailNotificationBase {
    lastTry: string
}

export interface RentingPeriodMailNotification extends RentingPeriodMailNotificationBase {
    lastTry: DateTime
}

export interface RentingPeriodDetailsBase {
    id: number
    value: number
    currencyId: number
    securityDeposit: number
    securityDepositCurrencyId: number
    bankName: string
    bankAccount: string
    propertyId: number
    isArchived: boolean
    paymentPeriod: number[]
    totalFiles: number
    totalExpenses: number
    totalIncome: number
    enabledNotifications: number[]
    rentingPeriodStatus: RentingPeriodStatusCode
    enableExternalSystemAccess: boolean
    externalSystemId: string
    showExpirationForm: boolean
    daysUntilExpirationToShowExpirationForm: number
    isRentExpenseAutomatic: boolean
    acceptPartialPayments: boolean
    invoicingLanguage?: string
    defaultInvoicingTemplate?: string
    invoicingCurrencyId?: number
    defaultInvoicingText?: string
    lateFeesPercentage: number
    invoicingClientId?: number
    invoiceMaxDays?: number
    invoicingCompany?: {
        id: number
        name: string
        isVatPayer: boolean
    }
    invoicingSeries?: {
        id: number
        name: string
    }
    invoicingVAT?: {
        id: number
        name: string
    }
    lateFeesVAT?: {
        id: number
        name: string
    }
    lateFeesSeries?: {
        id: number
        name: string
    }
    reinvoicingSeries?: {
        id: number
        name: string
    }
    reinvoicingVAT?: {
        id: number
        name: string
    }
    invoicingPeriodStart: number
    invoicingPeriodEnd: number
    reinvoicingPercentage: number
    defaultReinvoicingText: string
    sendToSPV: boolean
    removeCIF: boolean
    createInvoice: boolean
    createInvoiceDay?: number
    paymentNotificationVariables: NotificationTemplateVariable[]
    paymentNotificationTemplateId?: number
    scheduleMaintenanceAtHalfPeriod: boolean
    isC168Sent?: boolean
    automaticExpenseCurrencyId?: number
}

export interface RentingPeriodDetailsDto extends RentingPeriodDetailsBase {
    invitedDate: string
    moveInDate: string
    moveOutDate: string
    createdAt: string
    removeTenantAccessAt?: string
    invitedUsers: RentingPeriodMailNotificationDto[]
    users: RentingPeriodUserDto[]
}

export interface RentingPeriodDetails extends RentingPeriodDetailsBase {
    invitedDate: DateTime
    moveInDate: DateTime
    moveOutDate: DateTime
    createdAt: DateTime
    removeTenantAccessAt?: DateTime
    invitedUsers: RentingPeriodMailNotification[]
    users: RentingPeriodUser[]
}

export interface RentingPeriodSummaryData {
    numberOfExpenses: number
    numberOfIncome: number
    numberOfExpenseFiles: number
    numberOfIncomeFiles: number
    numberOfMeterValues: number
}

export enum AppNotifications {
    FirstPropertyCreated = 0,
    RentingPeriodCreatedTenant = 1,
    RentingPeriodCreatedOwner = 2,
    RentingPeriodAcceptedTenant = 3,
    RentingPeriodAcceptedOwner = 4,
    RentingPeriodDeclinedTenant = 5,
    RentingPeriodDeclinedOwner = 6,
    RentingPeriodEdited = 7,
    ExpenseCreatedAssignedToTenant = 8,
    ExpenseEditedAssignedToTenant = 9,
    ExpenseDeletedAssignedToTenant = 10,
    MeterAddedTenant = 11,
    MeterReadingAddedByOwner = 12,
    MeterEdited = 13,
    MeterDeleted = 14,
    MeterReadingPeriodStarted = 15,
    MeterReadingPeriodEnding = 16,
    RentingPeriodPaymentPeriodStarted = 17,
    RentingPeriodPaymentPeriodEnding = 18,
    NewUserNotification = 19,
    RentingPeriodAcceptedContractNotificationForOwner = 20,
    RentingPeriodAcceptedContractNotificationForTenant = 21,
    AccountCreated = 22,
    TrialEndingNotification = 23,
    TaskEndedNotification = 24,
    IncomeWithExpenseAddedByTenant = 25,
    IncomeWithExpenseAddedByOwner = 26,
    IncomeAddedByTenant = 27,
    InsuranceEnding = 28,
    UserHelp = 29,
    DocumentAddedByOwnerNotification = 30,
    SupportContactNotification = 31,
    TenantNewNoteAdded = 32,
    ExpenseDueDateNotification = 33,
    RecurringExpenseNoTenantNotification = 34,
    MeterReadingAddedByTenant = 35,
    VerificationCodeNotification = 36,
    InviteOwnerNotification = 37,
    ManagementRequestAcceptedForInviter = 38,
    ManagementRequestDeclinedNotificationForInviter = 39,
    ManagersNotificationWhenNewManagerDeclinedNotification = 40,
    ManagersNotificationWhenNewManagerAcceptedNotification = 41,
    ManagersNotificationWhenManagerWasRemovedNotification = 42,
    ManagerWasRemovedNotification = 43,
    ManagersNotificationWhenManagerWasInvitedNotification = 44,
    CustomMessageNotification = 45,
    NewProviderNotification = 46,
    PendingExpenseCreatedNotification = 47,
    DeletedRentingPeriodDataDownloadNotification = 48,
    ConfiguredProviderRejectedNotification = 49,
    ExpenseOverdueNotification = 50,
    TaskCreated = 51,
    TaskDeleted = 52,
    NewTaskComment = 53,
    TaskStatusChanged = 54,
    MonthlyPropertyBalanceNotification = 55,
    TenantMonthlyReport = 56,
    RentingPeriodExpirationFormResponse = 57,
    RentingPeriodEndingSoonOwner = 58,
    MeterSendingFailed = 59,
    MaintenanceComingSoon = 60,
    MaintenanceOverdue = 61,
    ProviderIncomeAcceptedForOwnerNotification = 72,
}

export enum InvitedManagerStatus {
    CREATED,
    NOTIFIED,
    NOTIFICATION_FAILED,
}

export enum ManagerAcceptanceStatus {
    NONE,
    ACCEPTED,
    REJECTED,
}
export interface ManagerBase {
    mail: string
    name: string
    ownershipType: ManagerType
    canDelete: boolean
    status: ManagerAcceptanceStatus
    isFromGroup: boolean
}

export interface ManagerDto extends ManagerBase {
    date: string
}
export interface Manager extends ManagerBase {
    date: DateTime
}

export interface InvitedManagersBase {
    mail: string
    ownershipType: ManagerType
    status: InvitedManagerStatus
}

export interface InvitedManagersDto extends InvitedManagersBase {
    lastTry: string
}
export interface InvitedManager extends InvitedManagersBase {
    lastTry: DateTime
}

export interface MarketingSettings {
    utilities: Utilities[]
    features: Features[]
    presentationFiles: PresentationFile[]
    isListed?: boolean
    description?: string
    desiredPrice?: number
    desiredPriceCurrencyId?: number
    firstName?: string
    lastName?: string
    email?: string
    phoneNumber?: string
}

export interface PropertyDataBase extends PropertyDetails {
    lat: number
    lng: number
    images: string[]
    furniture: Furniture
    hasHeater: boolean
    hasAC: boolean
    totalStories: number
    hasDishwasher: boolean
    tvType: TVType
    isDetached: boolean
    isListed: boolean
    isOccupied: boolean
    rentDay: number
    bankName: number
    iban: number
    rentPrice: number
    currencyCode: Currencies
    totalIncome: number
    totalExpenses: number
    rentPaymentPeriod: number[]
    name: string
    paymentAutoAcceptType: PropertyAutoAcceptType
    numberOfConfiguredProviders: number
    numberOfMeters: number
    numberOfRecurringExpenses: number
    numberOfConfiguredMeterProviders: number
    numberOfNotes: number
    marketingSettings?: MarketingSettings
    acceptOnlineCardPayments: boolean
}

export interface PropertyData extends PropertyDataBase {
    invitedUsers: InvitedManager[]
    users: Manager[]
}

export interface PropertyDataDto extends PropertyDataBase {
    invitedUsers: InvitedManagersDto[]
    users: ManagerDto[]
}

export enum InsuranceType {
    Mandatory,
    Optional,
}

export interface PropertyInsuranceBase {
    id: number
    value: number
    currencyId: number
    insuranceType: InsuranceType
    insurer: string
    shouldNotifyForRenewal: boolean
    propertyId: number
    expenseId?: number
    files?: PropertyFileDto[]
}

export interface PropertyInsuranceDto extends PropertyInsuranceBase {
    startDate: string
    endDate: string
}

export interface PropertyInsurance extends PropertyInsuranceBase {
    startDate: DateTime
    endDate: DateTime
}

export enum AddPropertyPage {
    Name,
    Details,
    Location,
}

export enum Utilities {
    Gaz = 0,
    CATV = 1,
    Telefon = 2,
    TelefonInternational = 3,
    SistemIncalzire = 4,
    Termoficare = 5,
    CentralaImobil = 6,
    CentralaProprie = 7,
    Curent = 8,
    Climatizare = 9,
    Calorifere = 10,
    SobaTeracota = 11,
    IncalzirePrinPardoseala = 12,
    FibraOptica = 13,
    Cablu = 14,
    Wireless = 15,
    DialUp = 16,
    Ventiloconvertoare = 17,
    AerConditionat = 18,
    Aeroterme = 19,
    Apa = 20,
    Canalizare = 21,
    PanouriInfrarosu = 22,
    ConverctorGaz = 23,
}
export enum Surroundings {}
export enum Features {
    Videointerfon = 0,
    SpatiiAgrement = 1,
    FierDeCalcat = 2,
    Sauna = 3,
    SPA = 4,
    Cafetiera = 5,
    UscatorDePar = 6,
    TV = 7,
    BucatarieMobilata = 8,
    BucatariePartialMobilata = 9,
    BucatarieUtilata = 10,
    BucatariePartialUtilata = 11,
    LocuintaCompletMobilata = 12,
    LocuintaPartialMobilata = 13,
    LocuintaNemobilata = 14,
    LocuintaMobilataLux = 15,
    Toaster = 16,
    DVD = 17,
    MasinaDeSpalatRufe = 18,
    SandwichMaker = 19,
    Frigider = 20,
    CuptorCuMicounde = 21,
    Aragaz = 22,
    Hota = 23,
    MasinaDeSpalatVase = 24,
    RobotBucatarie = 25,
    Aspirator = 26,
    Apometre = 27,
    ContorCaldura = 28,
    ContorGaz = 29,
    Jacuzzi = 30,
    ScaraInterioara = 31,
    Semineu = 32,
    SenzoriDeFum = 33,
    SistemDeAlarma = 34,
    TelecomandaPoartaGaraj = 35,
    TelecomandaPoartaAccesAuto = 36,
    Acoperis = 37,
    Curte = 38,
    CurteComuna = 39,
    Gradina = 40,
    Interfon = 41,
    Lift = 42,
    PiscinaInterioara = 43,
    PiscinaExterioara = 44,
    Uscatorie = 45,
    Terasa = 46,
    WCServiciu = 47,
    BoxaSubsol = 48,
    Debara = 49,
}

interface AddPropertyState {
    details: PropertyDetails | undefined
    photos: CustomFile[]
    location: LatLng | undefined
    activeStep: AddPropertyPage
    propertyName: string | undefined
    labelId: number | undefined
}

const initialState: AddPropertyState = {
    details: undefined,
    photos: [],
    location: undefined,
    activeStep: AddPropertyPage.Name,
    propertyName: undefined,
    labelId: undefined,
}

const addPropertySlice = createSlice({
    name: "addProperty",
    initialState,
    reducers: {
        setPropertyDetails: (state, action: PayloadAction<PropertyDetails | undefined>) => {
            state.details = action.payload
            state.activeStep = 2
        },
        setActiveStep: (state, action: PayloadAction<number>) => {
            state.activeStep = action.payload
        },
        setPhotos: (state, action: PayloadAction<CustomFile[]>) => {
            state.photos = action.payload
        },
        setLocation: (state, action: PayloadAction<LatLng | undefined>) => {
            state.location = action.payload
        },
        setPropertyName: (state, action: PayloadAction<string>) => {
            state.propertyName = action.payload
            state.activeStep = 1
        },
        setLabelId: (state, action: PayloadAction<number | undefined>) => {
            state.labelId = action.payload
            state.activeStep = 1
        },
    },
})

export const { setPropertyDetails, setPhotos, setLocation, setActiveStep, setPropertyName, setLabelId } = addPropertySlice.actions
export default addPropertySlice.reducer
