import { array, boolean, mixed, number, object, string } from "yup"
import { emptyStringToNull } from "../dialogs/add-edit-property/utils"
import { Furniture, TVType } from "rentzz"
import { Partition } from "../sections/propertyDetails/marketing/addNewAd/details/Details"

export const AdDataSchema = object().shape({
    title: string().required("required"),
    county: string().required("required"),
    city: string().required("required"),
    currencyId: number().required("required"),
    propertyId: number().required("required"),
    address: string().required("required"),
    rentPrice: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    size: number()
        .required("required")
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .min(10, "at_least_10mp")
        .max(300, "at_most_300mp")
        .transform(emptyStringToNull),
    partition: mixed<Partition>().optional(),
    floor: string().optional(),
    furniture: mixed<Furniture>().optional(),
    rooms: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    bathrooms: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    tv: mixed<TVType>().optional(),
    hasHeater: boolean().required("required"),
    hasAC: boolean().required("required"),
    hasFridge: boolean().required("required"),
    hasDishwasher: boolean().required("required"),
    hasWashingMachine: boolean().required("required"),
    hasHood: boolean().required("required"),
    hasElevator: boolean().required("required"),
    hasDoubleGlazing: boolean().required("required"),
    hasInsulation: boolean().required("required"),
    description: string().optional(),
    phone: string().optional().required("required"),
    photos: array().required("required"),
})
