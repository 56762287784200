import { array, boolean, number, object, string } from "yup"

export const AddDocumentSchema = object().shape({
    files: array().required().min(1, "min_files_required").max(1),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    name: string().required("required"),
    rentingPeriodId: number().required("required"),
    notifyOtherParty: boolean().required("required"),
})
