import { CommentType, Iconify, useLocales } from "rentzz"
import { Avatar, Button, IconButton, Stack, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { deleteTaskCommentMutation } from "../../../mutations/kanban"
import { fToNow, stringAvatar } from "../utils"
import { useTheme } from "@mui/material/styles"
import { getReverseTextColor } from "../../../utils/helpers"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { PropertyFileType } from "../../../redux/slices/AddProperty"
import { useUserDataQueryFn } from "../../../queries/userData"

interface Props {
    comment?: CommentType
    sectionId?: string
    canEdit?: boolean
}

export default function KanbanDetailsComment({ comment, sectionId, canEdit }: Props) {
    const theme = useTheme()
    const { data: user } = useUserDataQueryFn()
    const { currentLang, translate } = useLocales()
    const dispatch = useDispatch()
    const { editingItem } = useSelector((state) => state.appState)

    const handleViewFiles = useCallback(() => {
        if (comment == null) return
        dispatch(
            setEditingItem({
                ...editingItem,
                taskCommentId: comment.id,
                type: PropertyFileType.TaskComment,
                canWrite: comment.canAddFiles,
            }),
        )
        dispatch(setModalOpen(AppModals.EntityFileManager))
    }, [editingItem, comment])
    const { mutateAsync: deleteComment, isPending } = deleteTaskCommentMutation()

    const onDelete = useCallback(async () => {
        if (comment == null || sectionId == null) return
        await deleteComment({ sectionId, taskId: comment.taskId, commentId: comment.id })
    }, [deleteComment, comment, sectionId])

    if (comment == null) return

    return (
        <Stack direction='row' spacing={2}>
            <Avatar
                {...stringAvatar(`${comment.createdBy}`)}
                sx={{ backgroundColor: theme.palette.primary.main, color: getReverseTextColor(theme.palette.primary.main) }}
            />

            <Stack spacing={0.5} flexGrow={1}>
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Typography variant='subtitle2'> {comment.createdBy}</Typography>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography variant='caption' sx={{ color: "text.disabled" }}>
                            {fToNow(comment.createdAt, currentLang.value)}
                        </Typography>
                        {canEdit && user?.isTicketsOnly === false && (
                            <IconButton size='small' color={"error"} onClick={onDelete} disabled={isPending}>
                                <Iconify icon={"mdi:delete"} sx={{ width: 18 }} />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>

                <Typography variant='body2'>{comment.text}</Typography>
                <Button color='primary' variant='text' onClick={handleViewFiles}>
                    {translate("view_files", { numberOfFiles: comment.files.length })}
                </Button>
            </Stack>
        </Stack>
    )
}
