import { Iconify, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import React from "react"
import { RoleName, useUserBankAccountsQuery } from "../../../queries/userData"
import { DateTime } from "luxon"
import { Box, Card, CardHeader, Divider, Typography } from "@mui/material"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { AppModals, setModalOpen } from "../../../redux/slices/App"
import NoDataPage from "../../../components/NoDataPage"
import RoleLockedButton from "../../../components/RoleLockedButton"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"

export default function PropertyBankAccountsTable() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { canWrite } = usePermissions(PermissionType.Properties, currentPropertyId)
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: bankAccounts } = useUserBankAccountsQuery()

    return (
        <Card sx={{ width: "100%", minHeight: 400 }}>
            <CardHeader
                title={translate("assigned_bank_accounts")}
                titleTypographyProps={{ variant: "subtitle1" }}
                sx={{ p: 2, color: theme.palette.grey.A700 }}
                action={
                    canWrite && (
                        <RoleLockedButton
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.LinkBankAccountToProperty))
                            }}
                            buttonText={
                                <>
                                    <Iconify icon={"mdi:add"} />
                                    <Typography>{translate("add_bank_account")}</Typography>
                                </>
                            }
                            blockedRoles={[RoleName.FREE]}
                            variant='text'
                        />
                    )
                }
            />
            <Divider />

            {bankAccounts?.count === 0 && <NoDataPage noDataText={"no_bank_accounts"} />}

            {isMobile ? (
                <Box display={"flex"} flexDirection={"column"} gap={1} paddingY={1}>
                    {bankAccounts?.items?.map((bankAccount) => (
                        <CustomMobileCard
                            key={bankAccount.id}
                            item={bankAccount}
                            icon={"mdi:bank"}
                            iconColor={theme.palette.primary.main}
                            title={bankAccount.holder}
                            subtitle={bankAccount.iban}
                            rightFirstItem={translate(`bank-name-${bankAccount.bank}`)}
                            rightSecondItem={getCurrencyFromId(currencyData, bankAccount.currencyId).code}
                        />
                    ))}
                </Box>
            ) : (
                <Box sx={{ overflowY: "auto", maxHeight: isMobile ? undefined : 300 }}>
                    <Box display={"flex"} flexDirection={"column"} gap={1} padding={1}>
                        {bankAccounts?.items?.map((bankAccount) => (
                            <CustomMobileCard
                                key={bankAccount.id}
                                item={bankAccount}
                                icon={"mdi:bank"}
                                iconColor={theme.palette.primary.main}
                                title={bankAccount.holder}
                                subtitle={bankAccount.iban}
                                rightFirstItem={translate(`bank-name-${bankAccount.bank}`)}
                                rightSecondItem={getCurrencyFromId(currencyData, bankAccount.currencyId).code}
                                firstDate={`${translate("createdAt")}: ${bankAccount.createdAt.toLocaleString(DateTime.DATE_SHORT)}`}
                                secondDate={`${translate("lastModifiedAt")}: ${bankAccount.lastModifiedAt.toLocaleString(DateTime.DATE_SHORT)}`}
                            />
                        ))}
                    </Box>
                </Box>
            )}
        </Card>
    )
}
