import { CustomDialog, DetailsSheet, ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import { useSingleIncomeQuery } from "../../../queries/income"
import * as jsonpatch from "fast-json-patch"
import { updateIncomeMutation } from "../../../mutations/incomes"
import { addPaymentMutation } from "../../../mutations/payments"
import { getIncomeOrPaymentStatusColor } from "../../../utils/helpers"
import { useTheme } from "@mui/material/styles"
import { useDispatch } from "react-redux"
import { PropertyExpense, useSingleExpenseQuery } from "../../../queries/expenses"
import AddPaymentForm, { AddPaymentRequest } from "../../../sections/tenantContext/payment/AddPaymentForm"
import { Box, CircularProgress } from "@mui/material"

interface Props {
    onClose: () => void
}
export default function AddOrEditPaymentGuard({ onClose }: Props) {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateIncome } = updateIncomeMutation()
    const { mutateAsync: addPayment } = addPaymentMutation()
    const { data: currentPayment, isPending: isCurrentPaymentLoading } = useSingleIncomeQuery(editingItem?.id)
    const isLinkingExpense = useMemo(() => modalOpen === AppModals.LinkExpenseToIncomeAsTenant, [modalOpen])
    const { data: currentExpense, isPending: isCurrentExpenseLoading } = useSingleExpenseQuery(editingItem?.id)

    const getTitle = useCallback(() => {
        if (currentPayment && !isLinkingExpense) return "income-table.editPayment"

        return "payment.addPayment"
    }, [currentPayment, isLinkingExpense])

    const onPaymentSave = useCallback(
        async (data: AddPaymentRequest) => {
            if (currentPayment != null && modalOpen !== AppModals.LinkExpenseToIncomeAsTenant) {
                const operations = jsonpatch.compare(
                    { ...currentPayment, files: [] },
                    { ...currentPayment, ...data, files: [], referenceId: undefined },
                )

                if (!currentPayment.id) return
                await updateIncome({
                    propertyId: currentPayment.propertyId,
                    incomeId: currentPayment.id,
                    operations,
                })
            } else {
                await addPayment({ data })
                if (isMobile) {
                    dispatch(setModalOpen(AppModals.ExpenseDetails))
                    dispatch(setEditingItem({ backgroundColor: editingItem?.backgroundColor, id: currentExpense?.id }))
                } else {
                    dispatch(setModalOpen(undefined))
                    dispatch(setEditingItem(undefined))
                }
            }
        },
        [currentPayment, modalOpen, updateIncome, addPayment, isMobile, dispatch, currentExpense],
    )

    const handleClose = useCallback(() => {
        if (modalOpen === AppModals.LinkExpenseToIncomeAsTenant) {
            if (editingItem?.choosePayment) {
                dispatch(setModalOpen(AppModals.TenantPayment))
            } else {
                dispatch(setModalOpen(AppModals.ExpenseDetails))
            }
            dispatch(setErrorMessage(undefined))
            dispatch(setEditingItem({ backgroundColor: editingItem?.backgroundColor, id: currentExpense?.id }))
        } else onClose()
    }, [modalOpen, editingItem, currentExpense])

    return (
        <>
            <DetailsSheet
                isOpen={
                    isMobile &&
                    (modalOpen === AppModals.AddPayment || modalOpen === AppModals.EditPayment || modalOpen === AppModals.LinkExpenseToIncomeAsTenant)
                }
                onClose={handleClose}
                isLoading={modalOpen !== AppModals.AddPayment && currentPayment == null && currentExpense == null}
                title={translate(getTitle(), {
                    expenseName: (currentPayment as unknown as PropertyExpense)?.name,
                })}
                headerColor={
                    currentPayment
                        ? getIncomeOrPaymentStatusColor(currentPayment.status, theme)
                        : currentExpense?.assignee === ExpenseAssignee.ME
                          ? theme.palette.primary.main
                          : editingItem?.backgroundColor
                }
            >
                <AddPaymentForm
                    defaultValues={modalOpen === AppModals.EditPayment ? currentPayment : (currentExpense ?? undefined)}
                    onClose={
                        modalOpen === AppModals.LinkExpenseToIncomeAsTenant
                            ? () => {
                                  if (editingItem?.choosePayment) {
                                      dispatch(setModalOpen(AppModals.TenantPayment))
                                  } else {
                                      dispatch(setModalOpen(AppModals.ExpenseDetails))
                                  }
                                  dispatch(setErrorMessage(undefined))
                                  dispatch(setEditingItem({ backgroundColor: editingItem?.backgroundColor, id: currentExpense?.id }))
                              }
                            : onClose
                    }
                    onSave={onPaymentSave}
                    isLinkingExpense={modalOpen === AppModals.LinkExpenseToIncomeAsTenant}
                />
            </DetailsSheet>

            <CustomDialog
                title={translate(getTitle(), {
                    expenseName: (currentPayment as unknown as PropertyExpense)?.name,
                })}
                open={
                    !isMobile &&
                    (modalOpen === AppModals.AddPayment || modalOpen === AppModals.EditPayment || modalOpen === AppModals.LinkExpenseToIncomeAsTenant)
                }
                onClose={onClose}
                maxWidth={"sm"}
                showCloseIcon
                titleAlign={"center"}
                isFullScreen={false}
            >
                {isCurrentPaymentLoading || isCurrentExpenseLoading ? (
                    <Box display={"flex"} justifyContent={"center"} height={300}>
                        <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <AddPaymentForm
                        defaultValues={modalOpen === AppModals.EditPayment ? currentPayment : (currentExpense ?? undefined)}
                        onClose={onClose}
                        onSave={onPaymentSave}
                        isLinkingExpense={modalOpen === AppModals.LinkExpenseToIncomeAsTenant}
                    />
                )}
            </CustomDialog>
        </>
    )
}
