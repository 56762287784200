import { useForm } from "react-hook-form"
import { CustomPopover, FormProvider, RHFTextField, useLocales, UsePopoverReturn, useSnackbar } from "rentzz"
import * as React from "react"
import { useCallback, useRef } from "react"
import { LoadingButton } from "@mui/lab"
import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import Api from "../../../api/Api"
import { Box } from "@mui/material"

interface GenerateDocumentWithAIProps {
    pRef: RichTextEditorComponent | null
    popover: UsePopoverReturn
    setContent: (content: string | null) => void
    onEnd: () => void
}

const GenerateDocumentWithAI = ({ popover, pRef, setContent, onEnd }: GenerateDocumentWithAIProps) => {
    const methods = useForm({
        defaultValues: {
            description: "",
        },
    })
    const { handleSubmit } = methods
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar()
    const aiDescription = useRef<string>("")
    const onSubmit = useCallback(
        (data: { description: string }) => {
            enqueueSnackbar(translate("content_is_generated"), { variant: "info" })
            popover.onClose()
            setContent(null)
            fetchEventSource(Api.API + `/general-documents/generate-document-ai?prompt=${data.description}`, {
                credentials: "include",
                onopen: async (_: Response) => {
                    aiDescription.current = ""
                },
                onmessage: (ev) => {
                    if (ev.data.startsWith("error_")) {
                        enqueueSnackbar(ev.data.replace("error_", ""), { variant: "error" })
                    } else {
                        aiDescription.current += ev.data.replace("data: ", "").replace("\n\n", "").replaceAll("\\n", "\n")
                        setContent(aiDescription.current.replace("```html", "").replace("```", ""))
                        pRef?.refreshUI()
                    }
                },
                onclose: async () => {
                    onEnd()
                },
                onerror: (_) => {
                    onEnd()
                },
            })
        },
        [handleSubmit, pRef, onEnd, enqueueSnackbar],
    )

    return (
        <CustomPopover
            open={popover.open}
            slotProps={{
                arrow: { placement: "top-center" },
            }}
            anchorEl={popover.anchorEl}
            onClose={popover.onClose}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box display={"flex"} flexDirection='column' height={160} pt={2} width={300} gap={2}>
                    <RHFTextField
                        name={"description"}
                        label={translate("document_description")}
                        multiline
                        rows={3}
                        placeholder={translate("describe_document_placeholder")}
                    />
                    <LoadingButton color='primary' type='submit' variant='contained'>
                        {translate("generate")}
                    </LoadingButton>
                </Box>
            </FormProvider>
        </CustomPopover>
    )
}

export default GenerateDocumentWithAI
