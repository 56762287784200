import AddInvoicingConfigurationAccess from "./AddInvoicingConfigurationAccess"
import DeleteInvoicingConfigurationAccessGuard from "./DeleteInvoicingConfigurationAccessGuard"

const InvoicingConfigurationsGuard = () => {
    return (
        <>
            <AddInvoicingConfigurationAccess />
            <DeleteInvoicingConfigurationAccessGuard />
        </>
    )
}

export default InvoicingConfigurationsGuard
