export const toolbarSettings = {
    type: "MultiRow",
    items: [
        "Bold",
        "Italic",
        "Underline",
        "StrikeThrough",
        "FontName",
        "FontSize",
        "FontColor",
        "BackgroundColor",
        "LowerCase",
        "UpperCase",
        "|",
        "Formats",
        "Alignments",
        "OrderedList",
        "UnorderedList",
        "Outdent",
        "Indent",
        "|",
        "CreateLink",
        "|",
        "ClearFormat",
        "|",
        "Undo",
        "Redo",
    ],
}
