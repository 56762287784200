import React, { useMemo, useState } from "react"
import { styled, useTheme } from "@mui/material/styles"
import { Box, Checkbox, Link, Paper, Typography } from "@mui/material"
import { AccountPopover, DashboardContext, Iconify, LanguagePopover, LayoutOption, Page, useIsMobile, useLocales, UserContextProvider } from "rentzz"
import { RoleName, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { LoadingButton } from "@mui/lab"
import { AppModals, setContext, setCurrentPropertyId, setCurrentRentingPeriodId, setModalOpen } from "../../redux/slices/App"
import { useDispatch } from "../../redux/store"
import { useLogout } from "../../mutations/user"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { useChangeLanguage } from "../../utils/useChangeLanguage"

interface Props {
    title: string
    consultText: string
    consultURL: string | number | boolean
    acceptanceText: string
    buttonText: string
    onAccept: () => Promise<unknown>
    isLoading: boolean
    agreeWithTermsCheckTitle: string
}

export default function AcceptPage({
    title,
    acceptanceText,
    consultText,
    buttonText,
    consultURL,
    onAccept,
    isLoading,
    agreeWithTermsCheckTitle,
}: Props) {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const dispatch = useDispatch()
    const { mutate: signOut } = useLogout()
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const policiesPageUrl = useFeatureValue(UserFlags.PoliciesPageUrl.toString(), "")
    const { handleLangOption } = useChangeLanguage()
    const [isChecked, setIsChecked] = useState<boolean>(false)

    const PaperStyle = useMemo(
        () =>
            styled(Paper)(({ theme }) => ({
                maxWidth: 500,
                width: isMobile ? "100%" : undefined,
                minWidth: isMobile ? undefined : 500,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(5, 3, 1),
                gap: 20,
            })),
        [isMobile],
    )

    const ContentStyle = useMemo(() => {
        return styled("div")(() => {
            return {
                margin: "auto",
                minHeight: "100vh",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                padding: theme.spacing(12, 0),
                textAlign: "center",
            }
        })
    }, [theme])

    const options = useMemo((): LayoutOption[] => {
        const toReturn = [
            {
                icon: <Iconify icon={"mdi:email"} />,
                label: translate("contact"),
                href: "/",
                onClick: () => dispatch(setModalOpen(AppModals.Contact)),
            },
            {
                icon: <Iconify icon={"mdi:text-box-outline"} />,
                label: translate("policies"),
                href: "/",
                onClick: () => window.open(policiesPageUrl),
            },
            {
                icon: <Iconify icon={"mdi:logout"} />,
                label: translate("logout"),
                href: "/",
                onClick: () => {
                    dispatch(setContext(undefined))
                    dispatch(setCurrentPropertyId(undefined))
                    dispatch(setCurrentRentingPeriodId(undefined))
                    signOut()
                },
            },
        ]

        if (policiesPageUrl.length === 0) {
            toReturn.splice(1, 1)
        }

        return toReturn
    }, [translate, signOut, dispatch, policiesPageUrl, isWhitelabel])

    const userDataConfig = useMemo((): DashboardContext | null => {
        if (userData == null) return null

        return {
            firstName: userData.firstName,
            lastName: userData.lastName,
            fullName: `${userData?.firstName} ${userData?.lastName}`,
            email: userData.email ?? "",
            currency: userData.currency,
            availableCurrencies: [],
            onLanguageChange: handleLangOption,
            options,
            roleName: isWhitelabel ? undefined : userData?.roles.roleName,
            validUntil: isWhitelabel || userData?.roles.roleName === RoleName.FREE ? undefined : userData?.roles.to,
        }
    }, [userData, isWhitelabel, options, dispatch])

    if (userData == null) return null

    return (
        <Page title={translate(title)}>
            <ContentStyle>
                <PaperStyle elevation={5}>
                    <Box display='flex' justifyContent='flex-end' gap={1} alignItems='center'>
                        <UserContextProvider userData={userDataConfig}>
                            <>
                                <LanguagePopover />
                                <AccountPopover />
                            </>
                        </UserContextProvider>
                    </Box>
                    <Typography fontWeight={"bold"} fontSize={20} sx={{ color: theme.palette.grey.A700 }}>
                        {translate(title)}
                    </Typography>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography sx={{ color: theme.palette.grey.A700 }}>
                            {translate(consultText)}{" "}
                            <Link href={consultURL as string} rel='noreferrer' target='_blank'>
                                {translate("here").toLowerCase()}
                            </Link>
                            . <br />
                            <b>{translate(acceptanceText)}</b>
                        </Typography>
                    </Box>
                    <Box display={"flex"} gap={1} paddingLeft={0} alignItems={"center"} justifyContent={"center"}>
                        <Checkbox
                            checked={isChecked}
                            sx={{ padding: 0, height: "fit-content" }}
                            onChange={() => {
                                setIsChecked(!isChecked)
                            }}
                        />
                        <Typography sx={{ color: theme.palette.grey.A700 }}>{translate(agreeWithTermsCheckTitle)}</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            padding: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onAccept}
                            loading={isLoading}
                            disabled={!isChecked}
                            variant='contained'
                            color='primary'
                            sx={{ textTransform: "none", width: "100%" }}
                        >
                            <Typography>{translate(buttonText)}</Typography>
                        </LoadingButton>
                    </Box>
                </PaperStyle>
            </ContentStyle>
        </Page>
    )
}
