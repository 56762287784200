import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { FormProvider } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { AddGeneralDocumentSchema } from "../../../validations/notificationsTemplates"
import { RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor"
import { alpha, useTheme } from "@mui/material/styles"
import {
    getPdfMutation,
    updateNotificationMutation,
    updateNotificationTemplateContentMutation,
    useAddNotificationMutation,
} from "../../../mutations/notifications"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { NotificationTemplateDetails } from "../../../queries/userData"
import CustomAddDocumentFile from "../../../components/CustomAddDocumentFile"
import { AddNewDocumentRequest } from "../generalDocuments/AddOrEditGeneralDocument"

interface Props {
    defaultValues?: NotificationTemplateDetails
}
export default function AddOrEditNotificationTemplate({ defaultValues }: Props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const theme = useTheme()
    const pRef = useRef<RichTextEditorComponent>(null)
    const { mutateAsync: addNotification, isPending } = useAddNotificationMutation()
    const { mutateAsync: updateNotificationContent, isPending: isUpdateNotificationContentLoading } = updateNotificationTemplateContentMutation()
    const { mutateAsync: updateNotification, isPending: isUpdateNotification, isSuccess } = updateNotificationMutation()

    const [url, setUrl] = useState<string>("")
    const [showPreview, setShowPreview] = useState<boolean>(true)
    const { mutateAsync: getPdf, isPending: isGetPdfPending } = getPdfMutation()

    const methods = useForm<AddNewDocumentRequest>({
        resolver: yupResolver(AddGeneralDocumentSchema),
        mode: "onBlur",
        defaultValues: {
            name: "",
            content: "",
        },
    })

    const { handleSubmit, reset, setValue, getValues } = methods

    const getHTMLContent = useCallback(
        () =>
            (pRef.current?.getHtml() ?? defaultValues?.content ?? "").replace(
                /\{{([^}]+)\}}/g,
                (match, p1) =>
                    `<span style="font-weight:bold; background-color:${alpha(theme.palette.primary.main, 0.2)}; color:${
                        theme.palette.primary.main
                    }; border-radius: 4px; padding: 2px">${p1}</span>`,
            ),
        [pRef, defaultValues, theme],
    )

    const onLoad = useCallback(async () => {
        setShowPreview(true)
        const result = await getPdf({ body: getHTMLContent() })
        const url = window.URL.createObjectURL(new Blob([result]))
        setUrl(url)
        setShowPreview(false)
        setValue("content", pRef.current?.getHtml() ?? defaultValues?.content ?? "")
    }, [getPdf, setUrl, setShowPreview, defaultValues, getHTMLContent])

    useEffect(() => {
        if (defaultValues) {
            onLoad()
            reset({
                name: defaultValues.name,
                content: defaultValues.content,
            })
        } else {
            reset()
        }
    }, [defaultValues])

    const onSave = useCallback(
        async (data: AddNewDocumentRequest) => {
            if (defaultValues) {
                await updateNotificationContent({
                    notificationId: defaultValues.id,
                    content: data.content,
                })
            } else {
                await addNotification({ data })
                navigate("/document-templates/notification-templates")
            }
        },
        [addNotification, navigate, updateNotificationContent, defaultValues, setValue],
    )

    const onMobileSave = useCallback(async () => {
        const result = await getPdf({ body: getHTMLContent() })
        const url = window.URL.createObjectURL(new Blob([result]))
        dispatch(setModalOpen(AppModals.NotificationPreview))
        dispatch(setEditingItem({ pdfUrl: url }))
    }, [getPdf, getHTMLContent, dispatch])

    const onTextFieldBlur = useCallback(async () => {
        if (defaultValues) {
            if (getValues("name") === defaultValues.name) return
            await updateNotification({
                notificationId: defaultValues.id,
                operations: [{ op: "replace", path: "/name", value: getValues("name") }],
            })
        }
    }, [defaultValues, getValues, updateNotification])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <CustomAddDocumentFile
                url={url}
                pRef={pRef}
                isAddPending={isPending}
                isNameChangeSuccess={isSuccess}
                isNameChangeUpdating={isUpdateNotification}
                defaultValues={defaultValues}
                isPdfLoading={isGetPdfPending}
                isUpdateContentPending={isUpdateNotificationContentLoading}
                onMobileSave={onMobileSave}
                onReloadPdf={onLoad}
                onTextEditorFocus={() => setShowPreview(true)}
                onTextFieldBlur={onTextFieldBlur}
                showPreview={showPreview}
                name={"notification_name"}
                variables={defaultValues?.variables}
                itemId={defaultValues?.id}
            />
        </FormProvider>
    )
}
