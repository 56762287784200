import { FormProvider, RHFTextField, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import React, { useCallback } from "react"
import { object, string } from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button } from "@mui/material"

export interface AIPopoverContentProps {
    onGenerate: (prompt: string) => void
}

const AIPopoverContent = ({ onGenerate }: AIPopoverContentProps) => {
    const { translate } = useLocales()
    const methods = useForm({
        resolver: yupResolver(
            object().shape({
                prompt: string().min(10, "min10_character").required(),
            }),
        ),
        defaultValues: {
            prompt: "",
        },
    })
    const { handleSubmit } = methods
    const onSubmit = useCallback(
        (data: { prompt: string }) => {
            onGenerate(data.prompt)
        },
        [onGenerate],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' height={130} width={350} flexDirection={"column"} gap={2} pt={1}>
                <RHFTextField
                    name={"prompt"}
                    required
                    label={translate("notification_summary")}
                    placeholder={translate("notification_summary_placeholder")}
                />
                <Button type='submit' variant='contained' color='primary'>
                    {translate("generate_notification")}
                </Button>
            </Box>
        </FormProvider>
    )
}

export default AIPopoverContent
