import { Box, CircularProgress, MenuItem } from "@mui/material"
import { RHFSelect, useIsMobile, useLocales } from "rentzz"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import React from "react"
import { useCompanySeries, useCompanyVATs } from "../../../../../queries/invoicing-configuration"
import { useSelector } from "../../../../../redux/store"

export default function LateFeesConfiguration() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { data: allUserSeries } = useCompanySeries(rentingPeriodDetails?.invoicingCompany?.id)
    const { data: allUserVATs } = useCompanyVATs(rentingPeriodDetails?.invoicingCompany?.id)

    return (
        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
            <RHFSelect
                name={"lateFeesSeries"}
                size='small'
                variant={"outlined"}
                label={translate("invoicing_series")}
                disabled={!canWrite || allUserSeries == null || allUserSeries.length === 0}
                fullWidth
            >
                {allUserSeries ? (
                    allUserSeries.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                            {c.name}
                        </MenuItem>
                    ))
                ) : (
                    <CircularProgress />
                )}
            </RHFSelect>
            <RHFSelect
                name={"lateFeesVAT"}
                label={translate("TVA")}
                size='small'
                disabled={!canWrite || allUserVATs == null || allUserVATs.length === 0}
                fullWidth
            >
                {allUserVATs ? (
                    allUserVATs.map((c) => (
                        <MenuItem key={c.id} value={c.id}>
                            {c.percent} - {c.name}
                        </MenuItem>
                    ))
                ) : (
                    <CircularProgress />
                )}
            </RHFSelect>
        </Box>
    )
}
