import { NotificationTemplateVariable } from "../../../queries/userData"
import VariableContent from "./VariableContent"
import NoDataPage from "../../../components/NoDataPage"
import React from "react"
import { orderBy } from "lodash"

export interface DefaultVariablesEditProps {
    variables: NotificationTemplateVariable[]
    notificationId?: number
}

const DefaultVariablesEdit = ({ variables, notificationId }: DefaultVariablesEditProps) => {
    if (variables == null || variables.length === 0 || notificationId == null) {
        return <NoDataPage icon={"mdi:success-circle-outline"} noDataText={"no_variables"} />
    }
    return (
        <>
            {orderBy(variables ?? [], "pattern").map((v) => (
                <VariableContent
                    key={v.pattern}
                    variable={v}
                    variableIndex={variables.findIndex((v2) => v2.pattern === v.pattern)}
                    notificationId={notificationId}
                />
            ))}
        </>
    )
}

export default DefaultVariablesEdit
