import { Text, View } from "@react-pdf/renderer"
import React from "react"
import { useStyles } from "./useStyles"
import { useLocales } from "rentzz"

const OwnerContactDefaultInfo = () => {
    const { translate } = useLocales()
    const styles = useStyles()

    return (
        <View>
            <Text wrap style={styles.mainText}>
                <Text>___________</Text>
                <Text>{translate("contracts.address")} ___________</Text>
                <Text>{translate("contracts.county")} ___________ </Text>
                <Text>{translate("contracts.city")} ___________</Text>
                <Text>{translate("contracts.identitySeries")} ___________</Text>
                <Text>{translate("contracts.number")} ___________</Text>
                {translate("contracts.as")} <Text style={{ ...styles.boldFont, textTransform: "uppercase" }}>{translate("contracts.owner")}</Text>
            </Text>
        </View>
    )
}

export default OwnerContactDefaultInfo
