import { Notes, useNotesQuery } from "../../../../../../queries/notes"
import NoDataPage from "../../../../../../components/NoDataPage"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../../redux/slices/App"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "../../../../../../redux/store"
import { GridActionsCellItem, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro"
import { AppContext, CustomDataGrid, useIsMobile, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { useUserPropertiesQuery } from "../../../../../../queries/userData"
import ActionWithTooltip from "../../../../../../utils/ActionWithTooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import { DESKTOP_ICON_SIZE } from "../../../../../../utils/helpers"
import { Typography } from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../../../../redux/slices/AddProperty"
import { PermissionType, usePermissions } from "../../../../../../hooks/usePermissions"
import CustomToolbar from "../../../../../../components/CustomToolbar"

export default function DesktopNotesList() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { context } = useSelector((state) => state.appState)
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "addedAt", sort: "desc" }])
    const { data: userNotes, isFetching } = useNotesQuery(paginationModel.page, paginationModel.pageSize, sortModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const isMobile = useIsMobile()
    const { canWrite } = usePermissions(PermissionType.Notes)

    const notesColumns = useMemo(() => {
        const columns: GridColDef<Notes>[] = [
            {
                field: "title",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("title"),
            },
            {
                field: "text",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("text"),
                renderCell: (v) =>
                    ActionWithTooltip({
                        element: <Typography fontSize={14}>{v.value.substring(0, 25)}</Typography>,
                        tooltipText: v.value.length > 25 ? v.value : "",
                    }),
            },

            {
                field: "addedAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("notes.addedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "updatedAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("notes.updatedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [userProperties, translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            type: "actions",
            minWidth: 190,
            flex: 0.5,
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={
                                params.row.isVisibleToTenant ? (
                                    <RemoveRedEyeIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <VisibilityOffIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )
                            }
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.noteId }))
                                dispatch(setModalOpen(AppModals.NoteStatus))
                            }}
                            label={translate("notes.isVisibleToTenant")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate(params.row.isVisibleToTenant ? "notes.isVisibleToTenant" : "notes.isNotVisibleToTenant"),
                    key: "isVisibleToTenant",
                    hidden: context === AppContext.Tenant || !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Note,
                                        name: params.row.title,
                                        canWrite: params.row.canWrite,
                                        canDelete: params.row.canDelete,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.noteId }))
                                dispatch(setModalOpen(AppModals.EditNote))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: context === AppContext.Tenant || !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.noteId }))
                                dispatch(setModalOpen(AppModals.DeleteNote))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden: context === AppContext.Tenant || !params.row.canDelete,
                }),
            ],
        }),
        [translate, dispatch, context],
    )

    return (
        <>
            {context === AppContext.Owner && canWrite && (
                <CustomToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddNote))}
                    numberOfItems={userNotes?.count}
                    buttonText={"notes.addNote"}
                />
            )}

            <CustomDataGrid
                paginationMode={"server"}
                isLoading={isFetching}
                rowsLoadingMode={"client"}
                columns={notesColumns}
                rows={userNotes?.items ?? []}
                components={{
                    noRowsOverlay: () => (
                        <NoDataPage
                            onAddNewItem={isMobile && context === AppContext.Owner ? () => dispatch(setModalOpen(AppModals.AddNote)) : undefined}
                            icon={"mdi:file-plus"}
                            noDataText={context === AppContext.Owner ? "notes.noNotes" : "notes.tenantNoNotes"}
                            textButton={"notes.addNote"}
                        />
                    ),
                }}
                gridId='DesktopNotesList'
                idKey={"noteId"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={userNotes?.count}
                actionsColumn={[actionsColumn]}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.noteId}
            />
        </>
    )
}
