import { useMutation } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { setErrorMessage } from "../redux/slices/App"
import { AxiosError } from "axios"
import { useDispatch } from "../redux/store"
import { useIsMobile } from "rentzz"
import { BackendError } from "../sections/types/user"
import { AddPaymentRequest } from "../sections/tenantContext/payment/AddPaymentForm"

export const addPaymentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()

    return useMutation({
        mutationFn: ({ data }: { data: AddPaymentRequest }) => Api.addPayment(data),
        onMutate: () => {
            dispatch(setErrorMessage(undefined))
        },
        onSettled: async (data, error, _) => {
            if (!error) {
                await invalidateQueries([
                    {
                        query: ComplexQueryIDs.Property,
                        params: { id: data?.propertyId },
                    },
                    SimpleQueryIDs.UserData,
                    SimpleQueryIDs.UserProperties,
                    ComplexQueryIDs.SummaryCard,
                    ComplexQueryIDs.DashboardChart,
                    ComplexQueryIDs.DashboardTable,
                    ComplexQueryIDs.Income,
                    ComplexQueryIDs.Incomes,
                    ComplexQueryIDs.ExpenseIncomes,
                    ComplexQueryIDs.Expenses,
                    ComplexQueryIDs.Expense,
                ])
            } else if (isMobile) {
                const errorResponse = error as AxiosError
                const errorMessage = errorResponse?.response?.data as BackendError
                dispatch(setErrorMessage(errorMessage.Message))
            }
        },
    })
}
