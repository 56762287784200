import { Alert, Box, Button, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, ToolbarType } from "@syncfusion/ej2-react-richtexteditor"
import * as React from "react"
import { useCallback, useMemo, useRef, useState } from "react"
import { CustomFile, CustomPopover, FormProvider, useIsMobile, useLocales, usePopover } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { setEditingItem } from "../../../../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../../../../redux/store"
import TenantNotificationPreview from "./TenantNotificationPreview"
import SendTenantNotificationDetails from "./SendTenantNotificationDetails"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { SendTenantNotificationDetailsSchema } from "../../../../../../../validations/rentingPeriod"
import { LoadingButton } from "@mui/lab"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../../../queries/userData"
import { toolbarSettings } from "../../../../../../../components/ToolbarSettings"
import { ToolbarSettingsModel } from "@syncfusion/ej2-richtexteditor/src/rich-text-editor/models/models"
import AIPopoverContent from "./AIPopoverContent"
import { fetchEventSource } from "@microsoft/fetch-event-source"
import Api from "../../../../../../../api/Api"
import { useSnackbar } from "notistack"
import { isEmpty } from "lodash"

interface SendTenantNotificationDetailsRequest {
    subject: string
    emails: string[]
    files?: CustomFile[]
}
interface Props {
    onSave: () => void
    onClose: () => void
    isLoading: boolean
}

enum SendTenantNotificationSteps {
    Content,
    Details,
    Preview,
}

const steps = [
    {
        title: "notification_content",
    },
    {
        title: "details",
    },
    {
        title: "preview",
    },
]

export default function SendTenantNotificationForm({ onClose, isLoading, onSave }: Props) {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState<SendTenantNotificationSteps>(SendTenantNotificationSteps.Content)
    const pRef = useRef<RichTextEditorComponent>(null)
    const { editingItem, currentRentingPeriodId } = useSelector((state) => state.appState)
    const logoForNotification = useFeatureValue(UserFlags.LogoForNotification, "")
    const notificationPrompt = useFeatureValue(UserFlags.GenerateNotificationPrompt, "")
    const aiPopover = usePopover()
    const aiDescription = useRef<string>("")
    const { enqueueSnackbar } = useSnackbar()

    const newToolbarSettings = useMemo((): ToolbarSettingsModel => {
        if (!isEmpty(notificationPrompt)) {
            return {
                type: toolbarSettings.type as ToolbarType,
                items: [
                    {
                        template: `<span id="ai-button" class="hover-span">
                              ${translate("use_ai")}
                            </span>`,
                        click: (_) => {
                            const el = document.getElementById("ai-button")

                            if (el) {
                                // @ts-expect-error we fabricate this event
                                aiPopover.onOpen({ currentTarget: el })
                            }
                        },
                    },
                    "|",
                    ...toolbarSettings.items,
                ],
            }
        }

        return toolbarSettings as ToolbarSettingsModel
    }, [notificationPrompt])

    const methods = useForm<SendTenantNotificationDetailsRequest>({
        resolver: yupResolver<SendTenantNotificationDetailsRequest>(SendTenantNotificationDetailsSchema),
        defaultValues: {
            subject: "",
            emails: [],
            files: [],
        },
    })

    const {
        getValues,
        formState: { isValid },
    } = methods

    const onAIGeneration = useCallback(
        (message: string) => {
            dispatch(setEditingItem({ ...editingItem, content: "" }))
            enqueueSnackbar(translate("content_is_generated"), { variant: "info" })
            aiPopover.onClose()
            fetchEventSource(Api.API + `/tenants/${currentRentingPeriodId}/get-notification-message?prompt=${message}`, {
                credentials: "include",
                onopen: async (_: Response) => {
                    aiDescription.current = ""
                },
                onmessage: (ev) => {
                    if (ev.data.startsWith("error_")) {
                        enqueueSnackbar(ev.data.replace("error_", ""), { variant: "error" })
                    } else {
                        aiDescription.current += ev.data.replace("data: ", "").replace("\n\n", "").replaceAll("\\n", "\n")
                        dispatch(setEditingItem({ ...editingItem, content: aiDescription.current }))
                        pRef.current?.refreshUI()
                    }
                },
                onclose: async () => {
                    // setIsFetchingAIDescription(false)
                },
                onerror: (_) => {
                    // setIsFetchingAIDescription(false)
                },
            })
        },
        [enqueueSnackbar],
    )

    const handleBack = useCallback(() => {
        if (activeStep === SendTenantNotificationSteps.Content) return onClose()
        if (activeStep === SendTenantNotificationSteps.Details) return setActiveStep(SendTenantNotificationSteps.Content)
        if (activeStep === SendTenantNotificationSteps.Preview) return setActiveStep(SendTenantNotificationSteps.Details)
    }, [activeStep, onClose, setActiveStep])

    const handleNext = useCallback(() => {
        if (activeStep === SendTenantNotificationSteps.Content) {
            setActiveStep(SendTenantNotificationSteps.Details)
            dispatch(setEditingItem({ ...editingItem, content: pRef.current?.getHtml() === "<p><br></p>" ? "" : pRef.current?.getHtml() }))
        }
        if (activeStep === SendTenantNotificationSteps.Details) {
            setActiveStep(SendTenantNotificationSteps.Preview)
            dispatch(
                setEditingItem({
                    ...editingItem,
                    subject: getValues("subject"),
                    emails: getValues("emails"),
                    files: getValues("files"),
                }),
            )
        }
        if (activeStep === SendTenantNotificationSteps.Preview) return onSave()
    }, [activeStep, onClose, setActiveStep, dispatch, editingItem, getValues])

    const isButtonDisabled = useMemo(() => {
        if (activeStep === SendTenantNotificationSteps.Details) return !isValid
        if (activeStep === SendTenantNotificationSteps.Preview) return isLoading || editingItem?.content === ""
        return false
    }, [activeStep, isValid, isLoading, editingItem])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case 0:
                return (
                    <Box display={"flex"} flexDirection={"column"} paddingTop={2}>
                        <RichTextEditorComponent
                            value={editingItem?.content ?? ""}
                            name={"content"}
                            id={"content"}
                            ref={pRef}
                            height={isMobile ? undefined : window.innerHeight - 350}
                            toolbarSettings={newToolbarSettings}
                        >
                            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar]} />
                        </RichTextEditorComponent>
                    </Box>
                )
            case 1:
                return <SendTenantNotificationDetails />
            case 2:
                return (
                    <Box display={"flex"} flexDirection={"column"} margin={2}>
                        <Box padding={2} sx={{ borderStyle: "solid", borderColor: theme.palette.grey.A400, borderWidth: 1 }}>
                            <TenantNotificationPreview logoWithTextPath={logoForNotification} isPreview={true} />
                        </Box>

                        {editingItem?.content === "" && (
                            <Alert sx={{ marginTop: 2 }} severity={"error"}>
                                {translate("notification_off_content")}
                            </Alert>
                        )}
                    </Box>
                )
        }
    }, [activeStep, setActiveStep, isMobile, editingItem])

    return (
        <Box height={isMobile ? undefined : window.innerHeight - 200} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <FormProvider methods={methods}>
                <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                    {steps.map((step, index) => {
                        return (
                            <Step key={step.title} completed={index < activeStep}>
                                <StepLabel>{translate(step.title)}</StepLabel>
                                {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                            </Step>
                        )
                    })}
                </Stepper>

                {!isMobile && renderActiveStep()}
            </FormProvider>
            <CustomPopover
                open={aiPopover.open}
                anchorEl={aiPopover.anchorEl}
                slotProps={{
                    arrow: {
                        placement: "top-center",
                    },
                }}
                onClose={aiPopover.onClose}
            >
                <AIPopoverContent onGenerate={(prompt) => onAIGeneration(prompt)} />
            </CustomPopover>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={handleBack}>
                    {translate(activeStep === SendTenantNotificationSteps.Content ? "cancel" : "back")}
                </Button>
                <LoadingButton variant='contained' color={"primary"} loading={isLoading} disabled={isButtonDisabled} onClick={handleNext}>
                    {translate(activeStep === SendTenantNotificationSteps.Preview ? "send" : "next")}
                </LoadingButton>
            </Box>
        </Box>
    )
}
