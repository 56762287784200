import { ListSubheader, MenuItem } from "@mui/material"
import React from "react"
import { PaymentType } from "../../redux/slices/AddProperty"
import { getExpenseTypeIcon } from "../../sections/expenses/utils"
import { Iconify } from "rentzz"

export default function PaymentTypeOption(
    option: { id: string; label: string; color: string },
    onlySubtype: boolean,
    otherProps: { key?: string } & unknown,
) {
    if (option.id === "expenses-group") return <ListSubheader disableSticky>{option.label}</ListSubheader>

    return (
        <MenuItem value={option.id} {...otherProps} key={otherProps.key}>
            <span
                style={{
                    paddingLeft: option.id.toString().startsWith(`${PaymentType.Expense}_`) ? 20 : 0,
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "center",
                }}
            >
                <Iconify
                    icon={
                        onlySubtype
                            ? getExpenseTypeIcon(PaymentType.Expense, Number(option.id))
                            : getExpenseTypeIcon(
                                  Number((option.id.toString() + "_").split("_")[0]),
                                  Number((option.id.toString() + "_").split("_")[1]),
                              )
                    }
                />
                {option.label}
            </span>
        </MenuItem>
    )
}
