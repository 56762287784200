import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { PropertyExpense, useExpenseLinkedIncomesQuery } from "../../../queries/expenses"
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material"
import React, { useCallback, useEffect, useMemo } from "react"
import { IncomeStatus, PropertyFileType, PropertyIncome } from "../../../redux/slices/AddProperty"
import { AppContext, CustomDataGrid, ExpenseAssignee, Label, LightTooltip, useLocales } from "rentzz"
import { DateTime } from "luxon"
import { DESKTOP_ICON_SIZE, getIncomeLabelColorByPaymentMethod, getIncomeLabelColorByStatus } from "../../../utils/helpers"
import { useIntl } from "react-intl"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { getIncomeTitleStatus } from "../../income/utils"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../redux/store"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import NoIncomes from "./NoIncomes"
import Header from "./Header"
import { useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../../../hooks/useQueryInvalidator"
import Api from "../../../api/Api"
import DeleteIcon from "@mui/icons-material/Delete"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"

export interface ExpenseDetailsPanelProps {
    currentExpense: GridRowParams<PropertyExpense>
}
const ExpenseDetailsPanel = ({ currentExpense }: ExpenseDetailsPanelProps) => {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const intl = useIntl()
    const { context } = useSelector((state) => state.appState)
    const currentIncomesIds = useMemo(() => currentExpense.row.propertyIncomes.map((income) => income.id), [currentExpense])
    const { data: expenseIncomes, isFetching: isLoading } = useExpenseLinkedIncomesQuery(currentIncomesIds, currentExpense.row.id)
    const { data: currencyData } = useCurrencyQueryFn()
    const queryClient = useQueryClient()

    useEffect(() => {
        currentIncomesIds.forEach((i) =>
            queryClient.prefetchQuery({
                queryKey: [ComplexQueryIDs.Income, { incomeId: i }],
                queryFn: async () => {
                    return (
                        (context === AppContext.Tenant ? await Api.getTenantIncome(i!, currentExpense.row.propertyId) : await Api.getIncome(i!)) ?? {}
                    )
                },
            }),
        )
    }, [currentExpense, context])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<PropertyIncome>[] = [
            {
                field: "value",
                type: "number",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "paymentMethod",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.paymentMethod"),
                renderCell: (v) => {
                    return (
                        <Label color={getIncomeLabelColorByPaymentMethod(v.value)} style={{ textTransform: "none" }}>
                            {translate(`income-table.paymentMethod-${v.value}`)}
                        </Label>
                    )
                },
            },
            {
                field: "status",
                minWidth: 130,
                flex: 0.3,
                headerAlign: "center",
                headerName: translate("payment.status"),
                align: "center",
                renderCell: (v) => {
                    return (
                        <LightTooltip title={translate(getIncomeTitleStatus(v.value))} arrow>
                            <Label color={getIncomeLabelColorByStatus(v.value)} style={{ textTransform: "none" }}>
                                {translate(`payments-table.status-${v.value as number}`)}
                            </Label>
                        </LightTooltip>
                    )
                },
            },
            {
                field: "date",
                type: "date",
                minWidth: 100,
                flex: 0.7,
                headerAlign: "center",
                align: "center",
                headerName: translate("income-table.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate, getAmount])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.5,
            minWidth: 180,
            type: "actions",
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    hidden: !params.row.canWrite || params.row.status !== IncomeStatus.Waiting || context === AppContext.Tenant,
                    tooltipText: translate("payment_is_waiting_owner"),
                    key: "status",
                    element: (
                        <GridActionsCellItem
                            key='status'
                            color='warning'
                            icon={<ErrorOutlineIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            label={translate("status")}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem(params.row))
                                dispatch(setModalOpen(AppModals.HandleIncomeStatus))
                            }}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Income,
                                        name: translate(`income-table.incomeType-${params.row.type}`),
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: !params.row.canDelete,
                    element: (
                        <GridActionsCellItem
                            key='delete'
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteIncome))
                                dispatch(setEditingItem(params.row))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate],
    )

    if (isLoading)
        return (
            <Box display='flex' flexDirection='column' width='100%' height={150} alignItems='center' justifyContent='center'>
                <CircularProgress />
            </Box>
        )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                    paddingBottom: currentExpense.row?.assignee === ExpenseAssignee.ME ? 3 : 0,
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    {currentExpense.row?.assignee === ExpenseAssignee.ME && (
                        <Box display={" flex"} height={"inherit"} alignContent={"center"} justifyContent={"center"} color={"text.secondary"}>
                            <Typography>{translate("only_expenses_assigned")}</Typography>
                        </Box>
                    )}
                    {(currentExpense.row?.assignee === ExpenseAssignee.TENANT || context === AppContext.Tenant) && (
                        <>
                            <Header currentItem={currentExpense.row} itemType='expense' />
                            {expenseIncomes && expenseIncomes.length > 0 ? (
                                <CustomDataGrid
                                    gridId={"ExpenseDetailsPanel"}
                                    isLoading={isLoading}
                                    columns={columns}
                                    rows={expenseIncomes ?? []}
                                    idKey={"id"}
                                    totalCount={expenseIncomes?.length}
                                    actionsColumn={[actionsColumn]}
                                    gridHeight={270}
                                    paginationMode={"client"}
                                    hidePagination={expenseIncomes?.length < 4}
                                />
                            ) : (
                                <NoIncomes />
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default ExpenseDetailsPanel
