import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import AddOrEditInvoicingClientForm from "../../../../../sections/nomenclature/invoicing-configuration/clients/AddOrEditInvoicingClientForm"
import { getInvoicingClientByIdQueryFn } from "../../../../../queries/invoicing-configuration"

export default function AddOrEditInvoicingClientGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const { data: currentInvoicingClient } = getInvoicingClientByIdQueryFn(editingItem?.invoicingClientId)

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={!isMobile && (modalOpen === AppModals.AddInvoicingClient || modalOpen === AppModals.EditInvoicingClient)}
                onClose={handleClose}
                maxWidth={"sm"}
                title={translate("add_invoicing_client")}
            >
                <AddOrEditInvoicingClientForm onClose={handleClose} defaultValues={currentInvoicingClient} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddInvoicingClient || modalOpen === AppModals.EditInvoicingClient)}
                onClose={handleClose}
                isLoading={false}
                title={translate("add_invoicing_client")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <AddOrEditInvoicingClientForm onClose={handleClose} defaultValues={currentInvoicingClient} />
            </DetailsSheet>
        </>
    )
}
